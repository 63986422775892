import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';

import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { appConstants } from 'src/app/source/constants/appconstants';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { LoginService } from 'src/app/source/services/login/login.service';
declare var $: any;

@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.css']
})
export class CasinoComponent implements OnInit {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  private storeProfileSub: Subscription;
  slotsdata: any;
  casinogames: CasinoGames;
  habinaro: RootObject;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  loader_game: any;
  tableSlotsGames: Array<Game>;
  Casinresponse: Array<Casino>;
  habinarosponse: Array<Game>;
  tableGames: any;
  KeyVsValue: KeyVsValue;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  playerName: string;
  casinogmaelunch: casinogmaelunch;
  imagespath: any = appConstants.slotsiamgepath;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  keyname: string;
  tokendata: any = null;
  loaderdata: boolean = true;
  eventname: string = 'allslots'
  showgamefrom: number = 0;
  gamelunchurl: any;
  urlSafe: SafeResourceUrl;
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  canclePopup: boolean = false;
  mrgameLunchUrl: any;
  slotsfundata: slotsData;
  brandid: any;
  crashDiv: any;
  selectedlanguege = 'EN';
  message = 'Drage / Double tap to close';
  showMessage = false;
  touchTime = 0;
  liveCasinoGmList: any[];
  openTab: any;
  lang: any = appConstants.language;
  showLoad: boolean = false;

  constructor(private cookieService: CookieService, private loginService: LoginService, public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private slotsservice: SlotsService, private store: Store<appState.AppState>) {

    if (this.lang == null) {
      this.selectedlanguege = 'EN'
    } else {
      this.selectedlanguege = localStorage.getItem('locale');
    }
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    // this.slotsservice.TopSlotsJson().subscribe(topSlots => { this.allSlotsGamesList(topSlots) });
    this.slotsservice.AllSlotsJson().subscribe((allSlots) => {
      this.allSlotsGamesList(allSlots)
    });
    this.slotsservice.TableGamesJson().subscribe((tables) => {
      this.tableGamesList(tables)
    });
    sessionStorage.removeItem('myPopup')


    var testCon = Boolean(sessionStorage.getItem("myPopupTwo"))
    console.log(testCon)
    if (testCon && window.location.pathname == '/casinoh5') {
      this.canclePopup = false;
    } else {
      this.canclePopup = true;
    }
  }
  // this.slotsservice.AllSlotsJson().subscribe((allSlots) => {
  //   this.allSlotsGamesList(allSlots)
  // });

  ngOnInit() {
    this.closegame('allslots')
    $(document).ready(function () {
      $("#draggables").draggable({
        snap: true
      });
    })


    $(window).scroll(function () {
      $('#draggables').css('position', 'fixed');
    });

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) { 
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        } else { 
          this.hitfunService();
        }
      });

    this.storeProfileSub = this.store.select("playerState").subscribe(
      (profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.success === true) {
            if (profileState.profile.login) {
              this.playerName = profileState.profile.login;
              this.hitService(this.playerName);
            }
          } else if (profileState.profile.success === false) {
          }
        }
      });

  }


  allSlotsGamesList(allSlots) {
    this.loaderdata = false;
    this.allSlotsList = allSlots;
    if (this.allSlotsList) {
      this.allSlotsGames = this.allSlotsList.Games;
    }
  }
  slotsLunchreal(id: any, providername: any, Game: any) {
    if (this.playerLoggedIn) {
      this.showLoad = true;
      const body = {
        gameMode: "real_play",
        gameId: id,
        provider: providername,
      };
      this.loginService
        .AllGamesLunch(body)
        .subscribe((data) => this.gameSlot1(data));
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.loader_game = Game;
    } else {
      
      this.casinogamessuccessPop = true;
    }
  }
  gameSlot1(data) {
    window.scrollTo({ top: 60, left: 0, behavior: 'smooth' })
    setTimeout(() => {
      this.showLoad = false;
    }, 2000);
    if (this.playerLoggedIn) {
      if (data.status == 200) {
        console.log(data.gameUrl);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          data.gameUrl
        );
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  // topSlotsGamesList(topSlots) {
  //   this.topSlotsList = topSlots;
  //   if (this.topSlotsList) {
  //     this.topSlotsGames = this.topSlotsList.Games;
  //   }
  // }
  // tableSlotsGamesList(tableSlots) {
  //   this.tableSlotsList = tableSlots;
  //   if (this.tableSlotsList) {
  //     this.tableSlotsGames = this.tableSlotsList.Games;
  //   }
  // }

  // habinarojson(data) {
  //   this.habinaro = data;
  //   if (this.habinaro) {
  //     this.habinarosponse = this.habinaro.Games;
  //   }
  // }





  hitService(profile) {
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  hitfunService() {
    this.slotsservice.httpClientfun().subscribe(data => { this.slotslunchfun(data) });
  };


  slotsreal(data) {
    $("#pills-tabContent").addClass("tabList");
    this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      this.showLoad = true;
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      setTimeout(() => {
        this.showLoad = false;
      }, 2000);
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem("profile");
        this.slotsservice.httpClient(profile).subscribe(
          (data) => {
            this.slotsdata = data;
            this.tokendata = this.slotsdata.TOKEN;
            if (this.tokendata) {
              this.gamelunchurl =
                this.slotsdata.HABANERO_GAMING_URL +
                "brandid=" +
                this.slotsdata.BRAND_ID +
                "&keyname=" +
                this.keyname +
                "&token=" +
                this.slotsdata.TOKEN +
                "&mode=real&locale=en";
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.gamelunchurl
              );

              setTimeout(() => {
                $(".casinoDrag").show();
              }, 5000);
              // window.scroll(0, 150);
            }
          },
          (err) => { }
        );
      } else if (this.tokendata != null) {
        this.gamelunchurl =
          this.slotsdata.HABANERO_GAMING_URL +
          "brandid=" +
          this.slotsdata.BRAND_ID +
          "&keyname=" +
          this.keyname +
          "&token=" +
          this.slotsdata.TOKEN +
          "&mode=real&locale=en";
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.gamelunchurl
        );
        console.log(this.urlSafe);
        setTimeout(() => {
          $(".casinoDrag").show();
        }, 5000);
        // window.scroll(0, 60);
        return;
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotsfun(data) {
    this.urlSafe = null;
    this.keyname = data;
    if (this.brandid == null) {
      this.hitfunService();
    } else if (this.brandid != null) {
      this.gamelunchurl = this.slotsfundata.HABANERO_GAMING_URL + 'brandid=' + this.slotsfundata.BRAND_ID + '&keyname=' + this.keyname + '&mode=fun&locale=en';
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
      return;
    }

  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;

    }
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;

    }
  }
  tableGamesList(tableData) {
    console.log(tableData)
    this.tableGames = tableData.Games;
    console.log(this.tableGames);
    window.scrollTo({ top: 0, left: 150, behavior: 'smooth' });
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  slotsGameLunch() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new loginActions.ResetState());
    this.gamelunchurl = this.mrgameLunchUrl;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
    setTimeout(() => {
      $('.casinoDrag').show();
    }, 5000);
    return;
  }

  closegame(event) {
    console.log(event)
    this.openTab = event;
    // this.loaderdata = true;
    this.showgamefrom = 0;
    this.urlSafe = "";
    this.eventname = event;
    // if (event == 'topslots') {
    //   this.slotsservice.TopSlotsJson().subscribe(topSlots => { this.allSlotsGamesList(topSlots) });
    // } else if (event == 'allslots') {
    //   this.slotsservice.AllSlotsJson().subscribe(allSlots => { this.allSlotsGamesList(allSlots) });
    // } else if (event == 'tablegames') {
    //   this.slotsservice.TableGamesJson().subscribe(tableSlots => { this.allSlotsGamesList(tableSlots) });
    // } else if (event == 'habinaro') {
    //   this.slotsservice.getJSON().subscribe(habinarogames => { this.allSlotsGamesList(habinarogames) });
    // } else if (this.eventname == 'crash') {
    //   this.slotsservice.getCrash().subscribe(crash => { this.allSlotsGamesList(crash) })
    // }
    // else if (this.eventname == 'livecasino') {
    //   this.slotsservice.liveCasinoGames().subscribe((data) => this.liveGamesList(data));
    // }


  }
  liveGamesList(data: any) {
    this.liveCasinoGmList = [];
    console.log(data);
    console.log(data.livecasino);
    this.liveCasinoGmList = data.livecasino;
  }
  crashGamesJsonRespone(res) {
    console.log(res)

  }

  // getIndieCasinoGames() {
  //     this.slotsservice.topGamesJson().subscribe( indieCasinoGames => { console.log(indieCasinoGames)})
  // }


  closeMe() {
    this.urlSafe = "";
    this.showgamefrom = 0;
  }

  clickMe() {
    this.showgamefrom = 0;
    $('.casinoDrag').hide();
    this.urlSafe = "";
  }
  exitPopup() {
    this.canclePopup = false;
    sessionStorage.setItem('myPopupTwo', 'true');
  }

}
