import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Transcation } from 'src/app/source/modules/cashier/transcaionresponse';
import { WithdrawsResponse } from 'src/app/source/modules/cashier/transcaionresponse';
import { CashierService } from 'src/app/source/services/cashier/cashier.service';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { AppState } from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Subscription } from 'rxjs';
import { WithDrawsResponses } from 'src/app/source/modules/cashier/withdrawResponse';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConstants } from 'src/app/source/constants/appconstants';


@Component({
  selector: 'app-cash-out-history',
  templateUrl: './cash-out-history.component.html',
  styleUrls: ['./cash-out-history.component.css']
})
export class CashOutHistoryComponent implements OnInit {

  WithdrawsResponse: WithDrawsResponses[];
  transcation: Transcation;
  loginSub: Subscription;
  withDrawResponsesub: Subscription;
  withdrawerrormessage: string;
  reverselcashoutResponse: string;
  p: number = 1;
   pendingcashoutsuccessPop: boolean = false;
  playerLoggedIn: boolean = false;
  historyloader: boolean = false;
  lang: any = appConstants.language;
  constructor(private cashierservice: CashierService, private store: Store<AppState>) {

  }
  ngOnInit() {
    
    this.store.dispatch(new cashierActions.ResetState());
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if(this.playerLoggedIn){
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    // if (this.playerLoggedIn == true) {
    //   this.cashierservice.TransactionHistory().subscribe(data => this.transactionHistoryResponse(data),
    //     err => {
    //       this.withdrawerrormessage = err.message;
    //     });
    // } else {
    // }
    this.withDrawResponsesub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => { 

        if (cashierState.withDrawResponse) { 
          if (cashierState.withDrawResponse.success == true) {
            this.withdrawerrormessage = "";
            this.WithdrawsResponse = cashierState.withDrawResponse.withdrawsResponses;
          } else if (cashierState.withDrawResponse.success == false) {
            if(cashierState.withDrawResponse.description == "NO_OPEN_WITHDRAWALS"){
              this.withdrawerrormessage = "No open withdrawals";
            }else{
              this.withdrawerrormessage = cashierState.withDrawResponse.description;
            }
            this.WithdrawsResponse = null;
          }
        }
        if (cashierState.withDrawResponseError) { 
          this.withdrawerrormessage = cashierState.withDrawResponseError.message;
          this.WithdrawsResponse = null;
        } 
          if (cashierState.reverselcashout) { 
            this.historyloader = false;
            if (cashierState.reverselcashout.success == true) {   

              if(this.withdrawerrormessage !== "No open withdrawals"){
                this.pendingcashoutsuccessPop = true;
                  if (this.lang == "EN") 
                  this.reverselcashoutResponse = "You have successfully reversed your cashout and balance was added to wallet";
                  else
                  this.reverselcashoutResponse = "مبلغ برداشت شما به ایدی برگشت داده شد.";

              }
            
            } else if (cashierState.reverselcashout.success == false) {
              this.reverselcashoutResponse = cashierState.reverselcashout.description;
            }
          }
 


        if (cashierState.reverselcashoutError) { 
          this.historyloader = false;
          this.pendingcashoutsuccessPop = true;
          this.reverselcashoutResponse = cashierState.reverselcashoutError.message;
        }
      });
      
      this.store.dispatch(new cashierActions.CashierGetOpenWithDrawRequest());
  }

  // transactionHistoryResponse(data) {
  //   this.transcation = data;
  //   if (this.transcation.success == true) {
  //     this.withdrawerrormessage = null;
  //     this.WithdrawsResponse = this.transcation.withdrawsResponses;
  //   }else{
  //     this.WithdrawsResponse = null;
  //     this.withdrawerrormessage = "eror occured";
  //   }
  // }
  cancelwithdrawal(refTxnID) {
    this.store.dispatch(new cashierActions.CashierCancelWithDrawRequest({ "cashoutId": refTxnID }));
  }
  pendingcashoutPopClose() {
    this.pendingcashoutsuccessPop = false;
    this.reverselcashoutResponse = ""; 
  }
}
