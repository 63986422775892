import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
declare var $: any;
@Component({
  selector: 'app-livecasino',
  templateUrl: './livecasino.component.html',
  styleUrls: ['./livecasino.component.css']
})
export class LivecasinoComponent implements OnInit {

  navigationsuccessPop: boolean = false;
  gamelunchmessage: string = "";
  urlSafe: SafeResourceUrl;
  message = 'Drage / Double tap to close';
  showMessage = false;
  touchTime = 0;
  showIt: any;
  constructor(private router: Router, private casinoGamesService: CasinoGamesService, public sanitizer: DomSanitizer) {
    this.casinoGamesService.launchLiveCasino()
      .subscribe((data) => {
        if (!data)
          this.showPopup('Error');
        else {
          let redirectionurl = data["VIVO_GAME_LAUNCH_URL"];
          return window.open(redirectionurl, '_self');
          //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(redirectionurl); 

        }
      }, (err) => {
        this.showPopup(err);
      })

  }

  ngOnInit() {
    $(document).ready(function () {
      $("#draggable").draggable({
        snap: true
      });
    })
    setTimeout(function () {
      $('#draggable').show();
    }, 5000);
  }

  showPopup(msg) {
    this.gamelunchmessage = msg;
    this.navigationsuccessPop = true;

  }
  navigationPopClose() {
    this.gamelunchmessage = "";
    this.navigationsuccessPop = false;
    if (this.urlSafe == null) {
      this.router.navigate(['/home']);
    }
  }


  clickMe() {

    if (this.touchTime == 0) {
      // set first click
      this.touchTime = new Date().getTime();
      this.showMessage = !this.showMessage;
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - this.touchTime) < 800) {
        // double click occurred
        this.touchTime = 0;
        this.urlSafe = "";
        this.router.navigate(['/home']);
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
        this.showMessage = !this.showMessage;
      }
    }
  }


}
