import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { Store } from '@ngrx/store';
import * as appState from "src/app/source/appstates/appState";
import * as loginActions from "src/app/source/appstates/loginstates/loginActions";
import * as cashierActions from "src/app/source/appstates/cashierstates/cashierActions";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { LoginState } from "src/app/source/appstates/loginstates/loginState";
import { ProfileState } from "src/app/source/appstates/playerstates/playerState";
declare var $: any;

import {
  slotsData,
  casinogmaelunch,
  KeyVsValue,
} from "src/app/source/modules/slotsmodel/slotsdata";


@Component({
  selector: 'app-crash',
  templateUrl: './crash.component.html',
  styleUrls: ['./crash.component.css']
})
export class CrashComponent implements OnInit {
  CrashDatares: any
  showgamefrom: number = 0;
  urlSafe: SafeResourceUrl;
  playerLoggedIn: boolean = false;
  showLoad: boolean = false;
  cover: boolean = false;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  loginSub: Subscription;
  slotsfundata: slotsData;
  private storeProfileSub: Subscription;
  playerName: string;
  slotsdata: slotsData;
  tokendata: any = null;
  brandid: any;
  message = "Drage / Double tap to close";
  canclePopup: boolean = false;
  poppy: any;
  showMessage = false;
  loaderdata: boolean = true;
  selectedlanguege = 'EN';


    constructor(private slotsservice: SlotsService,private store: Store<appState.AppState>) { 
    this.slotsservice.getCrash().subscribe((data) => { this.CrashDatares = data.responseing.splice(0,2),
      this.loaderdata = false;
      console.log(data) });
  
    this.poppy = Boolean(sessionStorage.getItem('myPopup'));
    var proflie = sessionStorage.getItem("profile")
    console.log(proflie)
    var testCon = Boolean(sessionStorage.getItem("myPopupTwo"))
    console.log(testCon)
    if (testCon && window.location.pathname == '/crash') {
      this.canclePopup = false;
    } else {
      this.canclePopup = true;
    }
  
  }

  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        } else {
          this.hitfunService();
        }
      });

    this.storeProfileSub = this.store
      .select("playerState")
      .subscribe((profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.success === true) {
            if (profileState.profile.login) {
              this.playerName = profileState.profile.login;
              this.hitService(this.playerName);
            }
          } else if (profileState.profile.success === false) {
          }
        }
      });
  }

  hitfunService() {
    this.slotsservice.httpClientfun().subscribe((data) => {
      this.slotslunchfun(data);
    });
  }
  hitService(profile) {
    this.slotsservice.httpClient(profile).subscribe((data) => {
      this.slotslunch(data);
    });
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
    }
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;
    }
  }

  crashGamesMethod(id, pro) {
    if (this.playerLoggedIn) {
      this.showLoad = true;
      const body = {
        gameId: id,
        gameMode: "real_play",
        provider: pro,
      };
      this.store.dispatch(new loginActions.ResetState()); 
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        this.cover = true;
        this.slotsservice.getSlottyUrl(body).subscribe((data) => {
          this.mojuslots(data);
          this.showLoad = false;
        }); 
    }else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }



  mojuslots(data) {
    if(this.playerLoggedIn) {

      console.log(data);
      window.scroll(0, 0);
      if (data) {
        setTimeout(() => {
          this.cover = false;
        }, 1000);
        window.open(data.gameUrl,'_self');
        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        //   data.gameUrl
        // );
        // window.scroll(0, 60);
      }
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }

  closeMe() {
    this.urlSafe = "";
    this.showgamefrom = 0;
  }
  clickMe() {
    this.showgamefrom = 0;
    $(".casinoDrag").hide();
    this.urlSafe = "";
  }

  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  exitPopup() {
    this.canclePopup = false;
    sessionStorage.setItem('myPopup', 'true');
  }
}
