import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { appConstants } from 'src/app/source/constants/appconstants';
import { PasswordsComponent } from '../passwords/passwords.component';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  instaLink: any = appConstants.SOCIAL_LINKS.INSTAGRAM.link
  telegramLink: any = appConstants.SOCIAL_LINKS.TELEGRAM.link;
  contactMail: any = appConstants.CONTACT_MAIL;
  navigationsuccessPop: boolean = false;
  gamelunchmessage: string = "";
  private loginstoreSub: Subscription;
  playerLoggedIn: boolean = false;
  contentsuccessPop :boolean =false;
  livecontentsuccessPop:boolean=false;
  casinocontentsuccessPop:boolean=false
  appLinks: any = appConstants.appDownloadLinks;
  selectedlanguege = 'EN';
  lang:any=appConstants.language;
  constructor(private store: Store<appState.AppState>,private casinoGamesService: CasinoGamesService) { 
    if(this.lang == null){
      this.selectedlanguege = 'EN'
    }else{
      this.selectedlanguege = localStorage.getItem('locale');
    }
  }

  ngOnInit() {
    $(document).ready(function () {
      $(".scrollTop").click(function () {
        $(window).scrollTop(0);
      });
    });
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        
        }
      }
    )
  }
  comingsoon(){
    this.passwords.comingSoonPopOpen()
  }

  navigateToWindow(link) {
    window.open(link, "_blank");
  }
  dashboard() {
    this.gamelunchmessage = "Please login";
    this.navigationsuccessPop = true;
  }
  launchLiveCasino() {
    if (!this.playerLoggedIn) {
      this.showPopup("Please login");
    }
    else {
      this.casinoGamesService.launchLiveCasino()
        .subscribe((data) => {
          if (!data)
            this.showPopup('Error')
          else {
            let redirectionurl = data["VIVO_GAME_LAUNCH_URL"];
           return window.open(redirectionurl, "_self", );
          }
        }, (err) => {
          this.showPopup(err);
        })
    }
  }


  showPopup(msg) {
    this.gamelunchmessage = msg;
    this.navigationsuccessPop = true;
  }
  navigationPopClose() {
    this.gamelunchmessage = '';
    this.navigationsuccessPop = false;
  }


   
}
