import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
import { PlayerService } from '../player/player.service';

@Injectable({
  providedIn: 'root'
})
export class LivedealerService {

  constructor(private http: HttpClient, private playerService: PlayerService) { }

  liveDealerServiceData(data):Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.livedealer}/${data}`, this.playerService.httpOptions())
  }
}
