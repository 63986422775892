
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription, interval } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Countries, Country } from 'src/app/source/modules/login/countries'; 
import { environment } from '@app/env';
import { map, take } from 'rxjs/internal/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { appConstants } from 'src/app/source/constants/appconstants';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;
export enum KEY_CODE {
  Esc_btn = 27
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  selectedlanguege = 'FA';
  lang: any = appConstants.language;
  formRegister: FormGroup;
  errorMessage: string ;
  selectedcountry: string = '4';
  phonevalue: any;
  undefiend: any;
  selecteddata: any;
  undefind;
  emailvalue: any;
  forgotform: FormGroup;
  registerloader: boolean = false;
  private storeSub: Subscription;
  private tokenstoreSub: Subscription;
  private countries: Countries;
  country: Country[];
  registerPopup: boolean = false;
  otpRequesterrorMessage: string;
  registerpopmessage: string;
  registersuccessPop: boolean = false;
  urlof: string; reID: any;
  reType: any;
  // changedemail:boolean=false;
  // erromessage:boolean=false;
  playerLoggedIn: boolean = false;
  // registerPlayerData: boolean = false;
  constructor(private route: ActivatedRoute, private router: Router, private store: Store<appState.AppState>) {
    this.store.dispatch(new loginActions.ResetState());
    if (this.lang == null) {
      this.lang = 'FA'
    } else {
      this.lang = localStorage.getItem('locale');
    }
    this.selectedlanguege = localStorage.getItem('locale');
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.Esc_btn) {
      this.onclose();
    }
  }
  ngOnInit() {
    this.urlof = this.router.url.substr(0, this.router.url.indexOf("?"));
    if (this.urlof == '/register') {
      this.route.queryParams.subscribe(params => {
        this.reID = params.ID;
        this.reType = params.type;
        this.openRegisterPop();
      });

      if (this.reID != null) {
        this.store.dispatch(new loginActions.ResetState());
      }
    }
    this.store.dispatch(new loginActions.ResetState());

 

 
  //   this.formRegister.get('register.email').valueChanges.subscribe(x => {
      
  //     let extension = x.substr(x.lastIndexOf(".") + 1);
  //     if(extension === 'com'){ 
  //      this.erromessage=false;
  //       this.changedemail = true;
  //     }else{
        
  //      this.erromessage=true;
  //       this.changedemail = false;
  //     }
  //  });
    // this.formRegister = new FormGroup({
    //   "register": new FormGroup({
    //     "login": new FormControl(null, [Validators.required, Validators.minLength(4)]),
    //     "nickname": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
    //     "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
    //     "firstName": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z]*')]),
    //     "lastName": new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //     "password": new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
    //     "address": new FormGroup({
    //       "city": new FormControl(null,  [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //       "phone": new FormControl(null, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
    //       "country": new FormControl(),
    //      // "country": new FormControl(null, Validators.required),
    //     })
    //   }),
    //   "acceptTerms": new FormControl(true, Validators.requiredTrue)
    // })
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => { 
        if (loginState.countries) {
          //if (loginState.countries.success === true) {
          this.countries = loginState.countries;
          this.country = this.countries.values;
          // } else if (loginState.countries.success === false) {
          //   this.errorMessage = loginState.loginResponse.description;
          // }
        }
        if (loginState.playerLoggedIn) {
          
          if (loginState.playerLoggedIn.loggedIn == true) {
            this.registerPopup = false;
          }
        }
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) {
            this.registerPopup = false;
          } else if (loginState.loginResponse.success === false) {
            this.errmessagenull(loginState.loginResponse.description);
          }
        }
        if (loginState.loginErrorResponse) {
          this.errmessagenull(loginState.loginErrorResponse.message);
        }
      }
    ) 
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.messageforsuccess == true) {
          this.registerloader = false;
          this.registersuccessPop = true;
          if(this.reID != null){
          const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
          this.router.navigateByUrl(urlWithoutQueryParams)
            .then(() => {
              this.reID = null;
              this.reType = null;
               this.router.navigate[('/home')];
            });
            return;
          }
        return;
        } else if (loginState.messageforsuccess == false) {

          this.registerloader = false;
        }


      }
    )

    this.store.dispatch(new loginActions.RegisterGetCountry());

  }


  
 
  generateOTP($event) {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.OtpRequestResponse) {
          if (loginState.OtpRequestResponse.success == true) {
            this.otpRequesterrorMessage = "";
            $event.target.disabled = true;
            let numbers = interval(1000);
            let takeFourNumbers = numbers.pipe(take(60));
            takeFourNumbers.subscribe(x => {
              $event.target.innerHTML = x;
              if (x == 59) {
                $event.target.disabled = false;
                $event.target.innerHTML = "Resend OTP";
              }
            });
          } else if (loginState.OtpRequestResponse.success == false) {
            this.otpRequesterrorMessage = loginState.OtpRequestResponse.description;
          }
        }

        if (loginState.OtpRequestErrorResponse) {
          this.otpRequesterrorMessage = loginState.OtpRequestErrorResponse.message;
        }
      }
    )
    this.store.dispatch(new loginActions.RegisterGenarateOTP({ language: 'en', mobile: this.formRegister.get('register.address.phone').value }));
  }
  onFormSubmit() {
    this.registerloader = true;
    let RefferealCode = localStorage.getItem("Referrer")
    console.log(RefferealCode)
    if(RefferealCode){
      const registerFormGroup = this.formRegister.get('register') as FormGroup
      registerFormGroup.addControl('referrerLogin', new FormControl(RefferealCode));  
    }else{
      // this.formRegister.register.removeControl("referrerLogin")
    }
    
    this.formRegister.value.register.nickname = this.formRegister.value.register.login;
console.log(this.formRegister.value.register,"this.formRegister.value.register")

    this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    this.store.dispatch(new loginActions.ResetState());
    //  if(this.formRegister.value.register.address.phone==null){
    //     this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }else if(this.formRegister.value.register.address.phone.length==10){
    //     this.formRegister.value.register.address.phone = '+234'+this.formRegister.value.register.address.phone;
    //   this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }else{
    //     this.formRegister.value.register.address.phone;
    //     this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }

    // this.registerPlayerData = true;

  }
  // closePop(){
  //   this.registerPlayerData = false;
  // }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.tokenstoreSub) {
      this.tokenstoreSub.unsubscribe();
    }
  }
  closeRegisterPop() {
    this.formRegister.reset();
    this.store.dispatch(new loginActions.ResetState());
    this.registerPopup = false;
    this.formRegister.reset();
  }
  openRegisterPop() {
    this.registerPopup = true;
    this.formRegister = new FormGroup({
      "register": new FormGroup({
        "login": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(16), Validators.pattern('[a-zA-Z0-9]*')]),
        "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
        // "nickname": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
        "nickname": new FormControl(''),
        "firstName": new FormControl(null),
        "lastName": new FormControl(null),
        "campaignCode": new FormControl(this.reID),
        "referralType": new FormControl(this.reType),
        // "password": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
        "password": new FormControl(null, [Validators.required,  Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?!.*\s)(?!.*&)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*])[A-Za-z\d!@#$%^*]{8,30}$/)]),
        "address": new FormGroup({
          // "phone": new FormControl(null, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
          "country": new FormControl(364),
        })
      }),
      "acceptTerms": new FormControl(null, [Validators.required, Validators.requiredTrue]),
      // "mailVerification": new FormControl(null, [Validators.required, Validators.requiredTrue])
    });
          this.formRegister.value.register.login.controls['login'].setValue(null);
          this.formRegister.value.register.password.controls['password'].setValue(null);
  }
  registersuccessPopClose() {
    this.store.dispatch(new loginActions.ResetState());
    this.registersuccessPop = false;
  }
  onChange(data) {
    this.selectedcountry = data;
    // for (let currencydata of this.country) {
    //   if (this.selectedcountry == currencydata.id) {
    //     this.selectedcurrency = currencydata.isd;
    //   }
    // }
  }
  errmessagenull(err) { 
    console.log( err )
    this.registerloader = false;
    if(err == 'LOGIN_ALREADY_USED'){
    this.errorMessage="Login name already exists please try different one";
    }else if(err == 'EMAIL_ALREADY_USED'){
      this.errorMessage="Email already used please try another one";
    }else{
      if(this.lang == 'FA'){
        if(err== "login already used"){
          this.errorMessage = "این نام کاربری قبلا استفاده شده است"
        }else if(err == 'email already used'){
          this.errorMessage = "این آدرس ایمیل قبلا استفاده شده است"
        }
      }else{
        this.errorMessage = err;
      }
    }

    setTimeout(() => {
      this.errorMessage = "";
    }, 7000);
  }
  onKeydown(event) {
    // if (event.key === "Escape") {
    this.closeRegisterPop();
    // }
  }
  onclose() {

    this.closeRegisterPop();

  }

  passwordValid(event) {


    const keyCode = event.charCode || event.keyCode;
    const disallowedCodes = [38];
    if (disallowedCodes.includes(keyCode)) {
      event.preventDefault();
      this.formRegister.get('register.password').setErrors({ 'disallowedCharacter': true });
      console.log(keyCode);
      return false;
    }
    
    if (this.formRegister.get('register.password').errors) {
      this.formRegister.get('register.password').updateValueAndValidity();
    }
    return true;

    // var k;
    // k = event.charCode;
    // console.log(k)//         k = event.keyCode;  (Both can be used)
    // return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 58) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }


}
