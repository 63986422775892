import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { dashboardConf } from '../../source/config/dashboard.config';
import { appConf } from '../../source/config/app.config';
import { appConstants } from 'src/app/source/constants/appconstants';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
declare var $:any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  selectedlanguege = 'FA';
  // selectedPage: any = 'specification';
  lang:any=appConstants.language;
  dashboardMenu: any = dashboardConf.sideMenu;
  constructor(private store: Store<appState.AppState>, private router: Router, private activatedRoute: ActivatedRoute) {
    window.scroll(0,0);
    if(this.lang == null){
      this.lang = 'FA'
    }else if(localStorage.getItem('locale') == "English"){
      this.lang = sessionStorage.getItem('locale');
    }else{
      this.lang = localStorage.getItem('locale');
      this.selectedlanguege = localStorage.getItem('locale');
    }
    // this.activatedRoute.params.subscribe(params => {
    //   if ('name' in params)
    //     this.selectedPage = params['name'];
    //   else
    //     this.selectedPage = 'specification';
    // });

    //this.changeSelection();
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".scrollTop").click(function () {
        $(window).scrollTop(0);
      });
      $(".rightArow").click(function () {
        $('.dash_sideMenu ').addClass('rightUL');
        setTimeout(function () {
          $('.dash_sideMenu ').removeClass('rightUL');
        }, 3000);
      });
   
    });
  }
  // navigate(path) {
  //   this.router.navigate([path]);
  // }
  ngAfterViewInit() {
  }
  // changeSelection() {
  //   const currentUrl = this.router.url;
  //   let hithappend = false;
  //   this.dashboardMenu.forEach((record) => {
  //     if (record.link == currentUrl) {
  //       record.selected = true;
  //       hithappend = true;
  //     }
  //   });
  //   //  if(!hithappend)
  //   //  this.navigate("/dashboard"+appConf.defaultRoute);
  // }
  resetdata() {

    this.store.dispatch(new playerActions.ResetState());
  }
}
