import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as resultActions from 'src/app/source/appstates/betresult/betresultAction';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { betResultState } from 'src/app/source/appstates/betresult/betresultState';
import { Results, resultForm, Datum } from 'src/app/source/modules/betresults/results';
import * as moment from 'moment';
import { CommondataService } from 'src/app/source/services/sports/commondata.service';
import { SportsMenu, Menu } from 'src/app/source/modules/specific/sportsmenu';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  form: FormGroup;
  sdate: Date;
  todaydate: any;
  today: Date;
  edate: Date;
  sport: SportsMenu;
  sportName: Array<Menu>;
  selecteddata: any = 'Soccer';
  undefiend: any;
  afteraddweek: any;
  p: number = 1;
  startdateweek: any;
  resultForm: resultForm;
  enddateweek: any;
  defaultSport: string = "Soccer";
  selectnum: number = 10;
  descending: boolean;
  resultData: boolean = false;
  private Results: Results;
  result: Array<Datum>;
  errorMessage: string;
  private resulttoreSub: Subscription;
  // sportName = [
  //   { sport : 'Soccer' },
  //   { sport : 'Tennis'},
  //   { sport : 'Basketball'}
  // ];

  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate:any;
  myDateValue: Date;
  historyloader:boolean=false;
  constructor(  private commonSevice: CommondataService, private store: Store<appState.AppState>) {
    // defineLocale('bb', itLocale);
    // this.localeService.use('bb');
    //this.dpConfig.containerClass = 'theme-dark-white'; 
    //this.commonSevice.Issportsget.subscribe(value => { this.sportsdata(value) }); 
    
    this.store.dispatch(new resultActions.ResetState());
  }
  ngOnInit() {

    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.myDateValue = new Date();
    this.today = new Date();
    this.sdate = new Date();
    this.edate = new Date();
    let oneweek = new Date(this.edate.getFullYear(), this.edate.getMonth(), this.edate.getDate() - 1);
    this.sdate = oneweek;
    this.form = new FormGroup({
      "sdate": new FormControl(this.edate, Validators.required),
      "edate": new FormControl(this.sdate, Validators.required),
      "sport": new FormControl(''),
    });
    this.form.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.sdate).getTime() > ToDate.getTime() || new Date(x.sdate).getTime() > new Date(x.edate).getTime() || new Date(x.edate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   }); 
    const body: resultForm = {
      sdate: this.sdate,
      edate: this.edate,
      sport: this.defaultSport
    }
// this.OnSubmit();
    //this.store.dispatch(new resultActions.sportBookGetResults(body));
  }
 
  sdateupdate(startdate) {
    this.afteraddweek = moment(startdate).add(+7, 'days').format('DD-MM-YYYY');
    if (this.afteraddweek > this.today) {
      this.startdateweek = this.today;
    } else {
      this.startdateweek = this.afteraddweek;
    }
  }
  //'YYYY-MM-DD'

  //DD/MM/YYYY
  edateupdate(enddate) {
    this.enddateweek = moment().format('DD-MM-YYYY');
  }

  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }
  OnSubmit() {
    // this.form.value.sport=this.selecteddata;
    this.form.value.sdate = moment(this.form.value.sdate).format('YYYY-MM-DD');
    this.form.value.edate = moment(this.form.value.edate).format('YYYY-MM-DD');
    this.p = 1;
    this.historyloader=true;
    this.store.dispatch(new resultActions.sportBookGetResults(this.form.value));
  }
  changeSport(event) {
    this.selecteddata = event;
  }
}
