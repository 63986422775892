
export const dashboardConf = {
    sideMenu: [{
        displayName: "Specifications",
        displayNamefa: "مشخصات",
        link: "/specification",

        selected: false
    }, {
        displayName: "Bank Account",
        displayNamefa: "حساب بانکی",
        link: "/bank"
        , selected: false
    }, {
        displayName: "Cashier",
        displayNamefa: "صندوقدار",
        link: "/deposit"
        , selected: false
    }, {
        displayName: "My Balance",
        displayNamefa: "موجودی من",
        link: "/balance"
        , selected: false
    },
     {
        displayName: "Exchange",
        displayNamefa: "تبدیل دلار",
                link: "/exchangeCurrency"
        , selected: false
    },
    {
        displayName: "Rake Back",
        displayNamefa: "تبدیل ریک بک ",
        link: "/vip-points-exchange"
        , selected: false
    },
    {
        displayName: "Poker History",
        displayNamefa: "تاریخچه دستها",
        link: "/pokerHistory"
        , selected: false
    },
    // {
    //     displayName: "Casino History",
    //     link: "/dashboard/remotehistory"
    //     , selected: false
    // },
    {
        displayName: "Casino History",
        displayNamefa: "تاریخچه بازیهای کازینو",
        link: "/casinohistory"
        , selected: false
    },
    // {
    //     displayName: "Sportbook History",
    //     displayNamefa: "تاریخچه شرط بندی ورزشی",
    //     link: "/sportbookhistory"
    //     , selected: false
    // },
    // {
    //     displayName: "Backgammon History",
    //     displayNamefa: "تاریخچه تخته نرد",
    //     link: "/backgammonhistory"
    //     , selected: false
    // },
    // {
    //     displayName: "Russian History",
    //     displayNamefa: "تاریخچه بازی پوکر روسی",
    //     link: "/russianpokerhistory"
    //     , selected: false
    // },
    {
        displayName: "Transactions",
        displayNamefa: "تاریخچه تراکنش ها",
        link: "/transaction"
        , selected: false
    },
    {
        displayName: "PToP Transfers History",
        displayNamefa: "تاریخچه انتقال PToP",
        link: "/ptoptransfer"
        , selected: false
    }
    ],

}