import { Component, OnInit } from "@angular/core";
import { SlotsService } from "src/app/source/services/slots.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import * as appState from "src/app/source/appstates/appState";
import {
  slotsData,
  casinogmaelunch,
  KeyVsValue,
} from "src/app/source/modules/slotsmodel/slotsdata";
import { RootObject, Game } from "src/app/source/modules/slotsmodel/slotsvalue";
import {
  buildRootObject,
  buildGame,
} from "src/app/source/modules/slotsmodel/builsslots";
import { environment } from "@app/env";
import { CoreEnvironment } from "@angular/compiler/src/compiler_facade_interface";
import * as cashierActions from "src/app/source/appstates/cashierstates/cashierActions";
import { LoginState } from "src/app/source/appstates/loginstates/loginState";
import {
  CasinoGames,
  Casino,
} from "src/app/source/modules/goldenraceinterface/goldenrace";

import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from "@angular/router";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { ProfileState } from "src/app/source/appstates/playerstates/playerState";
import { appConstants } from "src/app/source/constants/appconstants";
import * as loginActions from "src/app/source/appstates/loginstates/loginActions";
import { LoginService } from "src/app/source/services/login/login.service";
import { PlayerService } from "src/app/source/services/player/player.service";
import { CasinoGamesService } from "src/app/source/services/casino/casinogames.service";
declare var $: any;

@Component({
  selector: "app-new-slots-ncrash",
  templateUrl: "./new-slots-ncrash.component.html",
  styleUrls: ["./new-slots-ncrash.component.css"],
})
export class NewSlotsNcrashComponent implements OnInit {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  private storeProfileSub: Subscription;
  // slotsdata: slotsData;
  casinogames: CasinoGames;
  habinaro: RootObject;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  allSlotsList: any;
  crashList: any;
  slotsdata:any
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  Casinresponse: Array<Casino>;
  habinarosponse: Array<Game>;
  KeyVsValue: KeyVsValue;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  playerName: string;
  casinogmaelunch: casinogmaelunch;
  imagespath: any = appConstants.slotsiamgepath;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  keyname: string;
  tokendata: any = null;
  brandid: any;
  loaderdata: boolean = true;
  eventname: string = "Ezugi";
  showgamefrom: number = 0;
  gamelunchurl: any;
  urlSafe: SafeResourceUrl;
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  getId: any;
  mrgameLunchUrl: any;
  slotsfundata: slotsData;
  message = "Drage / Double tap to close";
  showMessage = false;
  touchTime = 0;
  fishGames: any;
  loader_game: any;
  fishingGames = [];
  suresh: any;
  fishtotal: any;
  crashgames = [];
  gamesArray = [];
  vivoGamesData = [];
  cover: boolean = false;
  gamesArrayList: any;
  liveCasinoGmList = [];
  lucky = [];
  gameName: any;
  getidlive: any;
  logPop: boolean;
  shootinglist: any;
  shootingID: any;
  fillterdGames: any;
  allSlotsGames1 = [];
  liveCasinoGmListDiv: boolean = true;
  allSlotsGamesDiv: boolean = false;
  canclePopup: boolean = false;
  poppy: any;
  selectedlanguege = "FA";
  openTab: any;
  tableGames: any;
  BetsolutionsData: any;
  openTabName: any;
  showLoad: boolean = false;
  CrashDatares: any

  constructor(
    private cookieService: CookieService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private slotsservice: SlotsService,
    private loginService: LoginService,
    private playerService: PlayerService,
    private casinoGamesService: CasinoGamesService,
    private store: Store<appState.AppState>
  ) {
    this.selectedlanguege = localStorage.getItem("locale");
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());

    // sessionStorage.removeItem('myPopup')
    this.poppy = Boolean(sessionStorage.getItem('myPopup'));
    var proflie = sessionStorage.getItem("profile")
    console.log(proflie)
    // if (proflie !== null) {
    // if (this.poppy) {
    //   this.canclePopup = false;
    // } else {
    //   this.canclePopup = true;
    // }
    sessionStorage.removeItem('myPopupTwo')
    var testCon = Boolean(sessionStorage.getItem("myPopup"))
    console.log(testCon)
    if (testCon && window.location.pathname == '/liveDealer_2') {
      this.canclePopup = false;
    } else {
      this.canclePopup = true;
    }
    // if (this.poppy ) {
    //   this.canclePopup = false;
    // } else {
    //   this.canclePopup = true;
    // }
    // } else {
    //   this.casinomessage = "Please login";
    //   this.casinogamessuccessPop = true;
    // }


    this.slotsservice.liveCasinoGames().subscribe((data) => { this.liveGamesList(data) });
   
    // this.slotsservice.indieCasinoGames().subscribe((data) => { this.indieCaisnoGamesList = data.Games });

    this.slotsservice.lukystrik().subscribe((data) => { this.luckstrik(data) });

    this.slotsservice.AllSlotsJson().subscribe((allSlots) => {
      this.allSlotsGamesList(allSlots)
    });
    this.slotsservice.TableGamesJson().subscribe((tables) => {
      this.tableGamesList(tables)
    });

    this.slotsservice.BetsolutionsJson().subscribe((data) =>
    // {this.BetsolutionsData = data});
    { console.log(data) });

    this.slotsservice.getCrash().subscribe((data) => { this.CrashDatares = data.responseing.splice(0,2), console.log(data) });

  }

  ngOnInit() {
    this.closegame('Ezugi');

    $(document).ready(function () {
      $("#draggables").draggable({
        snap: true,
      });
    });

    $(window).scroll(function () {
      $("#draggables").css("position", "fixed");
    });

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        } else {
          this.hitfunService();
        }
      });

    this.storeProfileSub = this.store
      .select("playerState")
      .subscribe((profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.success === true) {
            if (profileState.profile.login) {
              this.playerName = profileState.profile.login;
              this.hitService(this.playerName);
            }
          } else if (profileState.profile.success === false) {
          }
        }
      });
  }
  getCrashGames(data) {
    console.log(data);
    this.crashgames = data;
    this.crashgames = data.response;
  }
  mrslotylist(data) {
    this.fillterdGames = data.response;
    console.log(this.fillterdGames);
  }

  allSlotsGamesList(allSlots) {
    console.log(allSlots);
    console.log(allSlots.Games);
    this.allSlotsGames = allSlots.Games;
  }
  tableGamesList(tableData) {
    console.log(tableData)
    this.tableGames = tableData.Games;
    window.scrollTo({ top: 0, left: 150, behavior: 'smooth' });
  }
  topSlotsGamesList(topSlots) {
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }

  hitService(profile) {
    this.slotsservice.httpClient(profile).subscribe((data) => {
      this.slotslunch(data);
    });
  }
  hitfunService() {
    this.slotsservice.httpClientfun().subscribe((data) => {
      this.slotslunchfun(data);
    });
  }

  slotsreal(data) {
    $("#pills-tabContent").addClass("tabList");
    this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem("profile");
        this.slotsservice.httpClient(profile).subscribe(
          (data) => {
            this.slotsdata = data;
            this.tokendata = this.slotsdata.TOKEN;
            if (this.tokendata) {
              this.gamelunchurl =
                this.slotsdata.HABANERO_GAMING_URL +
                "brandid=" +
                this.slotsdata.BRAND_ID +
                "&keyname=" +
                this.keyname +
                "&token=" +
                this.slotsdata.TOKEN +
                "&mode=real&locale=en";
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.gamelunchurl
              );

              setTimeout(() => {
                $(".casinoDrag").show();
              }, 5000);
              window.scroll(0, 150);
            }
          },
          (err) => { }
        );
      } else if (this.tokendata != null) {
        this.gamelunchurl =
          this.slotsdata.HABANERO_GAMING_URL +
          "brandid=" +
          this.slotsdata.BRAND_ID +
          "&keyname=" +
          this.keyname +
          "&token=" +
          this.slotsdata.TOKEN +
          "&mode=real&locale=en";
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.gamelunchurl
        );
        console.log(this.urlSafe);
        setTimeout(() => {
          $(".casinoDrag").show();
        }, 5000);
        window.scroll(0, 60);
        return;
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotsfun(data) {
    this.urlSafe = null;
    this.keyname = data;
    if (this.brandid == null) {
      this.hitfunService();
    } else if (this.brandid != null) {
      this.gamelunchurl =
        this.slotsfundata.HABANERO_GAMING_URL +
        "brandid=" +
        this.slotsfundata.BRAND_ID +
        "&keyname=" +
        this.keyname +
        "&mode=fun&locale=en";
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.gamelunchurl
      );
      console.log(this.urlSafe);

      window.scroll(0, 150);
      return;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
    }
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;
    }
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  slotsGameLunch() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new loginActions.ResetState());
    this.gamelunchurl = this.mrgameLunchUrl;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.gamelunchurl
    );
    setTimeout(() => {
      $(".casinoDrag").show();
    }, 5000);

    window.scroll(0, 150);
    return;
  }
  fishinggame(data) {
    this.shootinglist = [];
    const fishingGames = [];
    for (let i = 0; i < data.response.length; i++) {
      if (data.response[i].categories == "fishing") {
        fishingGames.push(data.response[i]);
      }
    }
    this.fishGames = fishingGames;
    console.log(this.fishGames);
  }
  betsolutionsCall(id, provider, name) {
    console.log(id)
    console.log(provider)
    console.log(name)


    const body = {
      gameMode: "real_play",
      gameId: id,
      provider: provider,
    };
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.loginService
      .AllGamesLunch(body)
      .subscribe((res) => {
        if (this.playerLoggedIn) {
          if (res.status == 200) {
            window.open(res.gameUrl, '_self')
          }
        } else if (this.playerLoggedIn == false) {
          this.casinomessage = "Please login";
          this.casinogamessuccessPop = true;
        }
      });
  }


  closegame(event) {
    this.openTab = event;
    this.eventname = event
    this.showgamefrom = 0;
    
    if (event === "Ezugi") {
      console.log(this.liveCasinoGmList)

      this.liveCasinoGmList = this.liveCasinoGmList.filter(Obj => Obj.provider !== "Vivo");
      console.log(this.liveCasinoGmList)
    } 
    // else if (event === "livecasino") {
    //   let addViov = this.liveCasinoGmList.filter(Obj => Obj.provider === "Vivo");
    //   if (addViov.length === 0) {
    //     this.liveCasinoGmList.push(
    //       {
    //         "id": "1",
    //         "name": "Roulette",
    //         "provider": "Vivo",
    //         "media": "vivo_roulette"
    //       },
    //       {
    //         "id": "2",
    //         "name": "Baccarat",
    //         "provider": "Vivo",
    //         "media": "vivo_baccarat"
    //       },
    //       {
    //         "id": "3",
    //         "name": "Blackjack",
    //         "provider": "Vivo",
    //         "media": "vivo_blackjack"
    //       },
    //       {
    //         "id": "4",
    //         "name": "Teenpatti",
    //         "provider": "Vivo",
    //         "media": "vivo_teenpatti"
    //       }
    //     )
    //   }
    // }
    else if(event === "vivo"){
      this.slotsservice.vivoGames().subscribe((data) => {
        console.log(data)
        this.vivoGamesData = data.VivoGaming;
       });
    }
  }
  liveGamesList(data: any) {
    this.liveCasinoGmList = data.livecasino.filter(Obj => Obj.provider !== "Vivo");
    console.log(data)
    // this.liveCasinoGmList = data.livecasino;
  }
  luckstrik(data: any) {
    this.lucky = data.luckstrikk;
    // this.liveCasinoGmList.push(
    //   {
    //     "id": "1",
    //     "name": "Roulette",
    //     "provider": "Vivo",
    //     "media": "vivo_roulette"
    //   },
    //   {
    //     "id": "2",
    //     "name": "Baccarat",
    //     "provider": "Vivo",
    //     "media": "vivo_baccarat"
    //   },
    //   {
    //     "id": "3",
    //     "name": "Blackjack",
    //     "provider": "Vivo",
    //     "media": "vivo_blackjack"
    //   },
    //   {
    //     "id": "4",
    //     "name": "Teenpatti",
    //     "provider": "Vivo",
    //     "media": "vivo_teenpatti"
    //   }
    // )
    console.log(this.liveCasinoGmList)
  }
  shootinggames(data: any) {
    this.shootinglist = [];
    console.log(data);
    this.shootingID = sessionStorage.getItem("shoot");
    if (this.shootingID) {
      this.allSlotsList = [];
      this.liveCasinoGmList = [];
      this.gamesArray = [];
      this.crashgames = [];
      for (let i = 0; i < data.response.length; i++) {
        console.log(data.response.length);
        if (data.response[i].categories == "shooting") {
          console.log(data.response[i].categories == "shooting");
          this.shootinglist.push(data.response[i]);
        }
      }
    }
    console.log(this.shootinglist);
  }
  goToUrl(id: any, providername: any, Game: any) {
    if (this.playerLoggedIn) {
      const body = {
        gameMode: "real_play",
        gameId: id,
        provider: providername,
      };
      this.loginService
        .AllGamesLunch(body)
        .subscribe((data) => this.gameSlot(data));
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());

      this.loader_game = Game;
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  gameSlot(data) {
    if (this.playerLoggedIn) {
      if (data.status == 200) {
        console.log(data.gameUrl);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          data.gameUrl
        );
        window.scroll(0, 60);
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotsLunchreal(id: any, providername: any, Game: any) {
    if (this.playerLoggedIn) {
      const body = {
        gameMode: "real_play",
        gameId: id,
        provider: providername,
      };
      this.loginService
        .AllGamesLunch(body)
        .subscribe((data) => this.gameSlot1(data));
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());

      this.loader_game = Game;
    } else {
      this.casinogamessuccessPop = true;
    }
  }
  gameSlot1(data) {
    if (this.playerLoggedIn) {
      if (data.status == 200) {
        console.log(data.gameUrl);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          data.gameUrl
        );
        window.scroll(0, 60);
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  mojusreal(id, pro) {
    const body = {
      gameId: id,
      gameMode: "real_play",
      provider: pro,
    };
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.cover = true;
      this.slotsservice.getSlottyUrl(body).subscribe((data) => {
        this.mojuslots(data);
      });
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  mojuslots(data) {
    if(this.playerLoggedIn) {

      console.log(data);
      window.scroll(0, 0);
      if (data) {
        setTimeout(() => {
          this.cover = false;
        }, 1000);
        window.open(data.gameUrl,'_self');
        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        //   data.gameUrl
        // );
        // window.scroll(0, 60);
      }
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }

  liveDealer(gname, pro) { 
    if (this.playerLoggedIn == false) {
      this.casinomessage = "Please Login"
    }
    console.log(gname)
    console.log(pro)
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());

    if (this.playerLoggedIn == true) {
      this.showLoad = true;
      if (pro == "Vivo") {
        let type = (gname).split("_")[1]
        this.vivoGames(type)
      } else {
        this.gameName = gname;
        let wsession = this.cookieService.get("wsession");
        console.log(wsession);
        this.slotsservice.liveDealerServiceData(wsession).subscribe((liveDealerdata) => { this.liveDealerunch(liveDealerdata) });
      }
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
    }
  }
  liveDealerunch(data) {
    let ezugiLunch =
      data.EZUGI_GAME_URL +
      "token=" +
      data.EZUGI_TOKEN +
      "&operatorId=" +
      data.EZUGI_OPERATOR_ID +
      "&clientType=html5&language=en&selectGame=" +
      this.gameName;
    console.log(ezugiLunch);
    sessionStorage.setItem("gameurl", ezugiLunch);
    if (ezugiLunch) {
      let otherGames = "all";
      sessionStorage.setItem("urlweareGame", otherGames);
      window.open(ezugiLunch, "_self")
      setTimeout(() => {
        this.showLoad = false;
      }, 1500);
    } else if (ezugiLunch == "") {
      this.router.navigate(["/"]);
    }
  }

  closeMe() {
    this.urlSafe = "";
    this.showgamefrom = 0;
  }

  clickMe() {
    this.showgamefrom = 0;
    $(".casinoDrag").hide();
    this.urlSafe = "";
  }

  openLoginDialog() {
    this.casinogamessuccessPop = true;
  }
  launchFreshDeck() {
    if (this.playerLoggedIn == false) {
      this.casinomessage = "Please Login"
    }
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
    } else {
      this.showLoad = true;
      this.casinoGamesService.launchFreshDeck().subscribe(
        (data) => {
          if (!data)
            this.casinomessage = "Error loading";
          else {
            let redirectionurl = data["FRESH_DECK_LAUNCH_URL"];
            window.open(redirectionurl, '_self');
            setTimeout(() => {
              this.showLoad = false;
            }, 1500);
            // return location.replace(redirectionurl);
            // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(redirectionurl);
          }
        },
        (err) => {
          this.casinomessage = "Error loading";
        }
      );
    }
  }
  shootingLunchreal(id: any, providername: any, game: any) {
    if (this.playerLoggedIn) {
      console.log(id, providername);
      const body = {
        gameMode: "real_play",
        gameId: id,
        provider: providername,
      };
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.loginService
        .AllGamesLunch(body)
        .subscribe((data) => this.gameShoot(data));
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  gameShoot(data) {
    if (this.playerLoggedIn) {
      if (data.status == 200) {
        sessionStorage.setItem("gameurl", data.gameUrl);
        if (data.gameUrl) {
          let otherGames = "all";
          sessionStorage.setItem("urlweareGame", otherGames);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
            data.gameUrl
          );
          window.scroll(0, 60);
        } else if (data.gameUrl == "") {
          this.router.navigate(["/"]);
        }
      }
    } else {
      this.casinogamessuccessPop = true;
    }
  }
  exitPopup() {
    this.canclePopup = false;
    sessionStorage.setItem('myPopup', 'true');
  }
  vivoGames(name) {
    if (this.playerLoggedIn) {
      this.showLoad = true;
      // let ProName = sessionStorage.getItem('wsession')
      this.slotsservice.vivogameApi().subscribe((response) => {
        // this.vivogamesurl(response);
        // console.log(response)
        // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        const launchUrl = response.VIVO_GAME_LAUNCH_URL.split("selectedGame=")[0] + `selectedGame=${name}` + response.VIVO_GAME_LAUNCH_URL.split("selectedGame=All")[1];
        // console.log(launchUrl)
        window.open(launchUrl, '_self')
      });
      setTimeout(() => {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        this.showLoad = false;
      }, 2000);
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }

  crashGamesMethod(id, pro) {
    if (this.playerLoggedIn) {
      this.showLoad = true;
      const body = {
        gameId: id,
        gameMode: "real_play",
        provider: pro,
      };
      this.store.dispatch(new loginActions.ResetState()); 
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        this.cover = true;
        this.slotsservice.getSlottyUrl(body).subscribe((data) => {
          this.mojuslots(data);
          this.showLoad = false;
        }); 
    }else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
}
