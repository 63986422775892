import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as playerActions from './playerActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { PlayerService } from '../../services/player/player.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Profile } from '../../modules/player/profile';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { GameLaunchURLs } from '../../modules/player/gameLaunchURLs';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';

//import { ToastrService } from 'ngx-toastr';
import { on } from '@ngrx/store';
import { UpDatePasswordModel } from '../../modules/player/updatepassword';
import { GameHistory } from '../../modules/player/gameHistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';
import { UpDateProfileModel } from '../../modules/player/updateprofile';

declare const getUsername:any;
const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "Something went wrong please contact admin" }));
        } catch (error) {

        }
    }

    return of(new actions({ "message": "Something went wrong please contact admin" }));
}

@Injectable()
export class PlayerEffects {
    constructor(private actions$: Actions,
        private playerService: PlayerService,
        private router: Router,
        //private toastr: ToastrService,
        private loginservice: LoginService, ) { }
    @Effect()
    playerGetProfile = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PROFILE),
        exhaustMap((onPlayerGetProfile: playerActions.PlayerGetProfile) =>
            this.playerService.onPlayerGetProfile()
                .pipe(
                    map((response: Profile) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetProfileSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {

                        return handleError(error, playerActions.PlayerGetProfileFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetProfileSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PROFILE_SUCCESS),
        tap((onPlayerGetProfileSuccess: playerActions.PlayerGetProfileSuccess) => {
            // if (onPlayerGetProfileSuccess.payload.code === "SESSION_EXPIRED") {
            //     this.loginservice.sessinExpireToLogout();
            // }
            if (onPlayerGetProfileSuccess.payload.success === true) {
                this.loginservice.onPlayerLoggedIn(true);
                // let token = sessionStorage.getItem('accessToken');
                // if (token == null) {
                //     this.playerService.getSportsBookToken(onPlayerGetProfileSuccess.payload.login);
                // }
                let name = onPlayerGetProfileSuccess.payload.login;
                sessionStorage.setItem('profile', onPlayerGetProfileSuccess.payload.login);
                getUsername(name);
                sessionStorage.setItem('playermobilenumber', onPlayerGetProfileSuccess.payload.address.phone);
                // this.loginservice.onGetPlayerBalance();
            }
        })
    )


    @Effect()
    playerUpdateProfile = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PROFILE),
        exhaustMap((onPlayerUpdateProfile: playerActions.PlayerUpdateProfile) =>
            this.playerService.onPlayerUpdateProfile(onPlayerUpdateProfile.payload)
                .pipe(
                    map((response: UpDateProfileModel) => {
                    
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerUpdateProfileSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        // if(error.status === 500 || error.status === 502){
        
                        //     this.loginservice.sessinExpireToLogout();
                        // }
                        return handleError(error, playerActions.PlayerUpdateProfileFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerUpdateProfileSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PROFILE_SUCCESS),
        tap((onProfileUpdateSuccess: playerActions.PlayerUpdateProfileSuccess) => {
           if (onProfileUpdateSuccess.payload.success == true) {
                this.loginservice.getMyprofile();
                //this.toastr.success(onProfileUpdateSuccess.payload.description);
            }else if (onProfileUpdateSuccess.payload.success == false) {
                // this.toastr.error(onProfileUpdateSuccess.payload.description);
            }  

        })
    )

    @Effect()
    playerUpdatePassword = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PASSWORD),
        exhaustMap((onPlayerUpdatePassword: playerActions.PlayerUpdatePassword) =>
            this.playerService.onPlayerUpdatePassword(onPlayerUpdatePassword.payload)
                .pipe(
                    map((response: UpDatePasswordModel) => {
                      
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerUpdatePasswordSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {

                        //this.toastr.error(error.message);
                        return handleError(error, playerActions.PlayerUpdatePasswordFail);
                    })
                )
        )
    )


    @Effect({ dispatch: false })
    PlayerUpdatePasswordSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_UPDATE_PASSWORD_SUCCESS),
        tap((onPlayerUpdatePasswordSuccess: playerActions.PlayerUpdatePasswordSuccess) => {
            if (onPlayerUpdatePasswordSuccess.payload.success == true) {

            } 
        })
    )

    @Effect()
    playerGetLaunchURLs = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LAUNCHURLS),
        exhaustMap((onPlayerGetLaunchURLs: playerActions.PlayerGetLaunchURLs) =>
            this.playerService.onPlayerGetLaunchURLs()
                .pipe(
                    map((response: GameLaunchURLs) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetLaunchURLsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetLaunchURLsFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetLaunchURLsSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_LAUNCHURLS_SUCCESS),
        tap((onPlayerGetLaunchURLsSuccess: playerActions.PlayerGetLaunchURLsSuccess) => {
            this.playerService.launchFlashGame(onPlayerGetLaunchURLsSuccess.payload);
         
        })
    )

    @Effect()
    playerGetGameHistory = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_GAME_HISTORY),
        exhaustMap((onPlayerGetGameHistory: playerActions.PlayerGetGameHistory) =>
            this.playerService.onPlayerGetGameHistory(onPlayerGetGameHistory.payload)
                .pipe(
                    map((response: GameHistory) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetGameHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetGameHistoryFail);
                    })
                )
        )
    )
    @Effect()
    PokerGetGameHistory = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_POKER_HISTORY),
        exhaustMap((onPokerGetGameHistory: playerActions.PokerGetGameHistory) =>
            this.playerService.onPokerGetGameHistory(onPokerGetGameHistory.payload)
                .pipe(
                    map((response: object) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PokerGetGameHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PokerGetGameHistoryFail);
                    })
                )
        )
    )
    @Effect()
    PlayerGetRemoteGameHistory = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_REMOTE_GAME_HISTORY),
        exhaustMap((onPlayerGetRemoteGameHistory: playerActions.PlayerGetRemoteGameHistory) =>
            this.playerService.onPlayerGetRemoteGameHistory(onPlayerGetRemoteGameHistory.payload)
                .pipe(
                    map((response: RemoteGamesHistory) => {
                        return new playerActions.PlayerGetRemoteGameHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetRemoteGameHistoryFail);
                    })
                )
        )
    )
    @Effect()
    playerGetTournamentsList = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_TOURNAMENTS_LIST),
        exhaustMap((onPlayerGetTournamentsList: playerActions.PlayerGetTournamentsList) =>
            this.playerService.onPlayerGetTournamentsList(onPlayerGetTournamentsList.payload)
                .pipe(
                    map((response: TournamentsList) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetTournamentsListSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetTournamentsListFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    playerGetTournamentsListSuccess = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_TOURNAMENTS_LIST_SUCCESS),
        tap((onPlayerGetTournamentsListSuccess: playerActions.PlayerGetTournamentsListSuccess) => {

        })
    )

    @Effect()
    playerGetStats = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_STATS),
        exhaustMap((onPlayerGetStats: playerActions.PlayerGetStats) =>
            this.playerService.onPlayerGetStats()
                .pipe(
                    map((response: Stats) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetStatsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetStatsFail);
                    })
                )
        )
    )

    @Effect()
    playerGetPlayerLevels = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_PLAYERLEVELS),
        exhaustMap((onPlayerGetPlayerLevels: playerActions.PlayerGetPlayerLevels) =>
            this.playerService.onPlayerGetPlayerLevels()
                .pipe(
                    map((response) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetPlayerLevelsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetPlayerLevelsFail);
                    })
                )
        )
    )

    @Effect()
    PlayerGetTopCPPlayers = this.actions$.pipe(
        ofType(playerActions.PLAYER_GET_TOPCPPLAYERS),
        exhaustMap((onPlayerGetTopCPPlayers: playerActions.PlayerGetTopCPPlayers) =>
            this.playerService.onPlayerGetTopCPPlayers()
                .pipe(
                    map((response) => {
                        this.loginservice.sessionlogout(response);
                        return new playerActions.PlayerGetTopCPPlayersSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, playerActions.PlayerGetTopCPPlayersSuccess);
                    })
                )
        )
    )

}