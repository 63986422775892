import { Component, OnInit } from '@angular/core';
// import { Subscription } from 'rxjs';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
import { SlotsService } from "src/app/source/services/slots.service";
import { Store } from "@ngrx/store";
import * as cashierActions from "src/app/source/appstates/cashierstates/cashierActions";
import * as appState from "src/app/source/appstates/appState";

@Component({
  selector: 'app-live-dealar',
  templateUrl: './live-dealar.component.html',
  styleUrls: ['./live-dealar.component.css']
})
export class LiveDealarComponent implements OnInit {
  loaderlogo: boolean = true;
  vivoGamesData = [];
  openTab: any;
  constructor(private casinoGamesService: CasinoGamesService, private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    // this.Livedealar()
    // this.casinoGamesService.launchFreshDeck().subscribe((data) => { this.launch(data) })

  }

  ngOnInit() {
    // this.Livedealar()
    this.closegame('vivo');
    this.slotsservice.vivoGames().subscribe((data) => { this.vivoGamesData = data.VivoGaming });
    // this.casinoGamesService.launchFreshDeck().subscribe((data) => { this.launch(data) })
  }

  // launch(data) {
  //   setTimeout(() => { this.loaderlogo = false }, 2000)
  //   window.open(data["FRESH_DECK_LAUNCH_URL"], "_self");
  // }

  closegame(event) {
    console.log(event)
    // alert(event)
    this.openTab = event;
  }
  vivoGames(name) {
    let ProName = sessionStorage.getItem('wsession')
    this.slotsservice.vivogameApi().subscribe((response) => {
      console.log(response)
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      const launchUrl = response.VIVO_GAME_LAUNCH_URL.split("selectedGame=")[0] + `selectedGame=${name}` + response.VIVO_GAME_LAUNCH_URL.split("selectedGame=All")[1];
      console.log(launchUrl)
      // console.log(launchUrl)
      window.open(launchUrl, '_self')
    });
  }

}
