import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnrightclick]'
})
export class OnrightclickDirective {
  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event) {
  //   event.preventDefault();
  // }
  // @HostListener('window:keydown', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if(event.keyCode == 123) {
  //     return false;
  //     }
  //     if(event.ctrlKey && event.shiftKey && event.keyCode == 'I'.charCodeAt(0)){
  //     return false;
  //     }
  //     if(event.ctrlKey && event.shiftKey && event.keyCode == 'J'.charCodeAt(0)){
  //     return false;
  //     }
  //     if(event.ctrlKey && event.keyCode == 'U'.charCodeAt(0)){
  //     return false;
  //     }
  // }
  constructor() { 
    }
}
