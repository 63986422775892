import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
//import { type } from 'os';
import { TransactionResponse } from '../../source/modules/cashier/transactionsresponsehistory';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConstants } from 'src/app/source/constants/appconstants';
import { filter } from 'rxjs/operators';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-ptoptransfers',
  templateUrl: './ptoptransfers.component.html',
  styleUrls: ['./ptoptransfers.component.css']
})
export class PtoptransfersComponent implements OnInit {

  private storeSub: Subscription;
  private loginSub: Subscription;
  form: FormGroup;
  transaction: any;
  errormessage: string = "";
  selectedType: any = "Transfer to Player,Transfer from Player"
  startDate: Date;
  todaydate: any;
  endDate: Date;
  p: number = 1;
  historyloader: boolean = false;
  selectnum: number = 10;
  description: boolean = false;
  playerLoggedIn: boolean = false;
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate:any;
  selectedtypedata: any;
  lang: any = appConstants.language;
  constructor(private fb: FormBuilder, private store: Store<appState.AppState>) {
    this.store.dispatch(new cashierActions.ResetState());
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.endDate = new Date();
    let oneweek = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
    this.startDate = oneweek;
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');

    this.form = new FormGroup({
      "currency": new FormControl('CHP', Validators.required),
      "startDate": new FormControl('', Validators.required),
      "endDate": new FormControl('', Validators.required),
      "limit": new FormControl(100, Validators.required),
      "index": new FormControl(0, Validators.required)
    });
    this.form.value.startDate = this.startDate;
    this.form.value.endDate = this.endDate;
    this.form.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.TransactionResponse) {
          this.historyloader = false;
          if (cashierState.TransactionResponse.success) {
            if (cashierState.TransactionResponse.values) {
              if (!cashierState.TransactionResponse.values.length) {
                this.description = true;
                this.setError("There is no History  for the selected Period");
              } else {
                this.transaction = cashierState.TransactionResponse.values;
              }
            }
          } else {
            if (cashierState.TransactionResponse.description == 'NO_DATA_FOUND') {
              this.setError("There is no History  for the selected Period");
              return;
            }
            this.setError(cashierState.TransactionResponse.description);
          }
        }
        if (cashierState.TranscationResponseFail) {
          this.setError(cashierState.TranscationResponseFail.message);
        }

      }
    )

    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    // this.onFormSubmit();
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
  }
  get today() {
    return new Date();
  }
  onFormSubmit() {
    this.store.dispatch(new cashierActions.ResetState());
    this.historyloader = true;
    const body = {
      currency: this.form.value.currency,
      startDate: moment(this.form.value.startDate).format('MM-DD-YYYY'),
      endDate: moment(this.form.value.endDate).format('MM-DD-YYYY'),
      limit: this.form.value.limit,
      index: this.form.value.index,
      type: this.selectedType

    };
    this.errormessage = null;
    this.transaction = null;
    this.description = false;
    this.p = 1;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierTransactionHistory(body));
    // this.store.dispatch(new cashierActions.CashierTransactionHistoryBYToken(body));

    // let bonusadjustvalue = this.selectedType.filter(this.bonusadjustment);
    // if (bonusadjustvalue == "Bonus Adjustment") {
    //   const body = {
    //     currency: this.form.value.currency,
    //     startDate: moment(this.form.value.startDate).format('MM-DD-YYYY'),
    //     endDate: moment(this.form.value.endDate).format('MM-DD-YYYY'),
    //     limit: this.form.value.limit,
    //     index: this.form.value.index,
    //     type: "Bonus Adjustment"
    //   };
    //   this.store.dispatch(new cashierActions.CashierTransactionHistory(body));
    // }
    // let indexDep = this.selectedType.indexOf('Deposits');
    // let indexCash = this.selectedType.indexOf('Cashout');
    // if(indexDep != -1 && indexCash != -1){
    // this.selectedtypedata = "Deposits, Cashout";

    // }else if(indexDep != -1){

    //   this.selectedtypedata = "Deposits";
    // }else if(indexCash != -1){

    //   this.selectedtypedata = "Cashout";
    // };
    // if(indexDep != -1 || indexCash != -1){
    //   const body = {
    //     currency: this.form.value.currency,
    //     startDate: moment(this.form.value.startDate).format('DD-MM-YYYY'),
    //     endDate: moment(this.form.value.endDate).format('DD-MM-YYYY'),
    //     limit: this.form.value.limit,
    //     index: this.form.value.index,
    //     type: this.selectedtypedata

    //  };
    //     this.store.dispatch(new cashierActions.CashierTransactionHistoryBYToken(body));
    // }


  }

  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }


  setError(message) {
    if (this.lang == 'FA') {
      this.errormessage = 'سابقه ای برای دوره انتخاب شده وجود ندارد';
    } else {
      this.errormessage = message;
    }
  };
}

