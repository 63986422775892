import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstates/appState';
import { LoginState } from '../../appstates/loginstates/loginState';
import { environment } from '@app/env';
import { appConstants } from '../../constants/appconstants';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
}) export class CommonUtilService {
    playerLoggedIn: boolean = false;
    constructor(private httpClient: HttpClient, private store: Store<AppState>) {

    }

    loadCashbalanceBasedOnCurrency(walletObjs, walletName, key) {
        console.log( walletObjs, walletName, key )
        let res = 0;
        if (walletObjs) {
            walletObjs.forEach((wobj) => {
                if ("wallet" in wobj && "name" in wobj["wallet"] &&
                    wobj["wallet"]["name"].toLowerCase() == walletName.toLowerCase()
                    && key in wobj) {
                    res = wobj[key]["value"];
                }
            })
        }
        return  this.getRoundedOffNumber(res);
    }
    loadAppPreferredCurrency(walletObjs) {
        let appCurrency = "NOT FOUND";
        if (walletObjs) {
            walletObjs.forEach((wobj) => {
                if ("wallet" in wobj && "name" in wobj["wallet"] &&
                    !(["PLAYMONEY", "COMPPOINTS"].includes(wobj["wallet"]["name"]))) {
                    appCurrency = wobj["wallet"]["name"];
                }
            })
        }
        return appCurrency;
    }

    addNumbers(arr) {
        let s = 0;
        arr.forEach((k) => { if (k) s += Number(k) });
        return s;
    }

    getRoundedOffNumber(n) {
        if (n) {
            n = Number(n).toFixed(2);
        }
        return n;
    }
}