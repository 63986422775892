import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Sports } from '../../modules/common/livedata/sports';
import { CommondataService } from './commondata.service';
import { WebService } from './web.service';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private _connection: WebSocket;
  private network_Connected = new Subject<string>();
  private network_error = new Subject<object>();
  constructor(private CommondataService: CommondataService, public router: Router,) {
  }
  connect(url: string) {
    this._connection = new WebSocket(url);
    this._connection.onopen = (res) => {
      this.network_Connected.next('connected');
    };

    this._connection.onmessage = (event) => {
      let data = JSON.parse(event['data']);
      if (Object.keys(data).length === 0
        && data.constructor === Object) {
        console.log('data not reived');
        this.codeError();

      } else {

        this.codeError();
        console.log('data get');
        if (this.router.url == '/liveMatch') {
          this.returnData(data);

        } else {
          console.log('connection close');
          this._connection.close();
        }
      }
    };
    this._connection.onclose = (event) => {

      if (this.router.url == '/liveMatch') {
        this.closeconnection();
      }

    }
    this._connection.onerror = (error) => {
      this.network_error.next(error);
      this.statusandcode();

      console.log(error['message'] + 'or' + error['statusText'] + "websocket error");
    };
  }
  send(object) {
    this._connection.send(object);
  }
  statusandcode() {
    const body = {
      status: 1,
      code: 1
    }
    this.CommondataService.setLiveonclosed(body);
    this.CommondataService.setLive(body);
  }
  codeError() {
    const body = {
      code: 1
    }
    this.CommondataService.setLiveonclosed(body);
  }
  closeconnection() {
    const body = {
      code: 1006
    }
    this.CommondataService.setLiveonclosed(body);
    setTimeout(() => {
      this.connect(localStorage.getItem('websocketurl'));
    }, 15000);
  }
  returnData(event) {
    let json;
    try {
      json = event;
      const body = {
        code: null,
        status:null
      }
      console.log('caneled get');
      this.CommondataService.setLiveonclosed(body);
      // console.log(json);
      this.CommondataService.setLive(json);

    } catch (error) {
      console.log(error + "websocket catch error");
    }
  }

  get onConnection(): Subject<string> {
    return this.network_Connected;
  }
  get onError(): Subject<object> {
    return this.network_error;
  }
}
