import { Component, OnInit } from '@angular/core';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CommonUtilService } from 'src/app/source/services/common/commonutil.service';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerService } from 'src/app/source/services/player/player.service';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ex-currency',
  templateUrl: './ex-currency.component.html',
  styleUrls: ['./ex-currency.component.css']
})
export class ExCurrencyComponent implements OnInit {

  public convertBtn: boolean = false;
  currency1: string = 'TOMAN';
  currency2: string = 'USD';
  CurrencySy: string = "$"
  userEnteredAmount: any = '';
  convertedAmount: any = '';
  errorMsg: any;
  private loginSub: Subscription;
  private storeSub: Subscription;
  playerLoggedIn: boolean = false;
  setSelection: string = "USD";
  covertTxt: string = "Please enter value below";
  USD_wallet: any;
  CHP_wallet: any;
  playerBalance: any;
  currencyselection: string = "USD"
  // selectedDropDownCurrencyValue: any = 5;
  chpExchangerate: any;
  usdExchangerate: any;
  dataLoad: boolean = false;
  summeryMsg: string = "";
  isError: boolean = false;
  errMsg: string = "";
  showBtn: boolean = true;
  errorDisable: boolean = false;

  payoutUSDamount = [
    5,
    10,
    20,
    30,
    40,
    50,
    100,
    200,
    500,
    1000,
    2000,
    3000
  ]
  payoutamount = [
    250000,
    500000,
    750000,
    1000000,
    1500000,
    2000000,
    2500000,
    5000000
  ];
  historyloader: boolean = false;
  imagepath: string;

  constructor(private store: Store<appState.AppState>, private commonUtilSer: CommonUtilService, private playerService: PlayerService) {
    let bodyCHP = {
      "walletCode": "CHP"
    }
    this.playerService.exchangRates(bodyCHP).subscribe(res => {
      console.log(res);
      this.chpExchangerate = res.responses.CHP[0].rate.split(".")[0];
      console.log(this.chpExchangerate);
      this.showBtn = false;
    })
    let bodyUSD = {
      "walletCode": "USD"
    }
    this.playerService.exchangRates(bodyUSD).subscribe(res => {
      console.log(res)
      this.usdExchangerate = res.responses.USD[0].rate;
      this.showBtn = false;
    })
  }

  ngOnInit() { 
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });

    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          if (cashierState.balance.success === true) {
            this.playerBalance = cashierState.balance;
            this.showDataToView("CHP");

          }
        }
      }
    );
  }
  showDataToView(wallet: any) {
    console.log(wallet)
    this.dataLoad = true
    this.setSelection = wallet;
    console.log(this.setSelection)
    let body = {
      "walletCode": (wallet === "CHP" ? "CHP" : "USD")
    }
    // this.playerService.exchangRates(body).subscribe(res => {
    //   console.log(res);

    //   this.dataLoad = false;
    //   if (wallet === "CHP") {
    //     this.changeAmtVal('')

    //     // this.userEnteredAmount = this.userEnteredAmount || res.responses.CHP[0].rate;
    //     this.summeryMsg = `1 USD is equals to ${this.chpExchangerate} TOMAN`;
    //     this.covertTxt = "Convert USD into TOMAN";
    //   } else {

    //     // this.userEnteredAmount=this.selectedDropDownCurrencyValue || 5;
    //     // this.userEnteredAmount = res.responses.USD[0].rate;
    //     this.summeryMsg = `1 TOMAN is equals to ${this.usdExchangerate} USD`;
    //     this.covertTxt = "Convert TOMAN into USD";
    //   }
    // })

    this.playerBalance.values.map(res => {
      console.log(res);
      if (res.wallet.name === "USD") {
        this.USD_wallet = res.cash.value;
      } else if (res.wallet.name === "CHP") {
        this.CHP_wallet = res.cash.value;
      }
    })

    // this.currencyValue
  }
  changeAmtVal(value: string) {
    this.errorMsg = ''
    // this.EnteredAmount = 0
    this.userEnteredAmount = value;
    if (this.userEnteredAmount === '') {
      if(localStorage.getItem('locale') == 'FA') {
        this.errorMsg = "لطفا یک مقدار معتبر وارد کنید"
      } else {

        this.errorMsg = "Please enter a valid amount"
      }
      this.convertedAmount = '';
    } else {
      const amount = parseFloat(this.userEnteredAmount);
      if (isNaN(amount) || amount <= 0) {
        this.convertedAmount = '';
        if(localStorage.getItem('locale') == 'FA') {
          this.errorMsg = "لطفا یک مقدار معتبر وارد کنید"
        } else {

          this.errorMsg = "Please enter a valid amount";
        }
      } else {
        if (this.currency1 === "USD" && this.currency2 === "TOMAN") {
          if (amount > this.USD_wallet) {
            if(localStorage.getItem('locale') == 'FA') {
              this.errorMsg = "مقدار از موجودی شما بیشتر است";
            } else {

              this.errorMsg = "Amount exceeds your balance";
            }
            this.errorDisable = true
            this.convertedAmount = '';
          } else {
            this.convertedAmount = (amount * this.chpExchangerate).toFixed(7);
            this.errorDisable = false
          }
        } else if (this.currency1 === "TOMAN" && this.currency2 === "USD") {
          if (amount > this.CHP_wallet) {
            if(localStorage.getItem('locale') == 'FA') {
              this.errorMsg = "مقدار از موجودی شما بیشتر است";
            } else {

              this.errorMsg = "Amount exceeds your balance";
            }
            this.errorDisable = true
            this.convertedAmount = '';
          } else {
            this.convertedAmount = (amount * this.usdExchangerate).toFixed(7);
            this.errorDisable = false
          }
        }
      }

    }
  }
  changeConvertedVal(value: string) {
    this.errorMsg = ''
    this.convertedAmount = value;
    if (this.convertedAmount === '') {
      this.userEnteredAmount = '';
      this.errorMsg = "Please enter a valid amount"
    } else {
      const amount = parseFloat(this.convertedAmount);
      if (isNaN(amount) || amount <= 0) {
        this.userEnteredAmount = '';
        this.errorMsg = "Please enter a valid amount";
      } else {
        if (this.currency1 === "USD" && this.currency2 === "TOMAN") {
          this.userEnteredAmount = (amount / this.chpExchangerate).toFixed(7);
        } else if (this.currency1 === "TOMAN" && this.currency2 === "USD") {
          this.userEnteredAmount = (amount / this.usdExchangerate).toFixed(7);
        }
      }
    }
  }


  submitExchangeCurrency() {
    if (this.currency1 === "USD" && this.userEnteredAmount > this.USD_wallet) {
      if(localStorage.getItem('locale') == 'FA') {
        this.errorMsg = "مقدار از موجودی شما بیشتر است";
      } else {

        this.errorMsg = "Amount exceeds your balance";
      }
      this.errorDisable = true
      return;
    } else if (this.currency1 === "TOMAN" && this.userEnteredAmount > this.CHP_wallet) {
      if(localStorage.getItem('locale') == 'FA') {
        this.errorMsg = "مقدار از موجودی شما بیشتر است";
      } else {

        this.errorMsg = "Amount exceeds your balance";
      }
      this.errorDisable = true
      return;
    }
    if (this.currency1 == "USD") {
      this.setSelection = "CHP"
    } else {
      this.setSelection = "USD"
    }
    console.log(this.setSelection)
    if (this.playerLoggedIn) {
      this.showBtn = true;
      this.historyloader = true;
      const amount = String(this.userEnteredAmount);

      if (this.setSelection == "CHP") {
        let body = {
          "walletCode": this.setSelection,
          "keyVsValue": {
            "AMOUNT_cash_USD": amount,
            // "AMOUNT_cash_USD": this.selectedDropDownCurrencyValue,
            "AMOUNT_bonus_USD": "0.00",
            "AMOUNT_tm_USD": "0.00"
          }
        }
        this.playerService.walletExchange(body).subscribe(res => this.exchangeRatesApiRes(res))
      } else {
        let body = {
          "walletCode": this.setSelection,
          "keyVsValue": {
            "AMOUNT_cash_CHP": amount,
            // "AMOUNT_cash_CHP": this.selectedDropDownCurrencyValue,
            "AMOUNT_bonus_CHP": "0.00",
            "AMOUNT_tm_CHP": "0.00"

          }
        }

        this.playerService.walletExchange(body).subscribe(res => this.exchangeRatesApiRes(res))
      }
    } else {
      this.isError = true;
      this.errMsg = "Please login"
    }
  }
  exchangeRatesApiRes(res: any) {
    console.log(res)
    this.isError = true;
    if (res.success == true) {
      this.errMsg = "Exchange Converted successfull";
      this.historyloader = false;
      setTimeout(() => {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      }, 500);
    } else {
      this.errMsg = res.description
      this.historyloader = false;

    }
  }
  resetError() {
    this.isError = false;
    this.showBtn = false;
  }


  swapcurrency() {
    this.setSelection = ''
    // this.userEnteredAmount = ''
    this.CurrencySy = ''
    const temp = this.currency1;
    this.currency1 = this.currency2;
    this.currency2 = temp;
    // this.userEnteredAmount = '';
    // this.convertedAmount = '';
    // this.changeAmtVal('');

    const tempAmount = this.userEnteredAmount;
    this.userEnteredAmount = this.convertedAmount;
    this.convertedAmount = tempAmount;
    // this.changeAmtVal(this.userEnteredAmount)

    if (this.currency1 == "USD") {

      this.showDataToView("USD")
      this.setSelection = "CHP"
      document.getElementById("fontSize").style.fontSize = "15px"
      this.CurrencySy = "CHP"
    } else {

      this.showDataToView("CHP")
      this.setSelection = "USD"
      document.getElementById("fontSize").style.fontSize = "19px"

      this.CurrencySy = "$"
    }
    console.log(this.setSelection);
    // this.changeAmtVal('')

  }

}
