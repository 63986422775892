import * as loginActions from './loginActions';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { LoginOut } from '../../modules/login/logout';
import { forgotResponse } from '../../modules/forgotpassword';
import { HttpClientError } from '../../modules/login/httpClientError';
import { PlayerLoggedIn, AllGameLunch } from '../../modules/login/playerLoggedIn';
import { ResetPassword } from '../../modules/login/resetpassword';
import { OtpRequest } from '../../modules/login/otprequest'; 

export interface LoginState {
    
    countries: Countries;
    loginResponse: Login;
    OtpRequestResponse: OtpRequest;
    OtpRequestErrorResponse: HttpClientError;
    logoutResponse: LoginOut;
    logoutResponseError: HttpClientError;
    forgotresponse: forgotResponse;
    loginErrorResponse: HttpClientError;
    forgotPasswordErrorResponse: HttpClientError;
    playerLoggedIn: PlayerLoggedIn;
    ResetPassword: ResetPassword;
    ResetPasswordError: HttpClientError;
    messageforsuccess: boolean;  
    allGamesLunch: AllGameLunch;
    allGamesLunchError: HttpClientError;
}
const initialState: LoginState = {
    OtpRequestResponse: null,
    OtpRequestErrorResponse: null,
    loginResponse: null,
    logoutResponse: null,
    logoutResponseError: null,
    countries: null,
    forgotresponse: null,
    loginErrorResponse: null,
    forgotPasswordErrorResponse: null,
    playerLoggedIn: null,
    ResetPassword: null,
    ResetPasswordError: null,
    messageforsuccess: null, 
    allGamesLunch: null,
    allGamesLunchError: null
}

export function loginReducer(state: LoginState = initialState, action: loginActions.LoginActions) {
switch (action.type) {

        case loginActions.RESET_STATE:
            return {
                ...state,
                loginResponse: null,
                countries: null,
                loginErrorResponse: null,
                forgotresponse: null,
                forgotPasswordErrorResponse: null,
                ResetPassword: null,
                ResetPasswordError: null,
                OtpRequestResponse: null,
                OtpRequestErrorResponse: null,
                messageforsuccess: null,
                logoutResponse: null,
                logoutResponseError: null, 
                allGamesLunch: null,
                allGamesLunchError: null
            }
            break;
        case loginActions.REGISTER_STATE:
            return {
                ...state,
                messageforsuccess: true
            }
            break;
        case loginActions.PLAYER_LOGGEDIN:
            return {
                ...state,
                playerLoggedIn: { ...state.playerLoggedIn, ...action.payload }
            }
            break;
        case loginActions.LOGIN_START:
        case loginActions.REGISTER_START:
            return {
                ...state,
                loginResponse: null,
                loginErrorResponse: null,
                forgotPasswordResponse: null
            }
            break;
        case loginActions.LOGIN_SUCCESS:
        case loginActions.REGISTER_SUCCESS:
            return {
                ...state,
                loginResponse: { ...state.loginResponse, ...action.payload },
                loginErrorResponse: null
            }
            break;
        case loginActions.LOGIN_FAIL:
        case loginActions.REGISTER_FAIL:
            return {
                ...state,
                loginResponse: null,
                loginErrorResponse: { ...state.loginErrorResponse, ...action.payload },
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY:
            return {
                ...state,
                countries: null
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY_SUCCESS:
            return {
                ...state,
                countries: { ...state.countries, ...action.payload }
            }
            break;
        case loginActions.REGISTER_GET_COUNTRY_FAIL:
            return {
                ...state,
                countries: null
            }
            break;
        case loginActions.REGISTER_GENERATE_OTP:
            return {
                ...state,
                OtpRequestResponse: null,
                OtpRequestErrorResponse: null
            }
            break;
        case loginActions.REGISTER_GENERATE_OTP_SUCCESS:
            return {
                ...state,
                OtpRequestResponse: { ...state.OtpRequestResponse, ...action.payload },
                OtpRequestErrorResponse: null
            }
            break;
        case loginActions.REGISTER_GENERATE_OTP_FAIL:
            return {
                ...state,
                OtpRequestResponse: null,
                OtpRequestErrorResponse: { ...state.OtpRequestErrorResponse, ...action.payload },
            }
            break;
        case loginActions.FORGOTPASSWORD_START:
            return {
                ...state,
                forgotresponse: null,
                forgotPasswordErrorResponse: null
            }
            break;
        case loginActions.FORGOTPASSWORD_SUCCESS:
            return {
                ...state,
                forgotresponse: { ...state.forgotresponse, ...action.payload },
                forgotPasswordErrorResponse: null
            }
            break;
        case loginActions.FORGOTPASSWORD_FAIL:
            return {
                ...state,
                forgotresponse: null,
                forgotPasswordErrorResponse: { ...state.forgotPasswordErrorResponse, ...action.payload },
            }
            break;
        case loginActions.RESETPASSWORD_START:
            return {
                ...state,
                ResetPassword: null,
                ResetPasswordError: null
            }
            break;
        case loginActions.RESETPASSWORD_SUCCESS:
            return {
                ...state,
                ResetPassword: { ...state.ResetPassword, ...action.payload },
                ResetPasswordError: null
            }
            break;
        case loginActions.RESETPASSWORD_FAIL:
            return {
                ...state,
                ResetPassword: null,
                ResetPasswordError: { ...state.ResetPasswordError, ...action.payload },
            }
            break;
        case loginActions.LOGOUT_START:
            return {
                ...state,
                logoutResponse: null,
                logoutResponseError:null
            }
            break;
        case loginActions.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutResponse: { ...state.logoutResponse, ...action.payload },
                logoutResponseError:null
            }
            break;
        case loginActions.LOGOUT_FAIL:
            return {
                ...state,
                logoutResponse: null,
                logoutResponseError:{ ...state.logoutResponseError, ...action.payload }
            }
            break; 
            case loginActions.ALL_GAMES_LUNCH_START:
                return {
                    ...state,
                    allGamesLunch: null,
                    allGamesLunchError: null
                }
                break;
            case loginActions.ALL_GAMES_LUNCH_SUCCESS:
                return {
                    ...state,
                    allGamesLunch: { ...state.allGamesLunch, ...action.payload },
                    allGamesLunchError: null
                }
                break;
            case loginActions.ALL_GAMES_LUNCH_FAIL:
                return {
                    ...state,
                    allGamesLunch: null,
                    allGamesLunchError: { ...state.allGamesLunchError, ...action.payload }
                }
                break;
        default:
            return state;
            break;
    }
}