import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
declare var $: any;

@Component({
  selector: 'app-mrslotty',
  templateUrl: './mrslotty.component.html',
  styleUrls: ['./mrslotty.component.css']
})
export class MrslottyComponent implements OnInit {
  allSlotsList: any;
  allSlotsGames: any;
  slotsdata: any;
  urlSafe: SafeResourceUrl;
  showgamefrom: number = 0;
  loginSub: Subscription;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  playerLoggedIn: boolean = false;
  gamesArray:any = []
  cover: boolean = false;
  constructor(private slotsservice: SlotsService, private store: Store<appState.AppState>, public sanitizer: DomSanitizer,) {
    this.slotsservice.getMrSlotty().subscribe(topSlots => { this.allSlotsGamesList(topSlots) });
  }

  allSlotsGamesList(slotty) {
    console.log(slotty.response)
    if (slotty.status == 200) {
      this.allSlotsGames = slotty.response;
    }
    for(let a=0; a<3; a++){
      this.gamesArray.push( this.allSlotsGames[a] )
    }
      this.gamesArray.push( this.allSlotsGames[6], this.allSlotsGames[3], this.allSlotsGames[4], this.allSlotsGames[5] )
    
    console.log( this.gamesArray )
  }

  ngOnInit() {
    $(document).ready(function () {
      $("#draggables").draggable({
        snap: true
      });
    })


    $(window).scroll(function () {
      $('#draggables').css('position', 'fixed');
    });

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }  
      });

  }

  slotsreal(id, pro) {
    const body = {
      "gameId": id,
      "gameMode": "real_play",
      "provider": pro
    }  
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.cover = true
      this.slotsservice.getSlottyUrl(body).subscribe(data => { this.slotslunch(data) });
    } else {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }


  }
  slotslunch(data) { 
    console.log( data )
    window.scroll(0, 0);
    if (data) {
      this.slotsdata = data; 
      // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.slotsdata.gameUrl);
      console.log( this.slotsdata.gameUrl )
      setTimeout(() => {
        this.cover = false;
      }, 1000);
      return window.open(this.slotsdata.gameUrl, "_self");
      // this.showgamefrom = 5; 
      // setTimeout(() => {
      //   $('.casinoDrag').show();
      // }, 5000); 
    }
  }

  closeMe() {
    this.urlSafe = "";
    this.showgamefrom = 0;
  }

  clickMe() {
    this.showgamefrom = 0;
    $('.casinoDrag').hide();
    this.urlSafe = "";
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  
}
