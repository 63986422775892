import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-refferal',
  templateUrl: './refferal.component.html',
  styleUrls: ['./refferal.component.css']
})
export class RefferalComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    const queryString = window.location.href;
    console.log(queryString);

    let paramString = queryString.split('?')[1];
     console.log(paramString)
   
     if(paramString != null || paramString != undefined){
      let refferalname = paramString.split("ID=")[1]
      console.log(refferalname)
      localStorage.setItem("Referrer",refferalname)
    }else{
      this.router.navigate(["/home"])
      localStorage.removeItem("Referrer")
    }
  }

}
