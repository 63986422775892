import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PokerGetGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';

@Component({
  selector: 'app-poker-history',
  templateUrl: './poker-history.component.html',
  styleUrls: ['./poker-history.component.css']
})
export class PokerHistoryComponent implements OnInit {
  private profilestoreSub: Subscription;
  private loginSub: Subscription;
  form: FormGroup;
  gamehistory: any;
  objectKeys = Object.keys;
  gameHistoryErrorMessage: string = "";
  profile: string;
  startDate: Date;
  todaydate: any;
  endDate: Date;
  historyloader: boolean = false;
  p: number = 1;
  selectnum: number = 10;
  description: boolean = false;
  playerLoggedIn: boolean = false;
  tableOneShow: boolean = false;
  tableTwoShow: boolean = false;
  tableThreeShow: boolean = false;
  gameRoundInfo:any;
  setGameRoundInfoData:{};
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate: any;
  constructor(private store: Store<appState.AppState>) {
  }
  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.profile = sessionStorage.getItem('profile');
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
    this.startDate = today;
    this.form = new FormGroup({
      "currency": new FormControl("CHP", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "types": new FormControl(''),
      "limit": new FormControl(100, Validators.required),
      "index": new FormControl(0, Validators.required),
    });
    this.form.valueChanges.subscribe(x => {
      let yearsDiff = 0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if (new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {

        this.steddate = true;
      } else {
        this.steddate = false;
      }
    });
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.PokerHistory) {
          this.historyloader = false;
          if (playerState.PokerHistory.success == true) {
            if (playerState.PokerHistory.values.length != 0) {
              this.description = false; 
              this.gamehistory = playerState.PokerHistory.values;
              this.tableOneShow = true;
            } else {
              this.description = true;
              this.gamehistory = "";
            }
          } else if (playerState.PokerHistory.success == false) {
            if (playerState.PokerHistory.description == 'NO_DATA_FOUND') {
              this.gameHistoryErrorMessage = "There is no History  for the selected Period";
              return;
            }
            this.gameHistoryErrorMessage = playerState.PokerHistory.description;
          }
        }
        if (playerState.PokerHistoryError) {
          this.historyloader = false;
          this.gameHistoryErrorMessage = playerState.PokerHistoryError.message;
        }
      }
    );
  }
  onFormSubmit() {

    this.form.value.startDate = moment(this.form.value.startDate).format('DD-MM-YYYY');
    this.form.value.endDate = moment(this.form.value.endDate).format('DD-MM-YYYY');
    this.form.value.types = ["POKER_HOLDEMNOLIMIT_CHP,HOLDEM,NOLIMIT", "POKER_HOLDEMLIMIT_CHP,HOLDEM,LIMIT", "POKER_OMAHANOLIMIT_CHP,OMAHA,NOLIMIT", "POKER_OMAHALIMIT_CHP,OMAHA,LIMIT", "POKER_OMAHAPOTLIMIT_CHP,OMAHA,LIMIT", "POKER_OMAHAPOTLIMIT_CHP,OMAHA,POTLIMIT","POKER_OMAHAFIVECARDSNOLIMIT_CHP,OMAHA,NOLIMIT", "POKER_OMAHAFIVECARDSPOTLIMIT_CHP,OMAHA,POTLIMIT"];
    this.historyloader = true;
    this.gamehistory = null;
    this.description = false;
    this.gameHistoryErrorMessage = null;
    this.p = 1;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new PokerGetGameHistory(this.form.value));
  }

  ngOnDestroy() {
    if (this.profilestoreSub) {
      this.profilestoreSub.unsubscribe();
    }

  }
  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }

  tableTwoView(gameRoundInfo) {
    console.log(gameRoundInfo);
    this.tableOneShow = false;
    this.tableTwoShow = true;
    this.gameRoundInfo=gameRoundInfo;
    
    console.log(this.gameRoundInfo[0].gameRoundDataInfos);
  }

  tableOneView(){
    
    this.tableOneShow = true;
    this.tableTwoShow = false;
  }
  roundInfoData(gameRoundInfoData){
    console.log(gameRoundInfoData);
    try{
      let getData = JSON.parse(gameRoundInfoData[0].gameDa.replace(/\\/g, ""));
      console.log(typeof getData);
      this.setGameRoundInfoData = getData.data;
      console.log(typeof this.setGameRoundInfoData);
      this.tableThreeShow = true;
      console.log(this.setGameRoundInfoData['data']['roundInfo']['ante']['_value']);
    }catch(e){
      console.log(e);
    }

  }

  closeWin(){
    this.setGameRoundInfoData=null;
    this.tableThreeShow = false;
  }
}

