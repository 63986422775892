import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Game } from 'src/app/source/modules/player/tournamentsList';
import { Router } from '@angular/router';
import { SlotsService } from 'src/app/source/services/slots.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Component({
  selector: 'app-indie-casino-games',
  templateUrl: './indie-casino-games.component.html',
  styleUrls: ['./indie-casino-games.component.css']
})
export class IndieCasinoGamesComponent implements OnInit {
  private storeSub: Subscription;
  allSlotsGames: Array<Game>;
  urlSafe: SafeResourceUrl;
  playerLoggedIn: boolean = false;
  playerName: string;
  indieUrl: any = null;
  indieUrl_1: any;
  indieUrl_2: any;
  showgamefrom: number = 0;
  indieUrl_3: any;
  gameIdIndie: any;
  closeIndieGame: any;
  casinogamessuccessPop: boolean;
  casinomessage: string;
  token: any;
  otherGames: string;
  indieCasinoGames: any;
  eventname: any;


  constructor(private router: Router, private cookieService: CookieService, public sanitizer: DomSanitizer, private store: Store<appState.AppState>, private slotSer: SlotsService) { }

  ngOnInit() {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn) {
            var webSess = this.cookieService.get("wsession")
            this.slotSer.indiecasino(webSess).subscribe(data => { this.tokenData(data) })
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          } else {
            this.playerLoggedIn = false;
          }
        }
      })
    this.slotSer.topGamesJson().subscribe(indieList => { this.indieGames(indieList) })
    this.playerName = sessionStorage.getItem('profile')
    
    console.log(this.playerName)
   
    
    this.closegame('topslots')
  }
  tokenData(data) {
    console.log(data)
    this.token = data;
  }
  closegame(num) {
    console.log(num)
    this.eventname = num;
  }
  indiecasino
  indieGames(data) {
    console.log(data)
    this.indieCasinoGames = data.Games;
    console.log(this.indieCasinoGames)
  }
  subindi(data) {
    console.log(data)
  }

  // enterGame(id: any, gameName: any, el: HTMLElement) {
  enterGame(id: any, gameName: any) {
    // var webSess = this.cookieService.get("wsession")
    // this.slotSer.indiecasino(webSess).subscribe(data => { this.tokenData(data) })
    console.log(id)
    console.log(gameName)
    console.log(this.token)
    this.urlSafe = null;
   
    if (this.playerLoggedIn == true) {
      this.gameIdIndie = id;
      let sendToIndie = "gameId=" + id + '\&playerToken=' + this.token["token"] + '\&site=' + 'rp1';
      console.log(sendToIndie)
      let indieUrl = this.token["url"] + "/"
      console.log(indieUrl)
      switch (id) {
        case 1:
          this.indieUrl_1 = indieUrl + 'miniAndarBahar?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          // el.scrollIntoView({ behavior: "smooth" });
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 2:
          this.indieUrl_1 = indieUrl + 'targetTimer?' + sendToIndie;
          this.otherGames = 'all';
          console.log(this.indieUrl_1);
          // el.scrollIntoView({ behavior: "smooth" });
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 3:
          this.indieUrl_1 = indieUrl + 'americanRouletteTimer?' + sendToIndie;
          this.otherGames = 'all';
          console.log(this.indieUrl_1);
          // el.scrollIntoView({ behavior: "smooth" });
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 5:
          this.indieUrl_1 = indieUrl + 'targetNonTimer?' + sendToIndie;
          this.otherGames = 'all';
          console.log(this.indieUrl_1);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 6:
          this.indieUrl_1 = indieUrl + 'americanRoulettenon?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 7:
          this.indieUrl_1 = indieUrl + 'matkatimer?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 12:
          this.indieUrl_1 = indieUrl + 'europeanRouletteTimer?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 13:
          this.indieUrl_1 = indieUrl + 'europeanRoulette?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 14:
          this.indieUrl_1 = indieUrl + 'matkaNonTimer?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 19:
          this.indieUrl_1 = indieUrl + 'soratTimer?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 20:
          this.indieUrl_1 = indieUrl + 'dragonTiger?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 22:
          this.indieUrl_1 = indieUrl + 'tripleChance?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 23:
          this.indieUrl_1 = indieUrl + 'moneyWheel?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
        case 24:
          this.indieUrl_1 = indieUrl + 'andarBahar?' + sendToIndie;
          this.otherGames = 'all'
          console.log(this.indieUrl_1)
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indieUrl_1);
          break;
      }
      window.scrollTo({
        top: 60,
        behavior: 'smooth',
      })
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
      this.casinomessage = 'Please Login';
    }
  }

  casinogamessuccessPopClose() {
    this.casinogamessuccessPop = false;
    this.casinomessage = "";
  }
  closeMe() {
    this.urlSafe = "";
    this.indieUrl_1 = "";
  }

}
