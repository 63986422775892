import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { PlayerService } from '../player/player.service';
import { Store } from '@ngrx/store';
import * as appState from '../../appstates/appState';
import { PlayerLoggedIn, LogoutStart, registermessage } from '../../appstates/loginstates/loginActions';
import { PlayerGetProfile } from '../../appstates/playerstates/playerActions';
import { Observable } from 'rxjs';
import { CashierGetBalanceStart } from '../../appstates/cashierstates/cashierActions';
import { ResetPassword } from '../../modules/login/resetpassword';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { AllGameLunch } from '../../modules/login/playerLoggedIn';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private countries = 'assets/countries.json';
  constructor(private httpClient: HttpClient,
    private playerService: PlayerService,
    private store: Store<appState.AppState>, private router: Router, private cookieService: CookieService) { }


  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'siteid': environment.skinId
  //   })
  // };


  httpOptions() { 
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      'siteid': environment.skinId 
      })
    };
    console.log(options)
    return options;
  } 




  httpOptionsdata = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  };
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'siteid': environment.skinId
  });
  onGetPlayerBalance() {
    this.store.dispatch(new CashierGetBalanceStart());
  }
  sessinExpireToLogout() {
    this.store.dispatch(new LogoutStart());
  }
  sessionlogout(res) {
    if (res['code'] == 'SESSION_EXPIRED' || res['code'] == 'INVALID_SESSION_TOKEN') {

      this.onPlayerLoggedIn(false);
      sessionStorage.removeItem('accessToken');
      this.cookieService.deleteAll();
      sessionStorage.removeItem('profile');
      sessionStorage.removeItem('playermobilenumber');
      sessionStorage.clear();
      this.router.navigate(['/home']);
      // this.store.dispatch(new LogoutStart());
    }
  }
  onPlayerLoggedIn(value: boolean) { 
    this.store.dispatch(new PlayerLoggedIn({ loggedIn: value }));
  }

  updateExpiredPassword(postdata) {
    return this.httpClient.post<ResetPassword>(`${environment.appApi.baseUrl}${environment.appApi.updateExpiredPassword}`, postdata, this.httpOptions());
  }

  onLogin(postdata) { 
    localStorage.setItem("LogN",postdata.login)
    localStorage.setItem("LogP",postdata.password)
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.login}`, postdata, this.httpOptions());
  }
  verifyAccount(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.verifyAccount}`, postdata, this.httpOptions());
  }
  onGenerateCaptchaStart(): Observable<any> {
    // return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateCaptcha}`,
    //   {}, { headers: this.headers, responseType: 'text' });
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateCaptcha}`,
      {}, { headers: this.headers, responseType: 'text' });
  }
  onLogOut() {
    // this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new playerActions.ResetState());
    this.store.dispatch(new cashierActions.ResetState());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());


  }
  onRegister(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.register}`, postdata, this.httpOptions());
  }
  onGetRegisterCountry() {
    return this.httpClient.get(this.countries);
  }
  // onGetRegisterCountry() {
  //   return this.httpClient.post<Countries>(`${environment.appApi.baseUrl}${environment.appApi.getCountries}`, {}, this.httpOptions);
  // }
  onForgotPassword(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.fotgotPassword}`, postdata, this.httpOptions());
  }
  onResetPassword(postdata) {
    return this.httpClient.post<ResetPassword>(`${environment.appApi.baseUrl}${environment.appApi.resetPassword}`, postdata, this.httpOptions());
  }
  getMyprofile() {
    this.store.dispatch(new PlayerGetProfile());
  }
  registerMessage() {

    this.store.dispatch(new registermessage());
  }
  onRegisterGenarateOTPStart(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateOTP}`, postdata, this.httpOptions());
  }
  AllGamesLunch(postdata) {
    // debugger;
    if (postdata.gameMode == 'real_play') {
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.allGamesUrl}`, postdata, this.playerService.httpOptions());
    } else if (postdata.gameMode == 'demo_play') {
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.mrFunGamesUrl}`, postdata, this.httpOptions());
    }
  }
}
