import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as appState from 'src/app/source/appstates/appState';
import { ActivatedRoute, Router } from '@angular/router';
import { SlotsService } from 'src/app/source/services/slots.service';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Subscription } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-casinogames',
  templateUrl: './casinogames.component.html',
  styleUrls: ['./casinogames.component.css']
})
export class CasinogamesComponent implements OnInit {
  gamesList: any = [];
  selectedGame: any;
  prefCurrency: string = 'CHP';
  loginSub: Subscription;
  htmlGames: any=["BLACKJACK", "EUROPEANROULETTE", "BACCARAT", "JACKSORBETTER"];
  playerLoggedIn: boolean = false;
  errMsg: string="";
  isError: boolean=false;
  constructor(private cookieService: CookieService, private route: ActivatedRoute, private router: Router,
    private slotsservice: SlotsService,
    private store: Store<appState.AppState>) {
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    //ADD FOR AUTHENTICATION 

  }
  ngOnInit(): void {
    this.resetError();
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn; 
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    this.getCasinGamesList();
  }

  getCasinGamesList() {
    this.slotsservice.getCasinoGamesList().subscribe(data => {
      this.casinoGamesListHandler(data);
    });
  }
  casinoGamesListHandler(gamesData) {
    if (gamesData && gamesData.success) {
      let fullGamesList = gamesData["casinoGameResponses"];
      // if (appConf.turnOnFilterForHtmlGames)
      fullGamesList = this.filterOnlyHtmlGames(fullGamesList);
      console.table(fullGamesList);
      this.gamesList = fullGamesList;
    }
    else {
      // popup 
      this.setError(gamesData["code"]);
    }
  }
  filterOnlyHtmlGames(gamesList) {
    let htmlGamesList = [];
    gamesList.forEach(obj => {
      if (this.htmlGames.includes(obj.metaPattern) && obj["login"] != "Guest")
        htmlGamesList.push(obj)
    });
    return htmlGamesList;
  }


  launchHtmlGame(index) {
    this.selectedGame = this.gamesList[index];
    let playerContextApiQueryTag = this.preparPlayerContextApiQueryTag();
    this.slotsservice.launchCasinoGameUrl(playerContextApiQueryTag).subscribe(data => {
      this.launchGameHandler(data);
    });

  }

  preparPlayerContextApiQueryTag() {
    var name = "name=CASINO_" + this.selectedGame["metaPattern"] + "_" + this.prefCurrency;
    var real = "real=1";
    var walletCode = "walletCCode=" + this.prefCurrency;
    return "?" + name + "&" + real + "&" + walletCode;
  }


  launchGameHandler(apiRes) {
    if (apiRes && apiRes.success) {
      let isHtmlGame = this.htmlGames.includes(apiRes["keyVsValue"]["gameSettingsName"])
      if (isHtmlGame)
        this.openGameUrlForHtmlGames(apiRes["keyVsValue"]["playerContextId"])
    }
    else {
      //popup 
    }

  }
  openGameUrlForHtmlGames(contextId) {
    const sessionIdArr = this.cookieService.get('wsession');
    if (sessionIdArr) {
      const launchurl = environment.appApi.casino.rp1Url + "/htmlgames/blackjack/index.html";
      const gameName = "gameName=" + this.selectedGame["metaPattern"];
      const lang = "language=EN";
      const wallet = "wallet=" + this.prefCurrency;
      const gameSession = "gameSession=" + contextId;
      const sid = "sessionId=" + sessionIdArr[sessionIdArr.length - 1];
      const launchUrlFinal = this.appendGetParamsToUrl(launchurl, [
        gameName, lang, gameSession, wallet, sid
      ]);
      window.open(launchUrlFinal, "", "width=1000,height=1000");
    }
  }
  appendGetParamsToUrl(url, params) {
    url += "?";
    let paramslen = params.length;
    for (var i = 0; i < paramslen - 1; i++)
      url += params[i] + "&";
    url += params[paramslen - 1];
    return url;

  }

  resetError() {
    this.isError = false;
    this.errMsg = "";
  }
  setError(errMsg) {
    this.errMsg = errMsg;
    this.isError = true;
  }

}
