import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as resultActions from './betresultAction';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { BetresultService } from '../../services/sbresult/betresult.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Results } from '../../modules/betresults/results';
import { Router } from '@angular/router';
import { isBoolean } from 'util';
import { LoginService } from '../../services/login/login.service';

@Injectable()
export class resultEffects{
    constructor(private actions$:Actions,
        private BetresultService:BetresultService,
        private router:Router,private loginservice: LoginService){}
    

    @Effect({dispatch:false})
    sportBookGetResultsSuccess = this.actions$.pipe(
        ofType(resultActions.SPORTBOOK_GET_RESULT_SUCCESS),
        tap((getResults:resultActions.sportBookGetResultsSuccess)=>{
            if(getResults.payload){
                
            }
        })
    )
}