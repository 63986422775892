
import { environment } from '@app/env';
console.log(environment.appApi.baseUrl)
if(environment.appApi.baseUrl == "https://riv3.czargaming.com"){
    var URL = "https://riv3.czargaming.com/download/setup.exe"
}else{
    var URL = "https://www.rghvir45.com/download/setup.exe"
}
export const appConstants = {
    "appDownloadLinks": {
        "android": environment.appApi.baseUrl+"/download/mobile/pokermobile.apk",
        // "androidNew": environment.appApi.baseUrl+"/download/mobile/pokermobile_new.apk",
        "ios": "itms-services://www.rivappsdo.com?action=download-manifest&url=https://www.rivappsdo.com/download/mobile/ios/manifest.plist",
        // "desktop": "https://www.riverpn37.com/client/download.html",
        // "desktop": "https://rghvir43.com/client/download.html",
        "desktop": "https://www.rghvir45.com/download/setup.exe",
        // "desktop": URL,
        // "desktop": "https://riv3.czargaming.com/download/setup.exe",
        "agent": environment.appApi.baseUrl+"/agent",
        "affiliate": environment.appApi.baseUrl+"/affiliate/login",
        "instaPlay": environment.appApi.baseUrl+"/pokerh5/",
        // "instaPlay": environment.appApi.baseUrl+"/pokerh5v4/",
        "instaPlayNew": environment.appApi.baseUrl+"/h5new/"
    },
    "appCertificatesLinks": {
        "mainPageCertificate": environment.appApi.baseUrl+"/RNG_Certificate_CzarGaming_RiverPriver_UK_19Nov2018.pdf"
    },
    "CASINO_GAMEPLAY_REAL_PLAY_KEY":"real_play",
    "CASINO_GAMEPLAY_DEMO_PLAY_KEY":"demo_play",
    "CASINO_DEMO_GAME_LAUNCH_CONFIRM_MSG":"This is a demo game , click ok to launch",
    "CONTACT_MAIL":"admin@riverpoker.info",
    "SOCIAL_LINKS": {
        "TELEGRAM": { "link": "http://telegram.me/River_support" },
        "INSTAGRAM": { "link": "https://www.instagram.com/river_poker/?igshid=1frajsrybifan" }
    },
    
    "slotsiamgepath": "/assets/habimg/habanero",
    "LOGIN_COMPONENT":"loginComp",
    "REGISTER_COMPONENT":"register",
    "defaultModalHeight":"500px",
    "defaultModalWidth":"500px",
    'language':(typeof window !== "undefined")? localStorage.getItem('locale'):'' ,
    "CASHOUT_SUCCESS_MSG_EN":"Your cashout request is under processing, It will reflect in your account in 2 to 3 working days",
    "CASHOUT_SUCCESS_MSG_FA":"درخواست برداشت شما با موفقیت ثبت شد"
}
