import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { LoginService } from 'src/app/source/services/login/login.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import * as appState from 'src/app/source/appstates/appState';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Subscription } from 'rxjs';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { MeightService } from 'src/app/source/services/meightservice/meight.service';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.css']
})
export class TwoFactorComponent implements OnInit {
  otp = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: ''
  };
  goToemailPop: boolean = false;
  emailMessage: any;

  twoFactorpopup: boolean = false;
  loginPlayerName: any;
  loginPlayerPass: any;
  form: FormGroup;
  profileName: string;
  private loginstoreSub: Subscription;
  private tokenstoreSub: Subscription;
  private profilestoreSub: Subscription;
  private storeSub: Subscription;
  reloginPop: boolean;
  profileMail: any;
  twofactorPop: boolean;
  submitButtonEnabled: boolean = false;
  otpForm: FormGroup;
  profile: Profile;
  otpMsg: any;
  otperror: boolean;
  logPop: boolean;
  readResponse: any;
  playerLoggedIn:boolean = false;
  constructor(private loginSer: LoginService, private route: ActivatedRoute, public router: Router,
    private Playerservice: PlayerService,
    private meightservice: MeightService,
    private store: Store<appState.AppState>) { }

  ngOnInit() {
    let EmailId = localStorage.getItem("EmailId")
    this.readResponse = sessionStorage.getItem('webSessionId');
    console.log(this.readResponse)
if(this.readResponse){

}else{
  this.router.navigate(['/home'])
}
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) { 
            
          } else if (loginState.loginResponse.success === false) {  
          }
        } 
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          console.log(this.playerLoggedIn)
          if (this.playerLoggedIn == true) { 
          }
        }
      }
    )

    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        console.log(loginState)
        console.log(loginState.playerLoggedIn.loggedIn)
        if (loginState && loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn == true) {
            if (loginState.loginResponse) {
              if (loginState.loginResponse['showTwoFactorPopup'] === true || loginState.loginResponse['showTwoFactorPopup'] === false) {
                if (loginState.loginResponse['twoFactor'] === true) {
                  this.profileMail = loginState.loginResponse['email'];
                  this.reloginPop = true;
                } else if (loginState.loginResponse['twoFactor'] === false) {
                  this.profileMail = loginState.loginResponse['email'];
                  this.twofactorPop = true;
                } else {
                  console.log("session missing");
                }
              }
            } else {
              if (EmailId) {
                this.profileMail = EmailId
              }
             
              console.log('loginResponse is null or undefined');
            }
          } else {
            console.log("Not logged in");
          }
        } else {
          console.log("playerLoggedIn is null or undefined");
        }
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn == true) {  

          }else{
            this.router.navigate(['/twofactor'])
          }
        }
      }
    );
    this.readResponse = sessionStorage.getItem('webSessionId');
    if (this.readResponse) {
      try { 
        const responseObj = JSON.parse(this.readResponse);
        if (responseObj['twoFactor'] === true) {
          this.profileMail = responseObj['email'];
          this.reloginPop = true;
        } else if (responseObj['twoFactor'] === false) {
          this.profileMail = responseObj['email'];
          this.twofactorPop = true;
        }
      } catch (e) {
        console.error('Error parsing JSON from sessionStorage', e);
      }
    } else {
      console.error('No session data found in sessionStorage');
    }
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        console.log(playerState)
        if (playerState.profile) {
          const responseObj = JSON.parse(this.readResponse); 
          console.log(1)
          if(playerState.profile.description == "Two Factor Enabled" ){
            console.log(responseObj)  
            console.log( responseObj['twoFAFeature'] === "false")  
            if(responseObj['twoFactor'] === "true"){ 
              if(responseObj['email'] == ''){
                this.profileMail = EmailId
              }else{
           this.profileMail = responseObj['email'];
                } 
              this.reloginPop = true;
            } 
          } else if ( responseObj['twoFactor'] === "false") {
            this.twofactorPop = true;
            this.profileMail = EmailId
          
          }else{
            if(EmailId){
      var path = sessionStorage.getItem('redirect') 
   if (path) {
     this.router.navigate([`${path}`]);
   } else {
     this.router.navigate(['/home']);
   }
            }
          }
          
//           else{
  
// console.log(   responseObj['twoFAFeature'] === "false" ||  responseObj['twoFAFeature'] === false)
// if(responseObj['twoFAFeature']){
  
//               var path = sessionStorage.getItem('redirect')
//               console.log(path)
//                if (path) {
//                  this.router.navigate([`${path}`]);
//                } else {
//                  this.router.navigate(['/home']);
//                }

// }

               
//           }
          }
      }
    ) 
    this.otpForm = new FormGroup({
      otp1: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      otp2: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      otp3: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      otp4: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      otp5: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      otp6: new FormControl('', [Validators.required, Validators.pattern('[0-9]')])
    });
    this.otpForm.valueChanges.subscribe(() => {
      this.checkIfAllFieldsFilled();
    });
  }
  twofactorPopClose() { 
    this.twofactorPop = false;
    var path = sessionStorage.getItem('redirect')
    if (path) {
      this.router.navigate([`${path}`]);
    } else {
      this.router.navigate(['/home']);
    }
    setTimeout(() => {
      sessionStorage.removeItem('redirect')
      localStorage.removeItem('twofactor')
      localStorage.removeItem('ShowtoFactorPopup')
      localStorage.removeItem('EmailId') 

      window.location.reload()
    }, 1000)

    const body = {
    twoFactorToken: "0000",
      twoFactorPopup: "later"
    }
    this.Playerservice.acceptTwoFactorApi(body).subscribe((data) => {

    })
  }
  twofactor() {
    const body = {

    }
    this.Playerservice.acceptTwoFactorApi(body).subscribe((data) => {
      console.log(data)
      this.twofactorPop = false;
      this.goToemailPop = true;
      this.emailMessage = data.description
    })
  }
  goToemailPopClose() {
   this.goToemailPop = false
   
   localStorage.clear()
    sessionStorage.clear() 
      sessionStorage.removeItem('redirect')
      localStorage.removeItem('twofactor')
      localStorage.removeItem('ShowtoFactorPopup')
      localStorage.removeItem('EmailId') 
    this.meightservice.TwowayonLogOut()
    this.router.navigate(['/home'])
    this.store.dispatch(new loginActions.ResetState()); 
    this.store.dispatch(new cashierActions.ResetState()); 

    this.store.dispatch(new loginActions.LogoutStart()); 
    setTimeout(() => {  
      window.location.reload()
    }, 1000)
  }
  reloginPopClose() {
    this.reloginPop = false;
  }
  checkIfAllFieldsFilled() {
    this.submitButtonEnabled = Object.values(this.otpForm.value).every(value => typeof value === 'string' && value.length === 1);
  }
  onOtpChange(event: KeyboardEvent, nextElement: string | null, prevElement: string | null) {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 1 && nextElement) {
      const nextInput = document.getElementsByName(nextElement)[0] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'Backspace' && prevElement) {
      const prevInput = document.getElementsByName(prevElement)[0] as HTMLInputElement;
      prevInput.focus();
    }
  }
  submitOtp() {
    if (this.otpForm.valid) {
      var otp = Object.values(this.otpForm.value).join('');
      console.log('OTP entered:', otp);
    } else {
    }
    const body = {
      "twoFactorToken": otp,
      "twoFactorPopup": "now"
    }
    this.Playerservice.twoFactorApi(body).subscribe((data) => {

      console.log(data);
      if (data.success === true) {
        this.twofactorPop = false;
        var path = sessionStorage.getItem('redirect')
        if (path) {
          this.router.navigate([`${path}`]);
        } else {
          this.router.navigate(['/home']);
        }
        setTimeout(() => {
          sessionStorage.removeItem('redirect')
          localStorage.removeItem('twofactor')
          localStorage.removeItem('ShowtoFactorPopup')
          localStorage.removeItem('EmailId')
          window.location.reload();
        }, 1000)
      } else {
        this.otpMsg = data.description;
        setTimeout(() => {
          this.otpMsg = "";
        }, 4000)
        this.otpForm.reset();
      }
    })
  }
  otperrorPopclose() {
    this.otperror = false;
 
  } 

  phonenum(event) {
    var k;
    k = event.charCode;
    return (k >= 48 && k <= 57);
  }
}