import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { GoldenraceService } from 'src/app/source/services/goldenraceservice/goldenrace.service';
import { GoldenRaceRes, GoldenRace, GoldenRaceGameLaunch } from 'src/app/source/modules/goldenraceinterface/goldenrace';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState'; 

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-goldenrace',
  templateUrl: './goldenrace.component.html',
  styleUrls: ['./goldenrace.component.css']
})
export class GoldenraceComponent implements OnInit {
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  GoldenRaceRes: GoldenRaceRes;
  GoldenRace: Array<GoldenRace>;
  gamelaunch: GoldenRaceGameLaunch;
  goldenracemessage: string = "";
  goldenracessuccessPop: boolean = false;
  srcurl: string;
  urlSafe: SafeResourceUrl;
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  constructor(public sanitizer: DomSanitizer, private goldenraceservice: GoldenraceService, private store: Store<appState.AppState>,) {




  }
  ngOnInit() {

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if(this.playerLoggedIn){
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            this.hitgamelunch(10110);
          }else {
          this.hitgamelunch(10110);
        }
      }
      });


  }
  goldenraceimages(data) {
    this.GoldenRaceRes = data;
    if (this.GoldenRaceRes) {
      this.GoldenRace = this.GoldenRaceRes.GoldenRace;
    }
  }

  hitgamelunch(id) {
    let gameid = id;
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/ipad/i) !== null) {
      let devicename = 'mobile';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/iphone/i) !== null) {
      let devicename = 'mobile';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/android/i) !== null) {
      let devicename = 'mobile';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/chrome/gi) !== null) {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/firefox/gi) !== null) {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/webkit/gi) !== null) {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/gecko/gi) !== null) {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    } else if (ua.match(/opera/gi) !== null) {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    } else {
      let devicename = 'desktop';
      this.hitservice(gameid, devicename);
    }

  }
  hitservice(id, devicename) {
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      let profile = sessionStorage.getItem('profile');
      this.goldenraceservice.httpClient(profile, id, devicename).subscribe((data) => { this.gamelunchurl(data) }, (err) => { this.gamelauncherror(err) });
    } else if (this.playerLoggedIn == false) {
      this.goldenraceservice.httpClientfun(id, devicename).subscribe((data) => { this.gamelunchurl(data) }, (err) => { this.gamelauncherror(err) });
    }

  }
  gamelunchurl(data) {
    this.gamelaunch = data;
    if (this.gamelaunch.Message == "Success") {
      this.goldenracemessage = "";
      this.srcurl = this.gamelaunch.launch;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.srcurl);
      //window.open(this.gamelaunch.launch, '_blank');
      //window.location.replace(this.gamelaunch.launch);
    } else {
      this.urlSafe = "";
      this.goldenracemessage = this.gamelaunch.Message;
    }
  }
  gamelauncherror(error) {
  }
  goldenracessuccessPopClose() {
    this.goldenracemessage = "";
    this.goldenracessuccessPop = false;
  }
}
