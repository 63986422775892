import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetRemoteGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { RemoteGamesHistory } from 'src/app/source/modules/remotegamehistory/remotegamehistory';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-remote-history',
  templateUrl: './remote-history.component.html',
  styleUrls: ['./remote-history.component.css']
})
export class RemoteHistoryComponent implements OnInit {
  private profileRemotestoreSub: Subscription;
  private loginSub: Subscription;
  remoteform: FormGroup;
  profile: string;
  startDate: Date;
  todaydate: any;
  endDate: Date;
  all = "all";
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  playerLoggedIn:boolean=false;
  // selecteddata: string="Colossal Gems";
  RemoteGamesHistory: RemoteGamesHistory;
  remotehistory: any;
  p: number = 1;
  selectnum: number = 10;
  historyloader:boolean=false;
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  undefiend;
  steddate:any;
  habinaro: RootObject;
  habinarosponse: Array<Game>;
  totalrecords: number;
  remoteGameHistoryErrorMessage: string;
  constructor(private store: Store<appState.AppState>, private slotsservice: SlotsService) {
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    console.log("---------------------------->"+ "allSlots" + "<---------------");
    
    this.slotsservice.AllSlotsJson().subscribe(allSlots => { this.allSlotsGamesList(allSlots) });
 
  }

  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.profile = sessionStorage.getItem('profile');
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
    this.startDate = today;
    this.remoteform = new FormGroup({
      "startDate": new FormControl(this.startDate, Validators.required),
      "endDate": new FormControl(this.endDate, Validators.required),
      // "name": new FormControl(null, ),
      "currency": new FormControl("CHP"),
      "limit": new FormControl(100),
      "index": new FormControl(0),
    });
    this.remoteform.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   });
    // this.onremoteFormSubmit();
    this.profileRemotestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.remotegameshistory) {
          this.historyloader=false;
          if (playerState.remotegameshistory.success == true) {
            if (playerState.remotegameshistory.total == 0) {
              this.totalrecords = playerState.remotegameshistory.total;
              this.remoteGameHistoryErrorMessage = "There is no History  for the selected Period";
            } else {
              this.totalrecords = playerState.remotegameshistory.total;
              this.remotehistory = playerState.remotegameshistory.values;
            }
          } else if (playerState.remotegameshistory.success == false) {
            if(playerState.remotegameshistory.description == 'NO_DATA_FOUND'){
              this.remoteGameHistoryErrorMessage = "There is no History  for the selected Period";
              return;
            }
            this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
          }
        }
        if (playerState.remotegameshistoryError) {
          this.historyloader=false;
          this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
        }
      }
    )

    // this.remoteform.value.name = this.selecteddata;
  }
  onDateChange(event: any) {
    setTimeout(() => {
      this.startDate = event;
    }, 0);
  }
  onDateChangeE(event: any) {
    setTimeout(() => {
      this.endDate = event;
    }, 0);
  }
  allSlotsGamesList(allSlots) { 
    console.info(allSlots);
    this.allSlotsList = allSlots;
    if (this.allSlotsList) {
      this.allSlotsGames = this.allSlotsList.Games;
      this.slotsservice.TopSlotsJson().subscribe(topSlots => { this.topSlotsGamesList(topSlots) });
    }
  }
  topSlotsGamesList(topSlots) {
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
      this.topSlotsGames.forEach(obj => {
        this.allSlotsGames.push(obj)
      });
      this.slotsservice.TableGamesJson().subscribe(tableSlots => { this.tableSlotsGamesList(tableSlots) });
     }
  }
  tableSlotsGamesList(tableSlots) {
    this.tableSlotsList = tableSlots;
    if (this.tableSlotsList) {
      this.tableSlotsGames = this.tableSlotsList.Games;
      this.tableSlotsGames.forEach(obj => {
        this.allSlotsGames.push(obj)
      });
      this.slotsservice.getJSON().subscribe(habinarogames => { this.habinarojson(habinarogames) });
    }
  }

  habinarojson(data) {
    this.habinaro = data;
    if (this.habinaro) {
      this.habinarosponse = this.habinaro.Games;
      this.habinarosponse.forEach(obj => {
        this.allSlotsGames.push(obj)
      });
    }
  }
  // selectGame(event) {
  //   this.selecteddata = event+"_habanero";
  // }
  onremoteFormSubmit() {
    this.historyloader=true;
    this.totalrecords = null;
    this.remotehistory = null;
    this.remoteGameHistoryErrorMessage = null;
    this.p = 1;
    // if(this.remoteform.value.name == 'live casino'){
    //   this.remoteform.value.name = null;
    // } else if(this.remoteform.value.name == 'all'){
    //   this.remoteform.value.name = 'all';
    // }else{
    //   this.remoteform.value.name = this.remoteform.value.name + "_habanero";
    // }
   
    this.remoteform.value.startDate = moment(this.remoteform.value.startDate).format('DD-MM-YYYY');
    this.remoteform.value.endDate = moment(this.remoteform.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new PlayerGetRemoteGameHistory(this.remoteform.value));
  }
  ngOnDestroy() {
    if (this.profileRemotestoreSub) {
      this.profileRemotestoreSub.unsubscribe();
    }
  }

  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }
}

