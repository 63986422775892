import {ActionReducerMap } from '@ngrx/store';
import * as loginState from './loginstates/loginState';
import * as playerState from './playerstates/playerState';
import * as cashierState from './cashierstates/cashierState';
import * as playerBetState  from './bethistory/betHistoryState';
import * as betResultState from './betresult/betresultState'; 

export interface AppState{
    loginState:loginState.LoginState;
    playerState:playerState.ProfileState;
    cashierState:cashierState.CashierState;  
}
export const AppReducer:ActionReducerMap<AppState> = {
    loginState:loginState.loginReducer,
    playerState:playerState.playerReducer,
    cashierState:cashierState.cashierReducer, 
}
