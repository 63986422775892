
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as loginActions from './loginActions';
import { forgotResponse } from '../../modules/forgotpassword';
import {catchError, map, switchMap, tap, exhaustMap} from "rxjs/internal/operators";
//import { Jsonxml } from '../../utils/jsonxml';
import { LoginService } from '../../services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Login } from '../../modules/login/login';
import { on } from '@ngrx/store';
import { Countries } from '../../modules/login/countries';

import { CookieService } from 'ngx-cookie-service';
//import { ToastrService } from 'ngx-toastr';
import { LoginOut } from '../../modules/login/logout';
import { ResetPassword } from '../../modules/login/resetpassword';
import { OtpRequest } from '../../modules/login/otprequest';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { AllGameLunch } from '../../modules/login/playerLoggedIn';
const handleError = (error: any, actions:any) => {
    if (error.error instanceof Error) {
        return of(new actions({"message":error.error.message}));
      } else {
        try{
            if(error.error.message){
                return of(new actions({"message":error.error.message}));
            }else if(error.message){
                return of(new actions({"message":error.message}));
            }
            return of(new actions({"message":"Something went wrong please contact admin"}));
        }catch(error){
        }
      }
      return of(new actions({"message":"Something went wrong please contact admin"}));
}
@Injectable()
export class LoginEffects{
    constructor(
        private actions$:Actions, 
        private loginservice:LoginService,
       // private toastr : ToastrService,
        private router: Router,
        private route: ActivatedRoute,  private cookieService: CookieService){}

    @Effect()
    loginStart = this.actions$.pipe(
        ofType(loginActions.LOGIN_START),
        exhaustMap((onLoginStart:loginActions.LoginStart) => 
        this.loginservice.onLogin(onLoginStart.payload)
        .pipe(
            map((response:Login) => {
                console.log( '--------------------------------------------')
                console.log(response)
                console.log( '--------------------------------------------')
                // this.loginservice.sessionlogout(response);
                return new loginActions.LoginSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error, loginActions.LoginFail);
                })
        )
        )
    )

    @Effect({dispatch:false})
    loginSuccess = this.actions$.pipe(
        ofType(loginActions.LOGIN_SUCCESS),
        tap((onLoginSuccess:loginActions.LoginSuccess)=>{
            if(onLoginSuccess.payload.success === true){
                this.cookieService.set('wsession', onLoginSuccess.payload.sessionId);
                console.log( onLoginSuccess) 
                console.log( onLoginSuccess.payload ) 
                sessionStorage.setItem('webSessionId', JSON.stringify(onLoginSuccess.payload));
                 this.loginservice.getMyprofile();
                 localStorage.removeItem("LogP")
                 localStorage.removeItem("LogN")
                //  window.location.reload();
                this.loginservice.onGetPlayerBalance();
                this.loginservice.onPlayerLoggedIn(true);
                //  this.router.navigate(['/home']);
                console.log(onLoginSuccess.payload)

                setTimeout(()=>{
                    if(onLoginSuccess.payload.twoFAFeature == true || onLoginSuccess.payload.twoFAFeature == "true"  ) {              
                        if(onLoginSuccess.payload.twoFactor == true || onLoginSuccess.payload.twoFactor == false || onLoginSuccess.payload.twoFactor == 'true' || onLoginSuccess.payload.twoFactor == 'false'){
                           this.router.navigate(['/twofactor']);
                          }else{ 
                             this.router.navigate(['/home']);
                          }
                    } else { 
                        var path = sessionStorage.getItem('redirect') 
                         if (path) {
                           this.router.navigate([`${path}`]);
                         } else {
                           this.router.navigate(['/home']);

                         }
                           }
                },300)

                 return;
            }
            this.loginservice.onPlayerLoggedIn(false);
        })
    )

    @Effect({dispatch:false})
    loginFail = this.actions$.pipe(
        ofType(loginActions.LOGIN_FAIL),
        tap((onLoginFailed:loginActions.LoginFail)=>{
        })
    )


    @Effect()
    registerStart = this.actions$.pipe(
        ofType(loginActions.REGISTER_START),
        exhaustMap((onRegisterStart:loginActions.RegisterStart) => 
        this.loginservice.onRegister(onRegisterStart.payload)
        .pipe(
            map(response => {
                this.loginservice.sessionlogout(response);
                return new loginActions.RegisterSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error,loginActions.RegisterFail);
            })
        )
        )
    )


    @Effect({dispatch:false})
    registerSuccess = this.actions$.pipe(
        ofType(loginActions.REGISTER_SUCCESS),
        tap((onRegisterSuccess:loginActions.RegisterSuccess)=>{
            if(onRegisterSuccess.payload.success === true){
                this.cookieService.set('wsession', onRegisterSuccess.payload.loginResponse.sessionId);
                this.loginservice.getMyprofile();
                this.loginservice.onGetPlayerBalance();
                this.loginservice.onPlayerLoggedIn(true);
                this.loginservice.registerMessage();
                //this.router.navigate(['/home']);
                localStorage.removeItem("Referrer")
            }else {
                this.loginservice.onPlayerLoggedIn(false);
            }
        })
    )



    @Effect()
    registerGetCountry = this.actions$.pipe(
        ofType(loginActions.REGISTER_GET_COUNTRY),
        exhaustMap((onRegisterGetCountry:loginActions.RegisterGetCountry) => 
        this.loginservice.onGetRegisterCountry()
        .pipe(
            map((response:Countries) => {
                this.loginservice.sessionlogout(response);
                return new loginActions.RegisterGetCountrySuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.RegisterGetCountryFail);
            })
        )
        )
    )




    @Effect()
    logoutStart = this.actions$.pipe(
        ofType(loginActions.LOGOUT_START),
        exhaustMap((onLogOutStart:loginActions.LogoutStart) => 
        this.loginservice.onLogOut()
        .pipe(
            map((response:LoginOut) => {
                // this.loginservice.sessionlogout(response);
                return new loginActions.LogoutSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error,loginActions.LogoutFail);
            })
        )
        )
    )

    @Effect({dispatch:false})
    logoutSuccess = this.actions$.pipe(
        ofType(loginActions.LOGOUT_SUCCESS),
        tap((onLogOutSuccess:loginActions.LogoutSuccess)=>{
            if(onLogOutSuccess.payload.success === true){
                this.loginservice.onPlayerLoggedIn(false);
                sessionStorage.removeItem('accessToken');
                this.cookieService.deleteAll();
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('playermobilenumber');
                sessionStorage.clear();
                localStorage.clear()
                this.router.navigate(['/home']);
                window.location.reload();

            }
        })
    )
    @Effect({dispatch:false})
    LogoutFail = this.actions$.pipe(
        ofType(loginActions.LOGOUT_FAIL),
        tap((onLogOutFail:loginActions.LogoutFail)=>{
            if(onLogOutFail.payload["success"] === false || onLogOutFail.payload["code"] === "SESSION_EXPIRED"){
                this.loginservice.onPlayerLoggedIn(false);
                sessionStorage.removeItem('accessToken');
                this.cookieService.deleteAll();
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('playermobilenumber');
                sessionStorage.clear();
                this.router.navigate(['/home']);
                // window.location.reload();
            }else{
                this.loginservice.onPlayerLoggedIn(false);
                sessionStorage.removeItem('accessToken');
                this.cookieService.deleteAll();
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('playermobilenumber');
                sessionStorage.clear();
                this.router.navigate(['/home']);
            }
        })
    )

    @Effect()
    forgotPasswordStart = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_START),
        exhaustMap((onForgoutPasswordStart:loginActions.ForgotPasswordStart) => 
        this.loginservice.onForgotPassword(onForgoutPasswordStart.payload)
        .pipe(
            map((response:forgotResponse) => {
                this.loginservice.sessionlogout(response);
               // this.toastr.success("Details Sent to your registered Email Id ");
                return new loginActions.ForgotPasswordSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.ForgotPasswordFail);
            })
        )
        )
    )
    @Effect()
    ResetPasswordStart = this.actions$.pipe(
        ofType(loginActions.RESETPASSWORD_START),
        exhaustMap((onResetPasswordStart:loginActions.ResetPasswordStart) => 
        this.loginservice.onResetPassword(onResetPasswordStart.payload)
        .pipe(
            map((response :ResetPassword)=> {
               // this.toastr.success("Details Sent to your registered Email Id ");
                return new loginActions.ResetPasswordSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.ResetPasswordFail);
            })
        )
        )
    )
    @Effect({dispatch:false})
    forGotPasswordSuccess = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_SUCCESS),
        tap((onForgotPasswordSuccess:loginActions.ForgotPasswordSuccess)=>{
            if(onForgotPasswordSuccess.payload.success === true){
            } else if (onForgotPasswordSuccess.payload.success === false) {
            }
        })
    )
    @Effect({dispatch:false})
    ForgotPasswordFail = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_FAIL),
        tap((onForgotPasswordFail:loginActions.ForgotPasswordFail)=>{
            if(onForgotPasswordFail.payload.success === true){
            } else if (onForgotPasswordFail.payload.success===false) {
                
            }
        })
    )


    @Effect()
    registerGenarateOTP = this.actions$.pipe(
        ofType(loginActions.REGISTER_GENERATE_OTP),
        exhaustMap((onRegisterGenarateOTPStart: loginActions.RegisterGenarateOTP) =>
            this.loginservice.onRegisterGenarateOTPStart(onRegisterGenarateOTPStart.payload)
                .pipe(
                    map((response: OtpRequest) => {
                        this.loginservice.sessionlogout(response);
                        return new loginActions.RegisterGenarateOTPSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.RegisterGenarateOTPFail);
                    })
                )
        )
    )
  
    @Effect()
    allGamesLunchStart = this.actions$.pipe(
        ofType(loginActions.ALL_GAMES_LUNCH_START),
        exhaustMap((onallGamesLunchStart: loginActions.allGamesLunchStart) => 
        this.loginservice.AllGamesLunch(onallGamesLunchStart.payload)
                .pipe(
                    map((response:AllGameLunch)=> {
                        console.log(response )
                        console.log(this.loginservice.sessionlogout(response) )
                        this.loginservice.sessionlogout(response);
                        if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
                            if(response.status == 200){
                                sessionStorage.setItem("IphoneErrMsg", "dontShow_popUp" )
                            }
                        }
                        // this.router.navigate(['/games']);
                        return new loginActions.allGamesLunchSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.allGamesLunchFail);
                    })
                )
        )
    )
    
}