import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs'; 
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Router } from '@angular/router';
@Component({
  selector: 'app-russionpoker',
  templateUrl: './russionpoker.component.html',
  styleUrls: ['./russionpoker.component.css']
})
export class RussionpokerComponent implements OnInit {

  private allGamesLunchStore: Subscription;
  gamelunchurl:any;
  urlSafe:SafeResourceUrl;
  mrgameLunchUrl:any;
  casinomessage:string;
  
  casinogamessuccessPop:boolean= false;
  constructor(public router:Router, public sanitizer: DomSanitizer, private store: Store<appState.AppState>) { 
 
  }

  ngOnInit() {
    this.allGamesLunchStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allGamesLunch) {
          if (loginState.allGamesLunch.status == 200) {
            this.casinomessage = "";
            this.mrgameLunchUrl = loginState.allGamesLunch.gameUrl;
            if(this.mrgameLunchUrl){
             this.slotsGameLunch();
            }

          } else if (loginState.allGamesLunch.status != 200) {
             this.casinogamessuccessPop= true;
            if (loginState.allGamesLunch.description) {
              this.casinomessage = loginState.allGamesLunch.description;
            } else {
              this.casinomessage = "Unknown Error"
            }
          }
        }
        if (loginState.allGamesLunchError) {
           this.casinogamessuccessPop= true;
          if (loginState.allGamesLunchError.message) {
            this.casinomessage = loginState.allGamesLunchError.message;
          } else {
            this.casinomessage = "Unknown Error"
          }
        }
      }
    )
 if(this.urlSafe == null && this.casinomessage == null){

  this.router.navigate(['/home']);
 }
  }
  slotsGameLunch(){
    this.gamelunchurl= this.mrgameLunchUrl;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
    return;
  }
  casinogamessuccessPopClose(){
    this.casinomessage="";
    this.casinogamessuccessPop=false;
    if(this.urlSafe == null){

      this.router.navigate(['/home']);
    }
}
closegame() {
  this.urlSafe = "";
  this.router.navigate(['/home']);
}
}
