import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { NgxPrintModule } from 'ngx-print';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { NavigationComponent } from './skins/navigation/navigation.component'; 
import { CarouselComponent } from './skins/carousel/carousel.component'; 
import { LiveMatchComponent } from './skins/live-match/live-match.component'; 
import { CasinoComponent } from './skins/casino/casino.component';
import { FooterComponent } from './skins/footer/footer.component';
import { PokerComponent } from './skins/poker/poker.component';
import { UpdateProfileComponent } from './skins/update-profile/update-profile.component';
import { ChangePasswordComponent } from './skins/change-password/change-password.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { PasswordsComponent } from './skins/passwords/passwords.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './source/appstates/loginstates/loginEffects';
import { PlayerEffects } from './source/appstates/playerstates/playerEffects';
import { CashierEffects } from './source/appstates/cashierstates/cashierEffects';
import { PlayerHistoryEffects } from './source/appstates/bethistory/betHistoryEffects';
import { resultEffects } from './source/appstates/betresult/betresultEffects'; 
import * as appState from 'src/app/source/appstates/appState';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RegisterComponent } from './skins/register/register.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';
import { ResultsComponent } from './skins/results/results.component';
import { HistoryComponent } from './skins/history/history.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { PlaceholderDirectiveDirective } from 'src/app/source/directives/placeholder-directive.directive'; 
import { ResetPasswordComponent } from "./skins/reset-password/reset-password.component";
import { BankaccountComponent } from './skins/bankaccount/bankaccount.component'; 

import { RouteauthGuard } from './routeauth.guard';  
import { BethistoryService } from '../app/source/services/bethistory.service';  
import { SlotsService } from '../app/source/services/slots.service';  
import { CashierService } from '../app/source/services/cashier/cashier.service';  
import { CasinoGamesService } from '../app/source/services/casino/casinogames.service';  
import { CockfightService } from '../app/source/services/cockfight/cockfight.service';  
import { CommonUtilService } from '../app/source/services/common/commonutil.service';  
import { CounterHandle } from '../app/source/services/common/CounterHandle.service';  
import { GoldenraceService } from '../app/source/services/goldenraceservice/goldenrace.service';  
import { LivedealerService } from '../app/source/services/livedealer/livedealer.service';  
import { LoginService } from '../app/source/services/login/login.service';  
import { MeightService } from '../app/source/services/meightservice/meight.service';  
import { PlayerService } from '../app/source/services/player/player.service';  
import { BetresultService } from '../app/source/services/sbresult/betresult.service';  
import { CommondataService } from '../app/source/services/sports/commondata.service';  
import { WebService } from '../app/source/services/sports/web.service';  
import { WebsocketService } from '../app/source/services/sports/websocket.service';  
import { CookieService } from 'ngx-cookie-service';

import { FilterpipePipe } from './source/pipes/filterpipe.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HomeComponent } from './skins/home/home.component';
import { DashboardComponent } from './skins/dashboard/dashboard.component';
import { ProfileComponent } from './skins/profile/profile.component';
import { TournamentsComponent } from './skins/tournaments/tournaments.component';
import { CashierComponent } from './skins/cashier/cashier.component';
import { ExchangeComponent } from './skins/exchange/exchange.component';
import { PokerHistoryComponent } from './skins/poker-history/poker-history.component';
import { CasinogamesComponent } from './skins/casinogames/casinogames.component';
import { RussionpokerComponent } from './skins/russionpoker/russionpoker.component';
import { RemoteHistoryComponent } from './skins/remote-history/remote-history.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RussianpokerhistoryComponent } from './skins/russianpokerhistory/russianpokerhistory.component';
import { BackgammonhistroyComponent } from './skins/backgammonhistroy/backgammonhistroy.component';
import { SportbookhistoryComponent } from './skins/sportbookhistory/sportbookhistory.component';
import { LivecasinoComponent } from './skins/livecasino/livecasino.component';
import { OnrightclickDirective } from './source/directives/onrightclick.directive';
import { ComingsoonComponent } from './skins/comingsoon/comingsoon.component';
import { UnsafeurlPipe } from './source/pipes/unsafeurl.pipe';
import { PtoptransfersComponent } from './skins/ptoptransfers/ptoptransfers.component';
import { MrslottyComponent } from './skins/mrslotty/mrslotty.component'; 
 import { RecaptchaModule } from 'ng-recaptcha';
import { IosInstallationComponent } from './skins/ios-installation/ios-installation.component';
import { IndieCasinoGamesComponent } from '../app/skins//indie-casino-games/indie-casino-games.component';
// import { LuckyStreakComponent } from '../../lucky-streak/lucky-streak.component';
import { LuckyStreakComponent } from '../app/skins/lucky-streak/lucky-streak.component';
import { LiveDealarComponent } from './skins/live-dealar/live-dealar.component';
import { NewSlotsNcrashComponent } from './skins/new-slots-ncrash/new-slots-ncrash.component';
import { VivogamesComponent } from './vivogames/vivogames.component';
import { RefferalComponent } from './skins/refferal/refferal.component';
import { TwoFactorComponent } from './skins/two-factor/two-factor.component';
import { ExchangepopupComponent } from './skins/exchangepopup/exchangepopup.component';
import { ExCurrencyComponent } from './skins/ex-currency/ex-currency.component';
import { SitemapComponent } from './skins/sitemap/sitemap.component';
import { ExcurrencypopupComponent } from './skins/excurrencypopup/excurrencypopup.component';
import { CrashComponent } from './skins/crash/crash.component';
// import { IndieCasinoGamesComponent } from '.../indie-casino-games/indie-casino-games.component';
// import { IndieCasinoGamesComponent } from './skins/goldenrace/indie-casino-games/indie-casino-games.component';
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, '/assets/Language/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    PagenotfoundComponent,
    HistoryComponent,
    ResetPasswordComponent,
    NavigationComponent, 
    CarouselComponent,
    PlaceholderDirectiveDirective, 
    LiveMatchComponent, 
    CasinoComponent,
    FooterComponent,
    PokerComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    WithdrawalComponent,
    GameHistoryComponent,
    CashOutHistoryComponent,
    TransactionHistoryComponent,
    DepositComponent,
    BalanceComponent,
    PasswordsComponent,
    LiveaddsComponent, 
    GoldenraceComponent,
    RegisterComponent,
    DepositresponseComponent, 
    BankaccountComponent, FilterpipePipe, HomeComponent, DashboardComponent, ProfileComponent, TournamentsComponent, CashierComponent, ExchangeComponent, PokerHistoryComponent,
    CasinogamesComponent,
    RussionpokerComponent,
    RemoteHistoryComponent,
    RussianpokerhistoryComponent,
    BackgammonhistroyComponent,
    SportbookhistoryComponent,
    LivecasinoComponent,
    OnrightclickDirective,
    ComingsoonComponent,
    UnsafeurlPipe,
    PtoptransfersComponent,
    MrslottyComponent,
    IosInstallationComponent,
    IndieCasinoGamesComponent,
    LuckyStreakComponent,
    LiveDealarComponent,
    NewSlotsNcrashComponent,
    VivogamesComponent,
    RefferalComponent,
    TwoFactorComponent,
    ExchangepopupComponent,
    ExCurrencyComponent,
    SitemapComponent,
    ExcurrencypopupComponent,
    CrashComponent,
    // IndieCasinoGamesComponent, 
    
  ],
  imports: [  
    Ng2SearchPipeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    // NgxPrintModule,
    ReactiveFormsModule,
    RecaptchaModule,
    BrowserAnimationsModule, 
    StoreModule.forRoot(appState.AppReducer),
    TranslateModule.forRoot({  loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    } }),
    EffectsModule.forRoot([LoginEffects, PlayerEffects, CashierEffects, PlayerHistoryEffects, resultEffects])
  ],
  providers: [ 
    RouteauthGuard,CurrencyPipe, BethistoryService, SlotsService, CashierService, CasinoGamesService, CockfightService, CommonUtilService, CounterHandle,
    GoldenraceService, LivedealerService, LoginService, MeightService, PlayerService, BetresultService, CommondataService,
    WebService, WebsocketService, CookieService ],
  bootstrap: [AppComponent],
 
})
export class AppModule { }
