import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env';
import { Profile } from '../../modules/player/profile';
import { GameLaunchURLs } from '../../modules/player/gameLaunchURLs';
import { LoginService } from '../login/login.service';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstates/appState';
import { GameHistory } from '../../modules/player/gameHistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';
import { UpDateProfileModel } from '../../modules/player/updateprofile';
import { Value } from '../../modules/cashier/balance';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
function launchFlashLobby(params: object) {
  let authWindow = window.open('about:blank', '', 'left=20,top=20,width=800,height=600,toolbar=0,resizable=1');
  authWindow.location.replace("/lobby/lobbyflash.html");
  authWindow.sessionStorage.setItem("flashgamelaunch", JSON.stringify(params));
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  amountvalue: 'value.json';
  constructor(private httpClient: HttpClient, private cookieService: CookieService,
    private store: Store<AppState>) { }

  onPlayerGetProfile() {
    return this.httpClient.post<Profile>(`${environment.appApi.baseUrl}${environment.appApi.player.getProfile}`, {}, this.httpOptions());
  }
  onPlayerUpdateProfile(postData) {
    return this.httpClient.post<UpDateProfileModel>(`${environment.appApi.baseUrl}${environment.appApi.player.updateProfile}`, postData, this.httpOptions());
  }
  onPlayerUpdatePassword(postData) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.updatePassword}`, postData, this.httpOptions());
  }
  onPlayerGetLaunchURLs() {
    return this.httpClient.post<GameLaunchURLs>(`${environment.appApi.baseUrl}${environment.appApi.player.getLaunchURLS}`, {}, this.httpOptions());
  }

  onPlayerGetGameHistory(postdata) {
    return this.httpClient.post<GameHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.game}`, postdata, this.httpOptions());
  }

  onPokerGetGameHistory(postdata) {
    return this.httpClient.post<any>(`${environment.appApi.baseUrl}${environment.appApi.history.pokergame}`, postdata, this.httpOptions());
  }

  onPlayerGetRemoteGameHistory(postdata) {
    if (postdata.name == null) {
      console.log(postdata.name);
      const body = {
        startDate: postdata.startDate,
        endDate: postdata.endDate,
        name: postdata.name,
        limit: postdata.limit,
        index: postdata.index
      }
      return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.game}`, body, this.httpOptions());
    } else if (postdata.name == 'all') {
      console.log(postdata.name);
      const res = {
        startDate: postdata.startDate,
        endDate: postdata.endDate,
        currency: postdata.currency,
        limit: postdata.limit,
        index: postdata.index
      }
      return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.remotegame}`, res, this.httpOptions());

    }
    else {
      return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.remotegame}`, postdata, this.httpOptions());
    }
  }
  PlayerGetAllRemoteGameHistory(postdata) {
    console.log(postdata);

    return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.remotegame}`, postdata, this.httpOptions());

  }
  onPlayerGetTournamentsList(postdata) {
    return this.httpClient.post<TournamentsList>(`${environment.appApi.baseUrl}${environment.appApi.player.tournamentsList}`, postdata, this.httpBeforLoginOptions());
  }

  onPlayerGetStats() {
    return this.httpClient.post<Stats>(`${environment.appApi.baseUrl}${environment.appApi.player.playerStats}`, {}, this.httpBeforLoginOptions());
  }

  onPlayerGetPlayerLevels() {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.playerLevels}`, {}, this.httpOptions());
  }
  onPlayerGetTopCPPlayers() {
    return this.httpClient.get(`${environment.appApi.baseUrl}${environment.appApi.player.topcpplayer}`, this.httpBeforLoginOptions());
  }

  launchFlashGame(value: GameLaunchURLs) {
    let params = { serverUrls: [], sessionID: "", clientType: "", LANG: "", swf2load: "" };
    value.connections.forEach(value => {
      params.serverUrls.push(value.uri);
    });

    params.sessionID = this.cookieService.get('wsession').split(".")[1];
    params.clientType = "POKER_LOBBY";
    params.LANG = "en";
    params.swf2load = "flashloader";
    launchFlashLobby(params);
  }
  getSportsBookToken(value: string) {
    let data = {
      operatorId: environment.skinId,
      token: "NO",
      loginName: value
    }
  }
  httpOptions() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid': environment.skinId,
        'wsession': this.cookieService.get('wsession'),
        'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
      })
    };
    return options;
  }
  httpOptionsVivo() {
    let lang = localStorage.getItem("locale")
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid': environment.skinId,
        'wsession': this.cookieService.get('wsession'),
        'Authorization': "Bearer " + sessionStorage.getItem('accessToken'),
        'lang': lang
      })
    };
    return options;
  }
  httpOptionsIndie() {
    let httpOption = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'wsession': this.cookieService.get('wsession'),
        siteid: environment.skinId,
      }),
    };
    return httpOption;
  }
 
  httpBeforLoginOptions() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid': environment.skinId
      })
    };
    return options;
  }

  /* adding for river poker */
  getExchangeRates() {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.getExchangeRates}`, {}, this.httpOptions());
  }

  makeExchange(exchangeInfo) {
    return this.httpClient.post<any>(`${environment.appApi.baseUrl}${environment.appApi.player.exchangeVipPointsUrl}`, exchangeInfo, this.httpOptions());

  }
  makeP2PTransfer(transferInfo) {
    return this.httpClient.post<any>(`${environment.appApi.baseUrl}${environment.appApi.casino.transferUrl}`, transferInfo, this.httpOptions());

  }

  playerDeposit(depositData) {
    return this.httpClient.post<any>(`${environment.appApi.cashier.deposit}`, depositData, this.httpOptionsVivo());

  }
  chipsAmount() {
    // let _jsonURL = 'assets/value.json';
    // let _jsonURL = environment.appApi.baseUrl+'value.json';
    // return this.httpClient.get(_jsonURL);
    // return this.httpClient.get(`${environment.appApi.cashier.getRate}`,this.httpOptions());
    return this.httpClient.get(`/api/cashier/getRate`, this.httpOptions());
  }

  playerWithdraw(withdrawBody) {
    return this.httpClient.post<any>(`${environment.appApi.cashier.withDrawCashout}`, withdrawBody, this.httpOptions());

  }

  sportsDataServe(logName) {
    console.log(logName)
    // return this.httpClient.post<any>(`${environment.appApi.player.sportUrl}`,logName,this.httpOptions());
    return this.httpClient.post<any>(`/rest/Sports/authenticate`, logName, this.httpOptions());
  }

  sportsSettionTkn(logName, sprtsTkn): Observable<any> {
    return this.httpClient.get(`${environment.appApi.sportsSessionToken}/${logName}/${sprtsTkn}`);
  }
  getInofServe(logName) {
    // return this.httpClient.post<any>(`${environment.appApi.player.getinfo}`,logName,this.httpOptions());
    return this.httpClient.post<any>(`/rest/Sports/getinfo`, logName, this.httpOptions());
  }

  twoFactorApi(body): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.twofactor}`,body, this.httpOptions());
  }
  acceptTwoFactorApi(body): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.acceptTwofactor}`,body, this.httpOptions());
  }
  twofactorOptin(body): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.twofactorOptin}`,body, this.httpBeforLoginOptions());
  }
  verifyAccount(body): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.verifyAccount}`,body, this.httpBeforLoginOptions());
  }
  resetpasswordNew(body): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.resetpasswordNew}`,body, this.httpBeforLoginOptions());
  }
  
  exchangRates(exchangeBody) {
    return this.httpClient.post<any>(`${environment.appApi.cashier.exchangRates}`, exchangeBody, this.httpOptions());
  }
  walletExchange(exchangeBody) {
    return this.httpClient.post<any>(`${environment.appApi.cashier.walletExchange}`, exchangeBody, this.httpOptions());
  }
  getAvatarListApi() {
    return this.httpClient.get<Profile>(`${environment.appApi.baseUrl}${environment.appApi.player.getAvatarList}`, {});
  }
  setAvatarListApi(postData) {
    return this.httpClient.post<UpDateProfileModel>(`${environment.appApi.baseUrl}${environment.appApi.player.setAvatar}`, postData, this.httpOptions());
  }
  getPlayerAvatar() {
    return this.httpClient.get<Profile>(`${environment.appApi.baseUrl}${environment.appApi.player.getAvatar}`, this.httpOptions());
  }
}
