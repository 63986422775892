import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { PlayerGetLaunchURLs} from 'src/app/source/appstates/playerstates/playerActions';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-poker',
  templateUrl: './poker.component.html',
  styleUrls: ['./poker.component.css']
})
export class PokerComponent implements OnInit {
  flashlunch:any;
  loginSub: Subscription;
  pokermessage:string="";
  playerLoggedIn: boolean = false;
  pokersuccesspop:boolean=false;
  constructor(private store: Store<appState.AppState>) {
   }
  ngOnInit() {

    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
  }
  onClickFlashLunch(){
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.flashlunch =  this.store.dispatch(new PlayerGetLaunchURLs());
    } else if(this.playerLoggedIn == false) {
      this.pokermessage='Please Login';
      this.pokersuccesspop=true;
    }
  }
  pokerpopClose(){
    this.pokermessage='';
    this.pokersuccesspop=false;
  }
}
