import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './source/appstates/appState';
import { LoginState } from './source/appstates/loginstates/loginState'; 

@Injectable({
  providedIn: 'root'
})
export class RouteauthGuard implements CanActivate, CanActivateChild, CanLoad {
  
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  
  constructor(private store: Store<AppState>, private router:Router){
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
      }
    });
  }
  ngOnInit(){


    console.log( '------------------------------' )
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.playerLoggedIn == true) { 
        return true;
      } else if (this.playerLoggedIn == false) { 

        this.router.navigate(['/home']);
        console.log( '------------------------------' )
        console.log( window.location )
        console.log( '------------------------------' )
        return false;
      }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.playerLoggedIn == true) { 
        return true;
      } else if (this.playerLoggedIn == false) { 

        this.router.navigate(['/home']);
        return false;
      }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      if (this.playerLoggedIn == true) { 

        return true;
      } else if (this.playerLoggedIn == false) { 

        this.router.navigate(['/home']);
        return false;
      }
  }
}
