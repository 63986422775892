import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../app/source/services/slots.service';
import { Store } from "@ngrx/store";
import * as appState from "src/app/source/appstates/appState";
import * as cashierActions from "src/app/source/appstates/cashierstates/cashierActions";
import { Subscription } from "rxjs";
import { LoginState } from "src/app/source/appstates/loginstates/loginState";
import { slotsData } from "src/app/source/modules/slotsmodel/slotsdata";
import { CasinoGamesService } from '../source/services/casino/casinogames.service';
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-vivogames',
  templateUrl: './vivogames.component.html',
  styleUrls: ['./vivogames.component.css']
})
export class VivogamesComponent implements OnInit {
  vivoGamesData: any;
  casinomessage: any;
  mantan:boolean = false;
  casinogamessuccessPop: boolean = false;
  showLoad: boolean = false;
  openTab: any;
  eventname: string = "livecasino";
  showgamefrom: number = 0;
  liveCasinoGmList = [];
  lucky = [];
  playerLoggedIn: boolean = false;
  loginSub: Subscription;
  slotsfundata: slotsData;
  brandid: any;
  gameName: any;

  constructor(private router: Router, private cookieService: CookieService, private slotsService: SlotsService, private store: Store<appState.AppState>, private slotsservice: SlotsService, private casinoGamesService: CasinoGamesService,) {
    this.slotsservice.lukystrik().subscribe((data) => { this.lucky = data.luckstrikk; });
    this.slotsservice.liveCasinoGames().subscribe((data) => { this.liveCasinoGmList = data.livecasino; });
    this.slotsService.vivoGames().subscribe((data) => { this.vivoGamesData = data.VivoGaming });
  }
  openLoginDialog() {
    this.casinogamessuccessPop = true;
  }
  ngOnInit() {
    this.closegame('livecasino');
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        } else {
          this.hitfunService();
        }
      });
  }
  hitfunService() {
    this.slotsservice.httpClientfun().subscribe((data) => {
      this.slotslunchfun(data);
    });
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;
    }
  }
  closegame(event) {
    this.openTab = event;
    this.eventname = event
    this.showgamefrom = 0;
    if (event === "Ezugi") {
      this.liveCasinoGmList = this.liveCasinoGmList.filter(Obj => Obj.provider !== "Vivo");
    } else if (event === "livecasino") {
      let addViov = this.liveCasinoGmList.filter(Obj => Obj.provider === "Vivo");
      if (addViov.length === 0) {
        this.liveCasinoGmList.push(
          {
            "id": "1",
            "name": "Roulette",
            "provider": "Vivo",
            "media": "vivo_roulette"
          },
          {
            "id": "2",
            "name": "Baccarat",
            "provider": "Vivo",
            "media": "vivo_baccarat"
          },
          {
            "id": "3",
            "name": "Blackjack",
            "provider": "Vivo",
            "media": "vivo_blackjack"
          },
          {
            "id": "4",
            "name": "Teenpatti",
            "provider": "Vivo",
            "media": "vivo_teenpatti"
          }
        )
      }
    }
  }

  launchFreshDeck() {
    if (this.playerLoggedIn == false) {
      this.casinomessage = "Please Login"
    }
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
    } else {
      this.showLoad = true;
      this.casinoGamesService.launchFreshDeck().subscribe(
        (data) => {
          if (!data)
            this.casinomessage = "Error loading";
          else {
            let redirectionurl = data["FRESH_DECK_LAUNCH_URL"];
            window.open(redirectionurl, '_self');
            setTimeout(() => {
              this.showLoad = false;
            }, 1500);
          }
        },
        (err) => {
          this.casinomessage = "Error loading";
        }
      );
    }
  }
  liveDealer(gname, pro) {
    if (this.playerLoggedIn == false) {
      this.casinomessage = "Please Login"
    }
    console.log(gname)
    console.log(pro)
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());

    if (this.playerLoggedIn == true) {
      this.showLoad = true;
      if (pro == "Vivo") {
        let type = (gname).split("_")[1]
        this.vivoGamesLaunch(type)
      } else {
        this.gameName = gname;
        let wsession = this.cookieService.get("wsession");
        console.log(wsession);
        this.slotsservice
          .liveDealerServiceData(wsession)
          .subscribe((liveDealerdata) => {
            this.liveDealerunch(liveDealerdata);
          });
      }
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
    }
  }
  liveDealerunch(data) {
    let ezugiLunch =
      data.EZUGI_GAME_URL +
      "token=" +
      data.EZUGI_TOKEN +
      "&operatorId=" +
      data.EZUGI_OPERATOR_ID +
      "&clientType=html5&language=en&selectGame=" +
      this.gameName;
    console.log(ezugiLunch);
    sessionStorage.setItem("gameurl", ezugiLunch);
    if (ezugiLunch) {
      let otherGames = "all";
      sessionStorage.setItem("urlweareGame", otherGames);
      window.open(ezugiLunch, "_self")
      setTimeout(() => {
        this.showLoad = false;
      }, 1500);
    } else if (ezugiLunch == "") {
      this.router.navigate(["/"]);
    }
  }
  vivoGamesLaunch(name: any) {
    let ProName = sessionStorage.getItem('webSessionId')
    if (ProName !== null) {
      this.showLoad = true;
      this.slotsService.vivogameApi().subscribe((response) => {
        // this.vivogamesurl(response);
        console.log(response)
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        const launchUrl = response.VIVO_GAME_LAUNCH_URL.split("selectedGame=")[0] + `selectedGame=${name}` + response.VIVO_GAME_LAUNCH_URL.split("selectedGame=All")[1];
        console.log(launchUrl)
        setTimeout(() => {
          this.showLoad = false;
        }, 1500);
        window.open(launchUrl, '_self')
      });
    } else {
      this.casinogamessuccessPop = true;
      this.casinomessage = "Please login";
    }
  }
  casinogamessuccessPopClose() {
    this.casinogamessuccessPop = false;
  }

}
