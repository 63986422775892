import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetGameHistory} from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';

@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.component.html',
  styleUrls: ['./game-history.component.css'],
})
export class GameHistoryComponent implements OnInit {
  private profilestoreSub: Subscription;
  private loginSub: Subscription;
  gamehistory: any;
  form: FormGroup;
 gameHistoryErrorMessage:string="";
  profile:string;
  startDate:Date;
  todaydate: any;
  endDate:Date;
  playerLoggedIn:boolean=false;
  totaldata:number;
  description:string;
  p:number = 1;
  n:number = 1;
  itemsperpagecount = [
    {num :5},
    {num :10},
    {num :15},
    {num :20},
    {num :25}
  ];
  steddate:any;
  constructor(private store: Store<appState.AppState>) {
    
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
   }

  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.profile = sessionStorage.getItem('profile');
    this.endDate = new Date();
    this.startDate =  new Date();
    this.form = new FormGroup({
      "currency": new FormControl("CHP", Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "name": new FormControl(null, Validators.required),
      "limit": new FormControl(1000, Validators.required),
      "index": new FormControl(0, Validators.required),
    });
    this.form.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   });

    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.GameHistory) {
          if (playerState.GameHistory.success == true) {
            this.totaldata = playerState.GameHistory.total;
            this.gamehistory = playerState.GameHistory.values;
            this.description = "No Records Found";
            this.gameHistoryErrorMessage=null;
          }else if(playerState.GameHistory.success==false){
            this.gamehistory=null;
            this.description = null;
            this.gameHistoryErrorMessage = playerState.GameHistory.description;
          }
        }
        if(playerState.GameHistoryError){
          this.gamehistory=null;
          this.description = null;
          this.gameHistoryErrorMessage = playerState.GameHistoryError.message;
        }
      }
    );

  }
  onCasinoFormSubmit() {
    this.form.value.startDate = moment(this.form.value.startDate).format('DD-MM-YYYY');
   this.form.value.endDate = moment(this.form.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new PlayerGetGameHistory(this.form.value));
  }
  ngOnDestroy(){
    if(this.profilestoreSub){
      this.profilestoreSub.unsubscribe();
    }
  }

}
