import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { environment } from '@app/env';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { appConstants } from 'src/app/source/constants/appconstants';
declare const $: any;
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit, OnDestroy {
  @Input() formState: string;
  ProfileUpdate: FormGroup;
  private storeSub: Subscription;
  private loginSub: Subscription;
  private profile: Profile;
  updateProfileError: string;
  private countries: Countries;
  basedonupdateprofile = environment.skinId;
  updatePopup: boolean = false;
  private loginstoreSub: Subscription;
  countrydata: Country[];
  isdcode: string;
  UpdateProfilemessage: string = "";
  UpdateProfilesuccessPop: boolean = false;
  historyloader: boolean = false;
  firstName: string;
  lastName: string;
  Profilecity: string;
  playerLoggedIn: boolean = false;
  changedprofile: boolean = false;
  pixelURL: string;
  showCopiedMessage = false;

  lang: any = appConstants.language;
  constructor(private store: Store<appState.AppState>) {
    this.store.dispatch(new playerActions.ResetState());
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {

    this.store.dispatch(new playerActions.ResetState());
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.ProfileUpdate = new FormGroup({
      "nickname": new FormControl('', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
      "email": new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
      "firstName": new FormControl('', [Validators.minLength(4), Validators.pattern('[a-zA-Z]*')]),
      "pixelURL": new FormControl('', Validators.pattern('[a-zA-Z]*')),
      "lastName": new FormControl('', Validators.pattern('[a-zA-Z]*')),

      "address": new FormGroup({
        "city": new FormControl('', Validators.pattern('[a-zA-Z]*')),
        "phone": new FormControl('', [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
        "country": new FormControl(),
      }),
    })

    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.countries) {
          this.countries = loginState.countries;
          this.countrydata = this.countries.values;

        }
      }
    )

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          if (playerState.profile.success == true) {

            this.profile = playerState.profile;
            if (this.profile.nickname) {
              (<FormControl>this.ProfileUpdate.get('nickname')).setValue(this.profile.nickname);
            }
            if (this.profile.email) {
              (<FormControl>this.ProfileUpdate.get('email')).setValue(this.profile.email);
            }
            if (this.profile.firstName) {
              (<FormControl>this.ProfileUpdate.get('firstName')).setValue(this.profile.firstName);
              this.firstName = this.profile.firstName;
            }
            if (this.profile.pixelURL) {
              (<FormControl>this.ProfileUpdate.get('pixelURL')).setValue(this.profile.pixelURL);
              this.pixelURL = this.profile.pixelURL;
            }
            if (this.profile.lastName) {
              (<FormControl>this.ProfileUpdate.get('lastName')).setValue(this.profile.lastName);
              this.lastName = this.profile.lastName;
            }
            if (this.profile.address) {
              if (this.profile.address.city) {
                (<FormControl>this.ProfileUpdate.get('address.city')).setValue(this.profile.address.city);
                this.Profilecity = this.profile.address.city;
              }
              if (this.profile.address.country) {
                for (let data of this.countrydata) {
                  if (data.id == this.profile.address.country) {
                    this.isdcode = data.isd;
                  }
                }
                (<FormControl>this.ProfileUpdate.get('address.country')).setValue(this.profile.address.country);
              }
              if (this.profile.address.phone) {
                (<FormControl>this.ProfileUpdate.get('address.phone')).setValue(this.profile.address.phone);
              }
              // if (this.profile.address.country) {
              //   (<FormControl>this.ProfileUpdate.get('address')).get("country").setValue(this.profile.address.country);
              // }
            }

          } else if (playerState.profile.success === false) {
            this.updateProfileError = playerState.profile.description;
          }
        }
      }
    )


    //   this.ProfileUpdate.valueChanges.subscribe(x => {
    //     if(x.firstName != this.firstName || x.lastName != this.lastName || x.address.city != this.city){

    //     }else if(x.firstName == this.firstName || x.lastName == this.lastName || x.address.city == this.city){

    //     }

    // })

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.UpDateProfileModel) {
          this.historyloader = false;
          if (playerState.UpDateProfileModel.success == true) {
            this.updatePopup = false;
            this.updateProfileError = "";
            this.UpdateProfilesuccessPop = true;
            if (this.lang == "EN") {
              this.UpdateProfilemessage = 'Updated Successfully';
            } else {
              this.UpdateProfilemessage = 'با موفقیت به روز شد';
            }
          } else if (playerState.UpDateProfileModel.success == false) {
            this.updateProfileError = playerState.UpDateProfileModel.description;
          }
        }
        if (playerState.UpDateProfileModelError) {
          this.historyloader = false;
          this.updateProfileError = playerState.UpDateProfileModelError.statusText;
        }
      })

    this.store.dispatch(new playerActions.PlayerGetProfile());
    this.store.dispatch(new loginActions.RegisterGetCountry());
    this.ProfileUpdate.valueChanges.subscribe(x => {
      if (x.firstName != this.firstName || x.lastName != this.lastName || x.address.city != this.Profilecity || x.pixelURL != this.pixelURL) {

        this.changedprofile = true;
      } else {

        this.changedprofile = false;
      }
    });

  }

  onInput() {
    if ($(window).width() < 660 && $(window).height() < 400) {
      $("input").focusin(function () {
        window.scroll(0, $(this).offset().top - 20);
        $('.fixMenu').css("position", "relative");
        $('.body_m').css("margin-top", "0px");
      });
      $("input").focusout(function () {
        $('.fixMenu').css("position", "fixed");
        $('.body_m').css("margin-top", "52px");
      });
    }
  }

  onProfileUpDateFormSubmit() {
    this.historyloader = true;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.ProfileUpdate.value.address.country = 288;
    this.store.dispatch(new playerActions.PlayerUpdateProfile(this.ProfileUpdate.value));


  }
  ngOnDestroy() {
    if (this.profile) {
      this.profile = null;
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();;
    }
  }

  openUpdatePop() {
    this.store.dispatch(new playerActions.ResetState());
    this.updatePopup = true;
  }
  closeUpdatePop() {
    this.ProfileUpdate.reset();
    this.store.dispatch(new playerActions.ResetState());
    this.updatePopup = false;
    this.store.dispatch(new playerActions.PlayerGetProfile());
  }
  UpdateProfilePopClose() {

    this.store.dispatch(new playerActions.ResetState());
    this.UpdateProfilemessage = "";
    this.UpdateProfilesuccessPop = false;
  }

  copyMessage(val: string) {
    // console.log(val)
    // if (val) {
    //   navigator.clipboard.writeText(val) 
    // }
    const tempTextarea = document.createElement('textarea');
  tempTextarea.value = val;
  document.body.appendChild(tempTextarea);

  // Select the text inside the textarea
  tempTextarea.select();
  tempTextarea.setSelectionRange(0, tempTextarea.value.length);  // For mobile devices

  try {
    // Execute the copy command
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Text copied successfully');
      this.showCopiedMessage = true;
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, 2000);
    } else {
      console.error('Failed to copy text');
    }
  } catch (err) {
    console.error('Error copying text: ', err);
  }

  // Remove the temporary textarea element
  document.body.removeChild(tempTextarea);

  }


}
