export const environment = {
  production: false,
  window:(typeof window !== "undefined")? window.location.host:'',
  skinId: 'rp1',
  siteid: 'rp1',
  logo: 'rp1',
  captchaName: 'rp1',


  // skinId: 'riverpoker-dev',
  // logo: 'riverpoker-dev',
  // captchaName: 'riverpoker-dev',

  sitekey: [
    {
      id: 'riverpoker-dev',
      keyid: '6LfoBsUUAAAAADg8OhPVLdg7QTZhmCvma1xvj85i',
      betslipcontent: "Your bet has been booked. Please print it and go to any riverpoker betting shop to lay the bet or Load the Betslip after succesful login from 'Load Bet Slip' to place the bet. Odds May Change. ",
      betsliplogin: "Bet Online! If you want to bet online, create an account using our register form. If you're already registered, close this window and login.",
      bookingnumber: "Booking number successfully registred on riverpoker.",
      couponid: 'Bet successfully registred on riverpoker.',
      acceptodd: ' I Accept Odd Change and riverpoker Terms & Conditions.'
    },
    {
      id: 'riverpoker',
      keyid: '6LdBOMwUAAAAAHU5yUK-DH0QfX_ibLi6uJ9jS40X',
      betslipcontent: "Your bet has been booked. Please print it and go to any riverpoker betting shop to lay the bet or Load the Betslip after succesful login from 'Load Bet Slip' to place the bet. Odds May Change. ",
      betsliplogin: "Bet Online! If you want to bet online, create an account using our register form. If you're already registered, close this window and login.",
      bookingnumber: "Booking number successfully registred on riverpoker.",
      couponid: 'Bet successfully registred on riverpoker.',
      acceptodd: ' I Accept Odd Change and riverpoker Terms & Conditions.'
    }
  ],

  appApi: {
    //baseUrl:"http://zbet-dev.czargaming.com",
    //baseUrl:"http://africa-dev.czargaming.com/api",
    baseUrl: '',
    livedealer: '/rest/ezugi/handler',
    mrFunGamesUrl: '/rest/mrslotty/mrGameFunURL',
    cockfight: '/rest/sabong/handler',
    indiecasino: "rest/indiCasino/api/handler",
    ezugi: "rest/ezugi/session",
    // vivourl: "api/Vivo/handler",
    vivourl: "rest/vivo/balance_integration/vivoGaming",
    //emial: '/rest/bet/email/ticket',
    // heblounch: '/rest/habanero/handler',
    heblounch: 'rest/habanero/session',
    hebfunlounch: '/rest/habanero/habFunMode',
    casino_blackajack: '/api/player/launchCasinoGame?name=CASINO_BLACKJACK_NGN&real=1&walletCCode=CHP',
    casino_baccarat: '/api/player/launchCasinoGame?name=CASINO_BACCARAT_NGN&real=1&walletCCode=CHP',
    casino_europeanroulette: '/api/player/launchCasinoGame?name=CASINO_EUROPEANROULETTE_NGN&real=1&walletCCode=CHP',
    casino_Oasispoker: '/api/player/launchCasinoGame?name=CASINO_OASISPOKER_NGN&real=1&walletCCode=CHP',
    goldenrace: '/rest/xpress/handler',
    //goldenracefun: '/rest/xpress/handler',
    meight: 'rest/m8/handler',
    login: '/api/player/login',
    verifyAccount: '/api/player/verifyAccount',
    logout: '/api/player/logout',
    register: '/api/player/register',
    getCountries: '/api/player/getCountries',
    fotgotPassword: "/api/player/forgotPassword",
    resetPassword: "/api/player/forgotPassword",
    // resetPassword: "/api/player/resetPassword",
    generateOTP: '/api/player/generateOTP',
    allGamesUrl: '/api/player/mrGameURL',
    sportsSessionToken: '/rest/sb/handler',
    // generateCaptcha: '/api/player/generateCaptchaText',
    generateCaptcha: '/api/player/generateCaptchaText',
    updateExpiredPassword: 'api/player/public/updatePassword',
    twofactor: 'api/player/verifyTwoFactor',
    acceptTwofactor: 'api/player/acceptTwoFactor',
    twofactorOptin:'/api/player/verifyTwoFactorOptIn',
    resetpasswordNew:'/api/player/reset-password',

    player: {
      getAvatarList: "api/player/getAvatarList",
      setAvatar: "api/player/setAvatar",
      getAvatar: "/api/player/getAvatar",
      getProfile: "/api/player/getProfile",
      updateProfile: "/api/player/updateProfile",
      updatePassword: "/api/player/updatePassword",
      verifyAccount: "/api/player/verifyAccount",
      resendToken: "/api/player/resendToken",
      getLaunchURLS: "/api/player/getLaunchURLS",
      tournamentsList: "/api/player/getPokerTournamentsList",
      playerStats: "/api/player/getStats",
      playerCurrencies: "/api/player/getCurrencies",
      games: "/api/player/getGames",
      playerLevels: "/api/cp/playerLevels",
      topcpplayer: "/api/player/getTopCPPlayers",
      /* adding for river poker */
      getExchangeRates: "/api/cp/exchangeRates",
      exchangeVipPointsUrl: "/api/cp/exchange",
      sportUrl: "/rest/Sports/authenticate",

      getinfo: "/rest/Sports/getinfo"
    },
    cashier: {
      slideplay: '/api/cashier/deposit',
      cashoutotp: '/api/cashier/generateCashOutOTP',
      balance: '/api/cashier/balance',
      resetPlayMoneyBalance: '/api/cashier/resetPlayMoneyBalance',
      getPaymentSystems: '/api/cashier/getPaymentSystems?pgid=',
      depositSystems: '/api/cashier/depositSystems',
      withdrawSystems: '/api/cashier/getCashOutInfo',
      withDrawCashout: '/api/cashier/cashout',
      deposit: '/api/cashier/buy',
      getOpenWithdrawRequests: '/api/cashier/getOpenWithdrawRequests',
      cancelWithdrawRequest: '/api/cashier/cancelWithdrawRequest',
      withdraw: '/api/cashier/withdraw',
      addBankAccount: '/api/cashier/addBankAccount',
      getBankAccounts: '/api/cashier/getBankAccounts',
      deleteBankAccounts: '/api/cashier/deleteBankAccounts',
      getRate: '/api/cashier/getRate',
      exchangRates: '/api/cashier/getExchangeRates',
      walletExchange: '/api/cashier/walletExchange'


    },
    history: {
      transaction: '/api/history/transaction',
      transactionCheck: '/api/history/transactionCheck',
      cashout: '/api/cashier/getOpenWithdrawRequests',
      pokergame: "/api/history/game",
      // pokergame: "api/history/gameRoundData",
      // game: "/api/history/casinoGames",
      game: "/api/history/remoteGames",
      remotegame: "/api/history/remoteGames"
    },
    /* adding for river poker */
    casino: {
      baseUrl: "/api/",
      getCasinoGames: "player/getCasinoGames",
      launchCasinoGameBaseUri: "player/launchCasinoGame",

      rp1Url: "https://rp1.czargaming.com",
      africaTestUrl: "http://africa-test.czargaming.com",
      transferUrl: "/api/cashier/pToPTransfer",
      freshdeck: "api/Vivo/freshDeck/handler",
      liveCasinoUrl: "/api/Vivo/handler",
    },

  },
};