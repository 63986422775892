import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as PlayerBetHistoryActions from './bethistoryActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { BethistoryService} from '../../services/bethistory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

import { BetHistory } from '../../modules/BetHistory/bet-history';
import { Router } from '@angular/router';
import { isBoolean } from 'util';
import { LoginService } from '../../services/login/login.service';

@Injectable()
export class PlayerHistoryEffects{
    constructor(private actions$:Actions,
        private Bethistoryservice:BethistoryService,
        private router:Router,private loginservice: LoginService){}
    
    @Effect({dispatch:false})
    PlayerGetHistorySuccess = this.actions$.pipe(
        ofType(PlayerBetHistoryActions.PLAYER_GET_HISTORY_SUCCESS),
        tap((getBetHistory:PlayerBetHistoryActions.PlayerGetHistoryFail)=>{
             if(getBetHistory.payload){
            }
        })
    )
    }