import { Component, ViewChild, HostListener, NgZone } from '@angular/core';
import { AppState } from './source/appstates/appState';
import { Store } from '@ngrx/store';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { interval, Subscription } from 'rxjs';
import { LoginState } from './source/appstates/loginstates/loginState';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CounterHandle } from './source/services/common/CounterHandle.service';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
//import { NavigationComponent } from './skins/navigation/navigation.component';
//import { ResetPasswordComponent } from './skins/reset-password/reset-password.component';
import { appConstants } from 'src/app/source/constants/appconstants';
import { CookieService } from 'ngx-cookie-service';
import { WebService } from './source/services/sports/web.service';
import { CashierState } from './source/appstates/cashierstates/cashierState';
import { MeightService } from './source/services/meightservice/meight.service';
declare var $: any;

// declare const getRouterUrl:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  modelpopup: boolean = false;
  sessionoutmessage: string;
  counter: any;
  logoutCounter: any = null;
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  isExecuteCounter: boolean = true;
  selectedlanguege = 'EN';
  lang: any = appConstants.language;
  wessionid: any;
  private storeSub: Subscription;
  constructor(private ngZone: NgZone, private MeightService:MeightService, private webservice: WebService, private cookieService: CookieService, private router: Router, private store: Store<AppState>, private route: ActivatedRoute, private counterService: CounterHandle) {

    // this.route.queryParams.subscribe(params => {
    //   this.wessionid = params.wsession;
    //   if (this.wessionid != null) {
    //     sessionStorage.setItem('wsession', this.wessionid);
    //     let value = true;
    //     this.store.dispatch(new loginActions.PlayerLoggedIn({ loggedIn: value }));

    // this.store.dispatch(new playerActions.PlayerGetProfile());
    //     return;
    //   }
    // });

    this.initializeApp();
    this.route.queryParams.subscribe(params => {
      if (params.wsession) {
        // sessionStorage.removeItem('accessToken');
        // this.cookieService.deleteAll();
        // sessionStorage.removeItem('profile');
        // sessionStorage.removeItem('playermobilenumber');
        // sessionStorage.clear();
        this.wessionid = params.wsession;
        this.cookieService.set('wsession', this.wessionid);
        this.store.dispatch(new playerActions.PlayerGetProfile());
        // const url = location.origin+this.router.url;
        // const urlof = url.substr(0, url.indexOf("?"));
        // window.location.replace(urlof);
        const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
        this.router.navigateByUrl(urlWithoutQueryParams)
          .then(() => {
            // any other functionality when navigation succeeds
            params = null;
          });
        return;
      }
    });
    if (this.lang == null) {
      this.selectedlanguege = 'EN'
    } else {
      if (typeof window !== "undefined") {
      this.selectedlanguege = localStorage.getItem('locale');}
    }
    let cookie = this.cookieService.get('playerlogin');
    if (cookie == 'true') {

      this.store.dispatch(new playerActions.PlayerGetProfile());
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    }
    interval(5000 * 60).subscribe(x => {
      if (this.playerLoggedIn == true) {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      }
    });
    // this.counterService.counterStatus.subscribe((data) => {
    //   if (data)
    //     this.setCounterStatus(data.status);
    // });
    router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        // this.restartCounter();
      }
    });


    // window.onresize = (e) =>
    // {
    //     //ngZone.run will help to run change detection
    //     this.ngZone.run(() => {
    //         console.log("Width: " + window.innerWidth);
    //         console.log("Height: " + window.innerHeight);
    //     });
    // };
  }
  //@ViewChild(ResetPasswordComponent, { static: false }) resetPasswordcomp: ResetPasswordComponent;
  ngAfterViewInit() {
    this.jqueryResetTimer();
  }
  ngOnInit() {
    if (typeof window !== "undefined") {
    setTimeout(() => {
      if (sessionStorage.getItem("IphoneErrMsg") !== null) {
        sessionStorage.removeItem("IphoneErrMsg")
      }
    }, 6000);
  }
    // if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
    //   console.log(localStorage.getItem("getSession"))
    //   var sessionId = localStorage.getItem("getSession")

    //   this.modelpopup = false;
    //   alert("test1")
    // }
    // if (this.router.url == '/liveMatch') {

    // } else {
    //   this.webservice.send();
    // }
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            let data = this.playerLoggedIn;
            this.cookieService.set('playerlogin', data.toString());
            // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    // getRouterUrl(this.router.url);
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          if (cashierState.balance.success == true) {
          } else if (cashierState.balance.success == false) {
            if (cashierState.balance.code === "SESSION_EXPIRED"
              || cashierState.balance.code === 'INVALID_SESSION_TOKEN') {
                if (typeof window !== "undefined") {
              if (sessionStorage.getItem("IphoneErrMsg") == null) {
                this.modelpopup = true;
              }
              }
              if (this.selectedlanguege == 'EN') {
                this.sessionoutmessage = "Your session has expired. Please relogin";
              } else {
                this.sessionoutmessage = "جلسه شما به پایان رسیده است. لطفا دوباره وارد شوید";
              }
            }
          }
        }
        if (cashierState.balanceErrorRes) {
          if (typeof window !== "undefined") {
          if (sessionStorage.getItem("IphoneErrMsg") == null) {
            this.modelpopup = true;
          }
          }
          if (this.selectedlanguege == 'EN') {
            this.sessionoutmessage = "Your session has expired. Please relogin";
          } else {
            this.sessionoutmessage = "جلسه شما به پایان رسیده است. لطفا دوباره وارد شوید";
          }
        }
      }
    );
  }
  //   @HostListener('window:resize', ['$event'])
  // onResize(event){
  //   window.location.reload();
  //    console.log("Width: " + event.target.innerWidth);
  // }
  // @HostListener('window:mousemove') refreshUserState() {
  //   this.normalImg();
  // }
  // @HostListener('window:scroll') refreshUserStateOnScroll() {
  //   this.normalImg("SCROLL");
  // }

  // @HostListener('mouseover')
  // onmousehover() {
  //   this.normalImg("mouseover");
  // }
  // @HostListener('click') onclick() {
  //   this.normalImg("click");
  // }
  // @HostListener('mouseleave') onMouseLeave() {
  //   this.normalImg("mouseleave");
  // }

  closemodelpopup() {
    this.modelpopup = false;
    this.sessionoutmessage = "";
    this.MeightService.onLogOut()
  }
  // normalImg(eventName = "") {
  //   this.clearCounters();
  //   if (this.playerLoggedIn == true && this.isExecuteCounter) {
  //     this.counterService.setCurrentCounterStatus(true);
  //     this.counter = setTimeout(() => {
  //       this.modelpopup = true;
  //       this.sessionoutmessage = "Your session has expired. Please relogin";
  //     }, 3540000);
  //     this.logoutCounter = setTimeout(() => {
  //       this.clearCounters();
  //       this.closemodelpopup();
  //     }, 3600000);

  //   }

  // }
  // clearCounters() {
  //   if (this.counter) {
  //     clearTimeout(this.counter);
  //   }
  //   if (this.logoutCounter) {
  //     clearTimeout(this.logoutCounter);
  //   }
  //   this.counterService.setCurrentCounterStatus(false);
  // }
  initializeApp() {
    try {
      document.getElementById("preloader").style.display = "none"
    } catch (e) {
    }

  }
  jqueryResetTimer() {


    $("*").trigger("mousemove");

    $('#iframeTest').bind('mousemove keydown scroll', function () {
      if (this.isExecuteCounter) {
        this.normalImg();
      }

    }.bind(this));


  }

  // setCounterStatus(status) {
  //   this.isExecuteCounter = status;
  //   this.clearCounters();
  //   if (this.isExecuteCounter) {
  //     this.normalImg();
  //   }
  //   else {
  //     this.counterService.setCurrentCounterStatus(false);
  //   }
  // }

  // restartCounter() {
  //   this.clearCounters();
  //   this.isExecuteCounter = true;
  //   this.normalImg();
  // }
}
