import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@app/env';
import { Observable } from 'rxjs';
import { slotsData } from '../modules/slotsmodel/slotsdata';
import { Store } from '@ngrx/store';
import { AppState } from '../appstates/appState';
import { PlayerService } from './player/player.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class SlotsService {
  private _jsonURL = 'assets/videopoker.json';
  private _AllSlots = 'assets/allslots.json';
  private _TopSlots = 'assets/topslots.json';
  private _TableGames = 'assets/tablegames.json';
  private _Betsolutions = 'assets/Betsolutions.json';
  private _mrSlotty = 'assets/mrSlotty.json';
  private _topGames = 'assets/indiecasino.json'
  private _crashgame = 'assets/crashGames.json'
  private _livecasinoJson = 'assets/liveCasino.json';
  private _luckystrik = 'assets/luckstrik.json';
  private _shooting = 'assets/shooting.json';
  private _fishing = 'assets/mrslottyrrrcasino.json';
  private _vivoGamesJson = 'assets/VivoGames.json';
  constructor(private cookieService: CookieService,
    private playerService: PlayerService, private http: HttpClient,
    private store: Store<AppState>) {

  }
  //  private httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'siteid': environment.skinId,
  //     'wsession':this.cookieService.get('wsession'),
  //     'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
  //   })
  // };
  public getMrSlotty(): Observable<any> {
    return this.http.get(this._mrSlotty)
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  public AllSlotsJson(): Observable<any> {
    return this.http.get(this._AllSlots);
  }
  public TopSlotsJson(): Observable<any> {
    return this.http.get(this._TopSlots);
  }
  public TableGamesJson(): Observable<any> {
    return this.http.get(this._TableGames);
  }
  public BetsolutionsJson(): Observable<any> {
    return this.http.get(this._Betsolutions);
  }
  public topGamesJson(): Observable<any> {
    return this.http.get(this._topGames);
  }
  public getCrash(): Observable<any> {
    return this.http.get(this._crashgame);
  }
  liveCasinoGames(): Observable<any> {
    return this.http.get(this._livecasinoJson);
  }
  vivoGames(): Observable<any> {
    return this.http.get(this._vivoGamesJson);
  }
  public lukystrik(): Observable<any> {
    return this.http.get(this._luckystrik);
  }
  public shooting(): Observable<any> {
    return this.http.get(this._shooting);
  }
  public getfishing(): Observable<any> {
    return this.http.get(this._fishing);
  }


  httpClient(data)  { 
    var wSession = this.cookieService.get("wsession")
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.heblounch}/${wSession}`, this.playerService.httpOptions());
  }
  httpClientfun(): Observable<any> {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.hebfunlounch}`, this.playerService.httpBeforLoginOptions())
  }
  getSlottyUrl(data) {
    //  return this.http.post<slotsData>("https://www.rghvir43.com/home", data, this.playerService.httpOptions());
    return this.http.post<slotsData>(`${environment.appApi.baseUrl}${environment.appApi.allGamesUrl}`, data, this.playerService.httpOptions());
  }
  casinoBlackJackLunch() {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_blackajack}`, this.playerService.httpOptions());
  }
  casinoBaccaratLunch() {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_baccarat}`, this.playerService.httpOptions());
  }
  casinoEuropeanrouletteLunch() {
    try {
      return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_europeanroulette}`, this.playerService.httpOptions());
    } catch (e) {
    }
  }
  casinoOasispokerLunch() {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_Oasispoker}`, this.playerService.httpOptions());
  }



  /* adding for river poker */
  getCasinoGamesList(): Observable<any> {
    return this.http.post<any>(`${environment.appApi.casino.baseUrl}${environment.appApi.casino.getCasinoGames}`, {}, this.playerService.httpOptions());
  }

  launchCasinoGameUrl(endQueryTags) {
    return this.http.get(`${environment.appApi.casino.baseUrl}${environment.appApi.casino.launchCasinoGameBaseUri}` + endQueryTags, this.playerService.httpOptions());
  }

  indiecasino(data): Observable<any> {
    return this.http.get(
      // `${environment.appApi.baseUrl}${environment.appApi.indiecasino}/${data}`, this.playerService.httpOptionsIndie()
      `${environment.appApi.baseUrl}${environment.appApi.indiecasino}/${data}`, this.playerService.httpOptions()
    );
  }
  liveDealerServiceData(data): Observable<any> {
    // console.log( `${environment.appApi.baseUrl}${environment.appApi.livedealer}/${data}` )
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.ezugi}/${data}`)
  }
  vivogameApi(): Observable<any> {
    // return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptionsVivo());
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`, this.playerService.httpOptions());
  }
}
