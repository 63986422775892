import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/source/appstates/appState';
import { Subscription, interval } from 'rxjs';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { WithDrawsResponses } from 'src/app/source/modules/cashier/withdrawResponse';
import { CashOutInfo } from 'src/app/source/modules/cashier/withDraw';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Profile } from 'src/app/source/modules/player/profile';
import { CashierGetWithdrawSystems, CashierWithDrawRequest, CashierCancelWithDrawRequest } from 'src/app/source/appstates/cashierstates/cashierActions';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';

import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { take } from 'rxjs/internal/operators';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  otpRequesterrorMessage:string;
  private storeSub: Subscription;
  public profilestoreSub:Subscription;
  private cashierstoreSub:Subscription;
  private withDrawSystemstoreSub:Subscription;
  cashier: Balance;
  Profile:Profile;
  walleteInfo: WalletInfo[];
  loginSub: Subscription;
  formResponseMessage: string;
  phonenumber:string;
  requriedno:any;
  ouputno:any;
  payoutInfo: any;
  paymentmethodid:any;
  withdrawRequesterrormessage:string="";
  withdrawRequestSuccessmessage:string="";
  withdrawformResponseMessage: string = "";
  withDrawResponse: WithDrawsResponses[];
  cashoutInfo: CashOutInfo;
  paymentmethodname:string;
  selectedPayment: string = "";
  form: FormGroup;
  cashoutinfoerrormsg:string="";
  playerLoggedIn: boolean = false;
  // ADDED FOR RVR POKER
  rvrForm:FormGroup
  constructor(private store: Store<AppState>) {
 
   }

  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
this.withdrawRequestSuccessmessage="";
    this.store.dispatch(new cashierActions.ResetState());
    this.phonenumber = sessionStorage.getItem('playermobilenumber');
    this.requriedno =  this.phonenumber.substr(7, 10);
    this.ouputno = "*******"+this.requriedno;
    this.form = new FormGroup({
      "otp": new FormControl(null, Validators.required),
     // "phone": new FormControl( this.phonenumber, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
    });
  //  this.rvrForm= new FormGroup({
  //     "amount": this.form.value.amount
  //  });
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.Profile = playerState.profile;
          this.phonenumber = this.Profile.address.phone;
          this.requriedno =  this.phonenumber.substr(7, 10);
          this.ouputno = "*******"+this.requriedno;
        }
      }
    )
    this.withDrawSystemstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.withDrawSystem) {
          if(cashierState.withDrawSystem.success == true){
          if (cashierState.withDrawSystem.cashOutInfo) {
            this.cashoutinfoerrormsg = cashierState.withDrawSystem.cashOutInfo.errorMsg;
            this.cashoutInfo = cashierState.withDrawSystem.cashOutInfo;
            // this.paymentmethodid = cashierState.withDrawSystem.cashOutInfo.gateWays[0].id;
            // this.paymentmethodname = cashierState.withDrawSystem.cashOutInfo.gateWays[0].name;
            // this.onPaymentSelection(this.paymentmethodname, this.paymentmethodid)
            if (this.cashoutInfo.gateWays) {
              for (let payments of this.cashoutInfo.gateWays) {
                if (payments.selected) {
                  this.selectedPayment = payments.id;
                  break;
                }
              }
            }
          }
        } else if (cashierState.withDrawSystem.success == false) {
          this.formResponseMessage = cashierState.withDrawSystem.description;
        }
          if (this.cashoutInfo) {
            for (let frm of this.cashoutInfo.params) {
              if (frm.required) {
                this.form.addControl(frm.name, new FormControl('', Validators.required));
              } else {
                this.form.addControl(frm.name, new FormControl(''));
              }
            }

           
          }
        }
      })

      this.cashierstoreSub = this.store.select("cashierState").subscribe(
        (cashierState: CashierState) => {
          if (cashierState.withDrawRequestResponse) {
            if (cashierState.withDrawRequestResponse.success == true) {
              if(cashierState.withDrawRequestResponse.result.success == true){
                this.form.reset();
                this.withdrawRequestSuccessmessage = 'Withdrawal Request recorded successfully. Your request will be processed within 24 hours.';
                this.withdrawRequesterrormessage="";
               // this.payoutInfo = cashierState.withDrawRequestResponse; 
                //this.withdrawRequestSuccessmessage = cashierState.withDrawRequestResponse.description;
              }
            } else if (cashierState.withDrawRequestResponse.success == false) {
              this.withdrawRequestSuccessmessage="";
              //this.withdrawRequesterrormessage = 'Withdrawal request failed please contact admin';
              this.withdrawRequesterrormessage = cashierState.withDrawRequestResponse.description;
            }
          }
          if (cashierState.withDrawRequestResponseFail) {
            this.withdrawRequestSuccessmessage="";
            this.withdrawRequesterrormessage = cashierState.withDrawRequestResponseFail.message;
          }
        });
    this.store.dispatch(new CashierGetWithdrawSystems({ id: null }));
   
  }
  onPaymentSelection(paymentName, selected) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.form.reset();
    this.selectedPayment =  this.selectedPayment;
    this.store.dispatch(new CashierGetWithdrawSystems({ id: selected }));
  }
  onFormSubmit() {
    //this.form.value.paymentMethod=this.paymentmethodid;
    if (this.form.value.amount == undefined || this.form.value.amount <= 0) {
      this.withdrawformResponseMessage = 'Invalid amount';
    } else {
      
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    
    this.form.value.paymentMethod="304";
    // const data = {
    //   amount:this.form.value.amount,
    //   otp:this.form.value.otp,
    //   paymentMethod:this.form.value.paymentMethod
    // }
    // ADDED FOR REVIER POKER 
     // this.form.setValue({amount:this.form.value.amount});
      const data={
        amount:this.form.value.amount
      }
    this.store.dispatch(new CashierWithDrawRequest(data));
    }
  }
  onCancelWithdraw(refTxnID) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new CashierCancelWithDrawRequest({ "cashoutId": refTxnID }));
  }


  generateOTP($event) {
   this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.CashierOtpRequestResponse) {
          if (cashierState.CashierOtpRequestResponse.success == true) {
            this.otpRequesterrorMessage="";
            $event.target.disabled = true;
            let numbers = interval(1000);
            let takeFourNumbers = numbers.pipe(take(60));
            takeFourNumbers.subscribe(x => {
              $event.target.innerHTML = "Try again in" + " " +x;
              if (x == 59) {
                $event.target.disabled = false;
                $event.target.innerHTML = "Resend OTP";
              }
            });
          }else if(cashierState.CashierOtpRequestResponse.success == false){
            this.otpRequesterrorMessage=cashierState.CashierOtpRequestResponse.description;
          }
        }
        
        if (cashierState.CashierOtpRequestErrorResponse) {
          this.otpRequesterrorMessage = cashierState.CashierOtpRequestErrorResponse.message;
        }
      }
    )
    this.store.dispatch(new cashierActions.CashierWithdrawOTPRequest({ language: 'en', mobile: this.phonenumber}));
  }
ngOnDestroy(){
  if (this.storeSub) {
    this.storeSub.unsubscribe();
  }
  if (this.profilestoreSub) {
    this.profilestoreSub.unsubscribe();
  }
  if (this.cashierstoreSub) {
    this.cashierstoreSub.unsubscribe();
  }
  if (this.withDrawSystemstoreSub) {
    this.withDrawSystemstoreSub.unsubscribe();
  }
}
  
}
