import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstates/appState';
import { LoginState } from '../../appstates/loginstates/loginState';
import { environment } from '@app/env';
import { appConstants } from '../../constants/appconstants';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
}) export class CasinoGamesService {
  private _CasinojsonURL = 'assets/headerCasinoGames.json';
  playerLoggedIn: boolean = false;
  constructor(private cookieService: CookieService,private httpClient: HttpClient, private store: Store<AppState>) {
    this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState && loginState.playerLoggedIn)
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
      });

  }
  public getHeaderCasinoGames(): Observable<any> {
    return this.httpClient.get(this._CasinojsonURL);
  }

  
  
  httpOptions() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid': environment.skinId
      })
    };
    if (this.playerLoggedIn)
      options.headers.append('wsession', this.cookieService.get('wsession'))
    return options;
  }
  launchLiveCasino() { 
    return this.httpClient.get<any>(`${environment.appApi.casino.liveCasinoUrl}`, this.httpOptions());
    
  }
  
  launchFreshDeck() {
    return this.httpClient.get<any>(`${environment.appApi.casino.freshdeck}`, this.httpOptions());
    
    }

}