import { Component, OnInit, HostListener, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { LivedealerService } from 'src/app/source/services/livedealer/livedealer.service';
import { MeightService } from 'src/app/source/services/meightservice/meight.service';
import { meightObeject } from 'src/app/source/modules/meight/meightmodal';
import { CockfightService } from 'src/app/source/services/cockfight/cockfight.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { Subscription } from 'rxjs';
import { RegisterComponent } from '../register/register.component';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { Router, ActivatedRoute ,NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
import { appConf } from 'src/app/source/config/app.config';
import { CommonUtilService } from 'src/app/source/services/common/commonutil.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/source/services/login/login.service';
import { appConstants } from 'src/app/source/constants/appconstants';
import { TBankAccountInfo } from 'src/app/source/modules/cashier/bankAccountInfo';

// import { Console } from 'console';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  private loginstoreSub: Subscription; 
  private tokenstoreSub: Subscription;
  private profilestoreSub: Subscription;
  private storeSub: Subscription;
  logPop: boolean = false;
  playerLoggedIn: boolean = false;
  Login: boolean = true;
  dropMenu: boolean = false;
  openUpdate: boolean = false;
  appLinks: any = appConstants.appDownloadLinks;
  fieldTextType: boolean;
  text: any;
  reID: any;
  reType: any;
  playertype: any;
  balance: any;
  bankAccountInfos: TBankAccountInfo[];
  cashier: Balance;
  walleteInfo: WalletInfo[];

  playerbonus: any;
  profile: Profile;
  errorMessage: string;
  loginloader: boolean = false;
  form: FormGroup;
  profileName: string;
  private closeSub: Subscription;
  meightObeject: meightObeject;
  token: any;
  urlof: string;
  navigationsuccessPop: boolean = false;
  gamelunchmessage: string = "";
  oneClick: boolean = false;
  btnDisabled: boolean = true;
  logName: any;
  sprtUrl: string;
  SprtsToken: any;
  captchNum: any;
  captchaRobot: string;
  email: string;
  redirectLoader: boolean = false;
  private allGamesLunchStore: Subscription;
  selectedlanguege: any;
  isMobile: boolean = false;
  /* ADDED FOR RIVER POKER */
  headerCasinoGamesList: any = [];
  showNewpopup: boolean = false;
  showNewpopup1: boolean = false;
  newForm: FormGroup;
  newForm1: FormGroup;
  conformPassTxt: string = "";
  showMoreFields: boolean = false;
  loginPlayerName: any;
  loginPlayerPass: any;
  vipPoints: any = "In progress";
  showPasswordExpairPop: boolean = false;
  passwordExpairMsg: string = "";
  newFieldTextType: boolean;
  successPayload: any;
  bodyPayload: any;
  chpBalance: String;
  usdBalance: string;
  showAvatarList: boolean = false;
  avatars = [];
  selectedAvatarId: any;
  playerAvatar: any;
  avtarNotset: string = 'assets/avatar/default_avtar.png';
  defaultImageUrl: string = 'assets/avatar/default.svg';
  mainmenu: boolean = false;
  showforgotPassword:boolean = false
  @ViewChild(UpdateProfileComponent, { static: false }) updatePro: UpdateProfileComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  @ViewChild(ChangePasswordComponent, { static: false }) changepasswords: ChangePasswordComponent;
  @ViewChild(RegisterComponent, { static: false }) register: RegisterComponent;
  @ViewChild(ResetPasswordComponent, { static: false }) resetPasswordcomp: ResetPasswordComponent;
  gamelunchurl: any;
  urlSafe: SafeResourceUrl;
  mrgameLunchUrl: any;
  wSession: any;
  urlSplit: any;
  responseData: any;
  registerPlayerData: boolean = false;
  successBtn: boolean = false;
  failBtn: boolean = false;
  playerSessionId: any;
  headerMsg: null;
  waittingLoader: boolean = false;
  errorsuccessPop: boolean = false;
  urlTokenForgot: any;
  urlEventTypeForgot: any;
  forgotPasswordMsg: string;
  constructor(private cookieService: CookieService, private Playerservice: PlayerService, private loginSer: LoginService, public translate: TranslateService, public sanitizer: DomSanitizer, private route: ActivatedRoute, public router: Router,
    private CommondataService: CommondataService,
    private loginService: LoginService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private Cockfightservice: CockfightService, private meightservice: MeightService,
    private store: Store<appState.AppState>, private Livedealerservice: LivedealerService,
    private casinoGamesService: CasinoGamesService, private commonUtilSer: CommonUtilService) {
      if (typeof window !== "undefined") {
    const queryString = window.location.href;
    const urlParams = new URL(queryString).searchParams;
    const product = urlParams.get('type');
    const productID = urlParams.get('token');
    const urlsid = urlParams.get("uniqueId");
    const urlsidws = urlParams.get("depositId");

    let twoFactor = queryString.split('&')[1];
    // let twoFAFeature  = queryString.split('&twoFAFeature=')[1];
    const twoFAFeature = urlParams.get('twoFAFeature');
    const wsession = urlParams.get('wsession');
    if (urlsid !== null || urlsidws !== null || wsession) {
      var Wesseion
      if (wsession) {
        Wesseion = wsession
      } else if (urlsid) {
        Wesseion = urlsid
      } else if (urlsidws) {
        Wesseion = urlsidws
      }
      let TwofactorValue = localStorage.getItem('twofactor')
      let ShowtoFactorPopup = localStorage.getItem('ShowtoFactorPopup')
      sessionStorage.setItem("uniqueId", 'ID')
      this.bodyPayload = {
        success: true,
        twoFactor: TwofactorValue,
        sessionId: Wesseion,
        showTwoFactorPopup: ShowtoFactorPopup,
        twoFAFeature: twoFAFeature
      }; 
      this.store.dispatch(new loginActions.LoginSuccess(this.bodyPayload));
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { 
      this.isMobile = true;
    } else { 
      this.isMobile = false;
    } 
    if (this.router.url.indexOf('Sportsm') != -1 && this.router.url.indexOf('wsession') != -1) {
      let url = this.router.url;
      this.urlSplit = url.split('wsession=').pop() 
      sessionStorage.setItem("webSocketSessionId", this.urlSplit)
    } 
    this.captchaRobot = null;
    this.token = sessionStorage.getItem('accessToken');    
    this.generateCaptchaReset();
    // this.casinoGamesService.getHeaderCasinoGames()
    //   .subscribe((gamesList) => {
    //     this.headerCasinoGamesList = gamesList["casinoGamesList"]; 
    //   });
    translate.addLangs(['EN', 'FA']);
    //translate.setDefaultLang('es');
    let lang = localStorage.getItem('locale');
    if (lang === 'FA' || lang === "EN") {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/FA|EN/) ? browserLang : 'FA');
    } else {
      localStorage.setItem('locale', 'FA');
      sessionStorage.setItem('locale', 'FA');
      translate.setDefaultLang('FA');
    }
    sessionStorage.setItem("locale",localStorage.getItem('locale'))
    this.selectedlanguege = localStorage.getItem('locale');
    if ($(window).width() < 992) { 
     
      this.mainmenu = true;
    } else {
      this.mainmenu = false;
    }
  }
  }


  // autoLogin() {
  //   if (this.router.url.indexOf('?token=') !== -1) {
  //     let routUrl = window.location.search;
  //     let splitUname = routUrl.split('?token=')
  //     let pToken = splitUname[1];
  //     const body = {
  //       "token": pToken,
  //       "face": 'rp1'
  //     }
  //     this.loginSer.verifyAccount(body).subscribe(res => this.playerRes(res))
  //   }
  // }
  // playerRes(res) {
  //   sessionStorage.clear();
  //   if (res.success == false) {
  //     this.registerPlayerData = true;
  //     this.responseData = res.description;
  //     this.failBtn = true;
  //     this.successBtn = false;
  //   } else {
  //     this.registerPlayerData = true;
  //     this.responseData = "Verified Successfully"
  //     this.failBtn = false;
  //     this.successBtn = true;
  //     this.playerSessionId = res.loginResponse;
  //     sessionStorage.setItem("w", this.playerSessionId)
  //   }
  // }
  // closePop() {
  //   this.registerPlayerData = false;
  //   this.logPop = false;
  // }
  // closePopSuccess() {
  //   this.registerPlayerData = false;
  //   this.store.dispatch(new loginActions.ResetState());
  //   this.store.dispatch(new loginActions.LoginSuccess(this.playerSessionId));
  // }

  generateCaptchaReset() {
    this.loginService.onGenerateCaptchaStart().subscribe(data => this.gotCaptcha(data))
  }
  gotCaptcha(data) { 
    this.captchNum = data;
  }
 
  ngOnInit() {  
    if (typeof window !== "undefined") {
    if (window.location.pathname == "/passwordupdate") {
      this.showNewpopup = true;
      this.showMoreFields = true;
    }
    const queryString = window.location.href; 
    const urlParams = new URL(queryString).searchParams;
    const productID = urlParams.get('token'); 
    const urlsid = urlParams.get("uniqueId");
    const urlsidws = urlParams.get("depositId");

    let twoFactor = queryString.split('&')[1];;
    const wsession = urlParams.get('wsession');


    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
 
          if (loginState.loginResponse.success === true) { 
            localStorage.removeItem("Referrer")
            if (loginState.loginResponse.description === "Email confirmation required") {
              this.errorsuccessPop = true;
              if (localStorage.getItem('locale') == 'FA') {
                this.errorMessage = "لطفا لینک ارسالی به ایمیل خود را تایید بفرمایید ، سپس وارد شوید"
              } else {
                this.errorMessage = "Please verify your email and login"
              }
            }


            if (twoFactor != null || twoFactor != undefined) {
              let twoFactorname = twoFactor.split("twoFactor=")[1]
              // localStorage.setItem("twofactor", twoFactorname)
              if (twoFactorname) {
                setTimeout(() => {
                  this.router.navigate(['/twofactor'])
                }, 1000)
              } else {
                this.router.navigate(['/home'])
              }
            } else {
              localStorage.removeItem("twoFactor")
            }
            var EmailId = localStorage.getItem("EmailId")
            if (EmailId) {
              this.router.navigate(['/twofactor'])
            }
             if (loginState.loginResponse.description == "LOGIN_TMP_PASSWORD") {
              this.changePassword();
            }
          } else if (loginState.loginResponse.success === false) {
            this.errorsuccessPop = true;
            if (loginState.loginResponse.description == "Password Expired") {
              this.errorMessage = "Its been a while you changed your password, please update with new password."
            } else {
              this.errorMessage = loginState.loginResponse.description;
              this.errorsuccessPop = true;
            }
            if (loginState.loginResponse.description == "Login forbidden. User is pending") {
              this.errorMessage = "Please verify your e-mail to activate your account"
            }
            if (this.selectedlanguege == 'FA') { 
              if (this.errorMessage == "Please verify your e-mail to activate your account") {
                this.errorMessage = "لطفا لینک ارسالی به ایمیل خود را تایید بفرمایید ، سپس وارد شوید"
              }
            }
            if (this.selectedlanguege == 'FA') {
              if (loginState.loginResponse.description == "Invalid Login or Password") {
                this.errorMessage = "رمز عبور یا نام کاربری صحیح نمی باشد"
              }
            } 
            // if(loginState.loginResponse.description == "Email confirmation required" ){
            //   this.registerPlayerData = true;
            //   this.headerMsg = loginState.loginResponse.description;
            //   this.responseData = "Please check your email or contact support";
            //   this.failBtn = true;
            // }
            this.errmessagenull();
            this.loginloader = false;
            this.generateCaptchaReset();
            //this.toastr.error(loginState.loginResponse.description);
          }
        }
        if (loginState.loginErrorResponse) {
          this.errorMessage = loginState.loginErrorResponse.message;
          this.errorsuccessPop = true;
          this.errmessagenull();
          this.loginloader = false;
        }
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn == true) {

            // this.store.dispatch(new cashierActions.CashierGetBankAccount());

            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            // this.form.reset();
            this.closeLogPop();
          }
        }
      }
    )
    let EmailVerifyL: any = window.location.search.split('&description=')[1] 
    if (EmailVerifyL) {
      if (EmailVerifyL == "emailVerifiedSuccessfully") {
        this.errorsuccessPop = true
        if (localStorage.getItem('locale') == 'FA') {
          this.errorMessage = "ایمیل با موفقیت تأیید شد."
        } else {
          this.errorMessage = "Email Verified Successfully"
        }

        setTimeout(() => {
          this.router.navigate(['/home'])
        }, 800)
      }
    }  
    const queryStringForgot = window.location.href; 
    const urlParamsForgot = new URL(queryStringForgot).searchParams;
    this.urlTokenForgot = urlParamsForgot.get('token');
    this.urlEventTypeForgot = urlParamsForgot.get('eventType'); 
    let paramString = queryString.split('?')[1]; 
    if (paramString != null || paramString != undefined) {
      let refferalname = paramString.split("ID=")[1] 
      localStorage.setItem("Referrer", refferalname)
      if (refferalname) {
        setTimeout(() => {
          this.router.navigate(['/home'])
          this.registrationPopup();
        }, 1000)
      }
    } else {
      localStorage.removeItem("Referrer")
    } 
    if (window.location.pathname == "/activate") {
      if (twoFactor == 'eventType=twoFactor') {
        let payload = {
          "twoFactorToken": productID,
        }
        this.Playerservice.twofactorOptin(payload).subscribe(data => {
          // this.Playerservice.verifyAccount(payload).subscribe(data => {
          this.router.navigate(['/home'])
          if (data['success']) {
            this.errorsuccessPop = true;
            if (localStorage.getItem('locale') == 'FA') {
              this.errorMessage = "ایمیل با موفقیت تأیید شد"
            } else {
              this.errorMessage = "E-mail verified successfully. Happy Playing !!!";
            }
            // this.errorMessage = "E-mail verified successfully. Happy Playing !!!";

          } else if (data['success'] == false) {
            this.errorsuccessPop = true;

            this.errorMessage = data.description
          }

        })
      }
      if (twoFactor == 'eventType=registration') {
        let payload = {
          "token": productID,
          "face": "rp1",
          "language": localStorage.getItem('locale')
        }
        this.Playerservice.verifyAccount(payload).subscribe(data => {
          this.router.navigate(['/home'])
          if (data.description == "INVALID_INPUT_DATA" || data['success'] == true) {
            this.errorsuccessPop = true;
            if (localStorage.getItem('locale') == 'FA') {
              this.errorMessage = "ایمیل با موفقیت تأیید شد"
            } else {
              this.errorMessage = "E-mail verified successfully. Happy Playing !!!";
            }


          } else if (data['success'] == false) {
            this.errorsuccessPop = true;
            this.errorMessage = data.description
          }
        })
      }    
      if(twoFactor == "eventType=forgotPassword"){
        this.showNewpopup1 = true; 
          }

    }
  }
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.profileName = this.profile.login;
          this.getPlayerAvatar();
        }
      }
    )
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {     
        if (cashierState.balance) {
          if (cashierState.balance.success == true) {
            this.loginloader = false;
            this.cashier = cashierState.balance;
            this.walleteInfo = cashierState.balance.values;
            let hasUSD = false;
            let hasCHP = false;
            for (let i = 0; i < this.walleteInfo.length; i++) {
              if (typeof window !== "undefined") {
              localStorage.removeItem("USD")
              localStorage.removeItem("CHP")
              localStorage.removeItem("TWO")
              }
              if (this.walleteInfo[i].wallet.name == "USD") {
                var usd = Number(this.walleteInfo[i].cash.value) 
                this.usdBalance = (this.walleteInfo[i].symbol + " " + Number(usd)) 
                if (typeof window !== "undefined") {
                localStorage.setItem("USD", "USD")
              }
              hasUSD = true;
              } 
              if (this.walleteInfo[i].wallet.name === "CHP") {
                var chp = Number(this.walleteInfo[i].cash.value); 
                this.chpBalance = Number(chp).toLocaleString();
                if (typeof window !== "undefined") {
                localStorage.setItem("CHP", "CHP")
                }
                hasCHP = true;

              }
              if (typeof window !== "undefined") {
              if (hasUSD && hasCHP) {
                localStorage.removeItem("USD")
                localStorage.removeItem("CHP")
                localStorage.setItem("TWO", "TWO");
              }
            }
            }
            // this.loadBalanceDetails(this.walleteInfo);
            let vipPointsblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(this.walleteInfo, appConf.vipPointsKey, "cash");
            this.vipPoints = vipPointsblc.toString().split(".")[0];
            for (let wallet of this.walleteInfo) {
              if (wallet.preferred === true) {
                let totalbalance = wallet.cash.value + wallet.bonus.value;
                if (typeof window !== "undefined") {
                this.balance = Number(totalbalance.toString().split(".")[0]).toLocaleString("en-US"); 
                }
                // this.balance = this.commonUtilSer.getRoundedOffNumber(this.balance);
                this.playertype = wallet.wallet.name;
                this.playerbonus = wallet.bonus.value;
              } else {
              }
            }
          } else if (cashierState.balance.success == false) {
            this.loginloader = false;
          }
        }

      }
    )
    if (typeof window !== "undefined") {
    this.profileName = sessionStorage.getItem('profile');
    }
    this.store.dispatch(new loginActions.ResetState());
    this.form = new FormGroup({
      "login": new FormControl('', Validators.required),
      // "login": new FormControl('', [Validators.required, Validators.minLength(4)]),
      // Validators.pattern('[a-zA-Z0-9]*')
      // "password": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
      "password": new FormControl(null, Validators.required),
      "captcha": new FormControl(null, Validators.required),
      // "gReCaptchaResponse": new FormControl(null)
    })


    this.newForm = new FormGroup({
      "usernaemOne": new FormControl(null, !this.showMoreFields ? null : Validators.required),
      "passwordOldOne": new FormControl(null, !this.showMoreFields ? null : Validators.required),
      "passwordOne": new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)]),
      "passwordTwo": new FormControl(null, [Validators.required, Validators.minLength(8)]),
    })
    this.newForm1 = new FormGroup({
      // "usernaemOne": new FormControl(null, !this.showMoreFields ? null : Validators.required),
      // "passwordOldOne": new FormControl(null, !this.showMoreFields ? null : Validators.required),
      "passwordOne": new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)]),
      // "passwordTwo": new FormControl(null, [Validators.required, Validators.minLength(8)]),
    })
    /************************** Jquery start *****************************/
    $(document).ready(function () {



      /************************** Menu open close code start *****************************/
      var docHeight = $(document).height();


      $('.drop_Menu').click(function () {
        $('.drop_Menu').hide();
      });

      $(".navUL li").click(function () {
        $(".navUL li").removeClass("active");
        $(this).addClass("active");
      });
      $('body').click(function () {
        $('.goSide').removeClass("active");
      })

      $('.menu_lines').click(function () {
        $('.navDiv').addClass('active');
        $('.menu_ul li').click(function () {
          $('.navDiv').removeClass('active');
        });
      });
      if ($(window).width() > 767) { 
        
        $('.menuClose').hide();
      } else { 
        // window.scroll(0,150)
        $('.menuClose').show();
        $('.menu_b_Light').hide();
      }
    });
    if ($(window).width() < 767) {
      $('.navDiv').addClass('sm_menu');
    }
    $(window).scroll(function () {
      // if ($(window).width() > 767) {
      //   $('.menuClose').hide();
      //   if ($(this).scrollTop() > 100) {

      //     $('.menu_lines').fadeIn('slow');
      //     $('.navDiv').addClass('goSide');
      //     $('.menuClose').show();
      //     $('.menu_b_Light').hide();

      //     $('.menu_lines').click(function () {
      //       $('.navDiv').addClass('active');
      //       $('.navDiv li').click(function () {
      //         $('.navDiv').removeClass('active');
      //       });
      //     });

      //   } else {
      //     $('.navDiv').removeClass('goSide');
      //     $('.menu_lines').fadeOut('slow');
      //     $('.nav_head').fadeIn('slow');
      //     $('.menu_b_Light').show();
      //   }
      // }

    });

    /************************** Jquery end *****************************/

    // if (this.router.url == '/casinoh5') {
    //   this.launchFreshDeck();
    //   // setTimeout(() => {
    //   //   alert("urlhit")
    //   // }, 500);
    // }
    if (typeof window !== "undefined") {
    if (this.router.url == '/live-casino2') {
      setTimeout(() => {
        this.launchFreshDeck();
      }, 500);
    }
    if (this.router.url == '/live-casino') {
      this.router.navigate(['/liveDealer_2'])
    }
  }
    // if (this.router.url == '/live-casino') {
    //   setTimeout(() => {
    //     if (this.playerLoggedIn == true) {
    //       this.launchLiveCasino();
    //     } else {
    //       this.openLoginDialog();
    //     }
    //   }, 2000);

    // }
    if (this.router.url == '/jetx') {
      setTimeout(() => {
        if (this.playerLoggedIn == true) {
          this.launchJetx();
        } else {
          this.openLoginDialog();
        }
      }, 2000);
    }

    if (this.router.url == '/register') {
      setTimeout(() => {
        if (this.playerLoggedIn == false && this.selectedlanguege) {
          this.registrationPopup();
        } else {
        }
      }, 5000);
    }
    if (this.router.url == '/Sportsm') {
      setTimeout(() => {
        if (this.playerLoggedIn == true) {
          this.sportsData();
        } else {
          this.openLoginDialog();
        }
      }, 2000);
    }
    this.allGamesLunchStore = this.store.select("loginState").subscribe(

      (loginState: LoginState) => {
        if (loginState.allGamesLunch) {
          if (loginState.allGamesLunch.status == 200) {
            this.mrgameLunchUrl = loginState.allGamesLunch.gameUrl;
            if (this.mrgameLunchUrl) {
              if (this.oneClick == true) {
                this.oneClick = false;
                this.slotsGameLunch();
              }
            }

          } else if (loginState.allGamesLunch.status != 200) {
            this.navigationsuccessPop = true;
            if (loginState.allGamesLunch.description) {
              this.gamelunchmessage = loginState.allGamesLunch.description;
            }
            else {
              this.gamelunchmessage = "Unknown Error"
            }
          }
        }
        if (loginState.allGamesLunchError) {
          this.navigationsuccessPop = true;
          if (loginState.allGamesLunchError.message) {
            this.gamelunchmessage = loginState.allGamesLunchError.message;
          } else {
            this.gamelunchmessage = "Unknown Error"
          }
        }
      }
    )

    if (this.playerLoggedIn !== true) {
     if (typeof window !== "undefined") {
      if (window.location.pathname === '/balance') {
        this.router.navigate(['/home']);
      }
     }
    }
  }
  // ngAfterViewChecked() {
  //   console.clear()
  // }
forgotPassword(){
  let body = {
    "token": this.urlTokenForgot ,
    "password": this.newForm1.value.passwordOne,
    "eventType": this.urlEventTypeForgot
  }
this.loginloader = true; 
if(sessionStorage.getItem('redirect') == "/activate"){
  sessionStorage.setItem('redirect', "/home")
} 
  this.Playerservice.resetpasswordNew(body).subscribe(data=>{
  // this.Playerservice.onResetPassword(body).subscribe(data=>{
if(data.success){
  if(data.sessionId){
this.loginloader = false;
this.showNewpopup1 = false;
    this.bodyPayload = {
      showTwoFactorPopup: data.showTwoFactorPopup,
      success: data.success,
      sessionId: data.sessionId,
      twoFAFeature: data.twoFAFeature      ,
      twoFactor:data.twoFactor 
    };
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LoginSuccess(this.bodyPayload)) 
this.showforgotPassword = true;
if (typeof window !== "undefined") {
  if (localStorage.getItem('locale') == 'FA') {
    this.forgotPasswordMsg = "رمز عبور با موفقیت تغییر کرد موفق باشید"
  }else{
    this.forgotPasswordMsg = data.description + ". Happy Playing !!!"
  }
}
  }
}else{
  this.loginloader = false;
  this.showNewpopup1 = false;
  this.showforgotPassword = true;
  this.forgotPasswordMsg = data.description
}
  })
}
forgotPasswordNew(){
  window.location.reload() 
  this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  this.router.navigate(['/home']);
  this.showforgotPassword = false;
this.forgotPasswordMsg = "";
}
  getAvatarList() {
    this.Playerservice.getAvatarListApi().subscribe(data => { this.avatarList(data) });
  }
  avatarList(avatars: any) {
    this.avatars = avatars;
  }
  setAvatar(avatarId) {
    this.selectedAvatarId = avatarId;
  }
  // customAvator(): SafeResourceUrl {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + imageData);
  // }
  getSanitizedImageUrl(imageData: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + imageData);
  }

  getPlayersAvatars(): SafeResourceUrl {
    if (this.selectedAvatarId) {
      const lastThreeChars = this.selectedAvatarId.slice(-3);
      const totalAvatars = this.avatars;
      for (const avatar of totalAvatars) {
        if (avatar.id.endsWith(lastThreeChars)) {
          return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + avatar.imageData);
        }
      }
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.playerAvatar);
  }

  getPlayerAvatar() {
    this.Playerservice.getPlayerAvatar().subscribe(
      (response) => {
        if (response["success"]) {
          this.playerAvatar = `data:image/png;base64,${response["imageData"]}`;
        } else {
          this.playerAvatar = this.avtarNotset;
        }
      },
      (err) => {
        this.playerAvatar = this.defaultImageUrl;
      }
    );
  }
  setSelectedAvatar() {
    this.loginloader = true;
    let payload = {
      "avatar_id": this.selectedAvatarId
    }
    this.Playerservice.setAvatarListApi(payload).subscribe(data => {
      if (data["status"] === "avatar set successfully") {
        this.getPlayerAvatar();
        this.loginloader = false;
        this.showAvatarList = false;
      } else {
        this.loginloader = false;
      }
    });
  }

  openAvatarList() {
    this.showAvatarList = !this.showAvatarList;
    if (this.showAvatarList) {
      this.getAvatarList();
    }
  }
  
  sportsRedirect() {
    if (this.playerLoggedIn == true) {
      this.sportsData();
      // this.router.navigate(['/MT_page']);
      this.redirectLoader = true;
    } else {
      this.openLoginDialog();
    }
  }
  sportsData() {
    if (this.playerLoggedIn) {
      this.Playerservice.onPlayerGetProfile().subscribe(data => this.getSportData(data))
    } else {
      this.openLoginDialog();
    }
  }
  getSportData(data) { 
    this.wSession = this.cookieService.get('wsession')
    this.logName = { loginName: data.login, webSocketSessionId: this.wSession };
    this.Playerservice.sportsDataServe(this.logName).subscribe(data => this.launchSport(data, this.logName))
    this.redirectLoader = false;
  }
  launchSport(data, logName) { 
    this.sprtUrl = data.data.address;
    this.SprtsToken = data.data.token; 
    // this.Playerservice.sportsSettionTkn(logName.loginName, this.SprtsToken).subscribe( data => { console.log( data ) } )
    // alert(" check Nav 420 launch data ")
    if (typeof window !== "undefined") {
    return window.open(this.sprtUrl, '_self');
    }
  }
  slotsGameLunch() {
    this.gamelunchurl = this.mrgameLunchUrl;
    if (typeof window !== "undefined") {
    return window.open(this.gamelunchurl, '_self');
    }
  }
  menuClose() {
    $('.navDiv').removeClass('active');
    // if ($(window).width() < 992) {
    //   $('.sm_menu').removeClass('active');
    // }
  }

  login() {
    this.playerLoggedIn = true;
    this.Login = false;
  }
  proDrop() {
    this.dropMenu = true;
  }
  dropHide() {
    this.dropMenu = false;
  }
  onClickLogout() {
    if (typeof window !== "undefined") {
    localStorage.clear() }
    // this.store.dispatch(new loginActions.ResetState());
    // this.store.dispatch(new playerActions.ResetState());
    this.meightservice.onLogOut()
    this.store.dispatch(new loginActions.ResetState());
    // this.store.dispatch(new tokenActions.ResetState());
    this.store.dispatch(new cashierActions.ResetState());
    this.store.dispatch(new loginActions.LogoutStart());
    if (typeof window !== "undefined") {
    this.router.navigate(['/home']);
    }
    // setTimeout(() => {  
    //   window.location.reload()
    // }, 1000)
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  updateProfile() {
    this.updatePro.openUpdatePop();
  }
  changePassword() {
    this.changepasswords.openPasswordPop();
  }
  forgotPopup() {
    this.passwords.openForgotPasswordPop();
  }
  openLoginDialog() {
    if (typeof window !== "undefined") {
    sessionStorage.removeItem('myPopup')
    sessionStorage.removeItem('myPopupTwo')
    this.logPop = true;
    }
  }
  closeLogPop() {
    this.form.reset();
    this.btnDisabled = true;
    this.loginloader = false;
    this.logPop = false;
  }
  registrationPopup() {
    this.register.openRegisterPop()
    this.store.dispatch(new loginActions.ResetState());
  }
  resetPassword() {
    this.resetPasswordcomp.openrestpassword();
  }
  resetPassword1() {
    this.resetPasswordcomp.openrestpassword1();
  }
  resolved(caotcgaRes: string) {
    this.btnDisabled = false; 
    // if(caotcgaRes == null ) {
    this.captchaRobot = caotcgaRes;
    // }else{
    //   this.btnDisabled = true;
    // } 
  }
  onSubmit() {
    if (typeof window !== "undefined") {
    sessionStorage.clear();
    sessionStorage.removeItem('accessToken');
    this.cookieService.deleteAll();
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('playermobilenumber');
    }
    this.loginPlayerName = this.form.value.login;
    this.loginPlayerPass = this.form.value.password;
    this.loginloader = true;

    this.form.value.gReCaptchaResponse = this.captchaRobot;
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LoginStart(this.form.value));
    this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.code == "PASSWORD_EXPIRED" && loginState.loginResponse.success == false) {
            this.showPasswordExpairPop = true;
            if (this.selectedlanguege == 'EN') {
              this.passwordExpairMsg = 'It’s been while you changed password please update with new password'
            } else {
              this.passwordExpairMsg = 'مدت زمانی است که رمز عبور را تغییر داده اید، لطفاً با رمز عبور جدید به روز کنید'
            }
          } else {
            this.showPasswordExpairPop = false;
          }
        }
      })
  }
  reCaptcha: any;
  testReCaptcha(data) { 
    this.reCaptcha = JSON.parse(data.error.text);
  }
  @HostListener("click", ["$event"])
  public onClick(event: any) {
    this.dropMenu = false;
    $('.CountriesOpen').hide();
    $('.country_List').hide();
    $('.LeaguesOpen').hide();
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
  if (typeof window !== "undefined") {
    if (window.pageYOffset > 150) {
      let Fixit = document.getElementById('mySidenav');
      Fixit.classList.add('fixMenus'); 
    } else {  
      let Fixit = document.getElementById('mySidenav');
      Fixit.classList.remove('fixMenus');
      // const scrollingElement = document.getElementById('scrolling');
      // if (scrollingElement) {
      //   scrollingElement.style.marginTop = "150px";
      // }
    }
  }
  }
  liveDealer() {
    if (this.playerLoggedIn) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (typeof window !== "undefined") {
      let logiName = sessionStorage.getItem('profile');
      this.Livedealerservice.liveDealerServiceData(logiName).subscribe(liveDealerdata => {
        this.liveDealerunch(liveDealerdata);
      });
    }
    } else {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }

  liveDealerunch(data) {
    if (data.EZUGI_GAME_LAUNCH_URL) {
      let ezugiLaunch = data.EZUGI_GAME_LAUNCH_URL;
      if (typeof window !== "undefined") {
      window.open(ezugiLaunch, '_self');
      }
    } else {

      this.gamelunchmessage = "No response";
      this.navigationsuccessPop = true;
    }

  }

  //m8 start

  meightBet() {

    if (this.playerLoggedIn) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      if (typeof window !== "undefined") {
      let logiName = sessionStorage.getItem('profile');
      this.meightservice.meightServiceData(logiName).subscribe(meightdata => {
        this.meightLunch(meightdata);
      });
    }
    } else {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  meightLunch(data) {
    if (typeof window !== "undefined") {
    let ua = window.navigator.userAgent.toLowerCase();
    this.meightObeject = data;
    if (this.meightObeject.STATUS == 'FAILED') {
      this.gamelunchmessage = this.meightObeject.STATUS;
      this.navigationsuccessPop = true;
    } else {
      if (ua.match(/ipad/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/iphone/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/android/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/chrome/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/firefox/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/webkit/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/gecko/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/opera/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      return null
    }
  }
  }

  cockFight() {
    if (this.playerLoggedIn) {
      this.token = sessionStorage.getItem('accessToken');
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      if (typeof window !== "undefined") {
      let logiName = sessionStorage.getItem('profile');
      this.Cockfightservice.cockFightServiceData(logiName).subscribe(cockfightdata => {
        this.cockFightLunch(cockfightdata);
      });
      }
    } else {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  cockFightLunch(data) {
    if (typeof window !== "undefined") {
    let sabongLunch = data.SABONG_GAME_LAUNCH_URL;
    window.open(sabongLunch, '_self');
    }
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()
  }


  navigationPopClose() {
    this.navigationsuccessPop = false;
    if (this.playerLoggedIn == true) {
      if (typeof window !== "undefined") {
      let token = sessionStorage.getItem('accessToken');
      if (token == null) {
        if (this.router.url == '/Sportsm') {
          if (this.gamelunchmessage == 'System encountered an error please contact help desk') {
            this.router.navigate(['/home']);
            this.gamelunchmessage = "";
            return;
          }
        }
      }
    }
    }
    this.gamelunchmessage = "";



  }

  errorPopClose() {
    this.errorsuccessPop = false;
    if (this.errorMessage == "Its been a while you changed your password, please update with new password.") {
      this.logPop = false;
      this.showNewpopup = true;
      this.showMoreFields = false;
      this.router.navigate(['/Upadtepassword'])
      // this.router.navigate(['/passwordupdate'])
    }
    if (this.errorMessage == "Email Verified Successfully" || this.errorMessage == "ایمیل با موفقیت تأیید شد.") {
      this.logPop = true
    }

    if (this.errorMessage == "E-Mail Verified Successfully" || this.errorMessage === "Please verify your Email and Login") {
      this.router.navigate(['/home'])
    }
    if (this.errorMessage == "E-mail verified successfully. Happy Playing !!!" || this.errorMessage === "ایمیل با موفقیت تأیید شد") {
      this.logPop = true;
    }




  }



  glodenracegamelunch() {
    if (typeof window !== "undefined") {
    this.token = sessionStorage.getItem('accessToken')
    }
    if (this.playerLoggedIn) {
      this.router.navigate(['/goldenrace']);
    } else {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  dashboard() {
    this.gamelunchmessage = "Please login";
    this.navigationsuccessPop = true;
  }
  russionpoker() {
    if (this.playerLoggedIn) {
      const body = {
        gameId: 'hub88-russian-poker',
        gameMode: 'real_play',
        provider: 'OneTouch'
      } 
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
      this.oneClick = true;
    } else {
      this.openLoginDialog();
    }
  }
  backgammon() {
    if (this.playerLoggedIn) {
      const body = {
        gameId: 'betsolutions-backgammon',
        gameMode: 'real_play',
        provider: 'Betsolutions'
      } 
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
      this.oneClick = true;
    } else {
      this.openLoginDialog();
    }
  }

  launchCasinoGame(id, providername) { 
    if (this.playerLoggedIn) {
      this.waittingLoader = true;
      setTimeout(() => {
        this.waittingLoader = false;
      }, 5000);
      const body = {
        gameId: id,
        gameMode: 'real_play',
        provider: providername
      }
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
      this.oneClick = true;
    } else {
      this.openLoginDialog();
    }
  }



  // loadBalanceDetails(walleteInfo) {
  //   let vipPointsblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(walleteInfo, appConf.vipPointsKey, "cash");

  //   this.vipPoints = vipPointsblc.toString().split(".")[0];
  // }
  // slotsGameLunch(){
  //   this.gamelunchurl= this.mrgameLunchUrl;
  //   this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
  //   return;
  // }

  launchLiveCasino() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
      // this.showPopup("Please login");
    }
    else {
      // this.router.navigate(['/live-casino']);
      this.casinoGamesService.launchLiveCasino()
        .subscribe((data) => {
          if (!data)
            this.showPopup('Error');
          else {
            let redirectionurl = data["VIVO_GAME_LAUNCH_URL"];
            // alert('url')
            if (typeof window !== "undefined") {
            return window.open(redirectionurl, '_self');
            }
            // return location.replace(redirectionurl);
            // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(redirectionurl); 

          }
        }, (err) => {
          this.showPopup(err);
        })
    }
  }
  mojos() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
    } else {
      this.router.navigate(['/liveDealer_2'])
    }
  }
  launchFreshDeck() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
      // this.showPopup("Please login");
    } else {
      // this.router.navigate(['/live-casino']);
      this.casinoGamesService.launchFreshDeck()
        .subscribe((data) => {
          if (!data)
            this.showPopup('Error');
          else {
            let redirectionurl = data["FRESH_DECK_LAUNCH_URL"]; 
            if (typeof window !== "undefined") { 
            return window.open(redirectionurl, "_self");
            }
            // return location.replace(redirectionurl);
            // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(redirectionurl); 

          }
        }, (err) => {
          this.showPopup(err);
        })
    }
  }

  launchJetx() {
    this.launchCasinoGame('smartsoft-jetx', 'SmartSoft')
  }


  showPopup(msg) {
    this.gamelunchmessage = msg;
    this.navigationsuccessPop = true;
  }
  switchLang(lang: string) {
    if (this.selectedlanguege == lang) {

    } else {
    if (typeof window !== "undefined") {
      localStorage.removeItem('locale');
      localStorage.setItem('locale', lang);
      this.translate.use(lang);
      this.selectedlanguege = localStorage.getItem('locale');
      location.reload();
    }
  }
  }
  errmessagenull() {
    setTimeout(() => {
      // this.errorMessage = "";
      // this.logPop = false;
      this.errorsuccessPop = false;
    }, 7000);
  }
  sportsbetaccess() {
    // this.CommondataService.prematchtype('all');
    if (typeof window !== "undefined") {
    if (this.playerLoggedIn == true) {
      let token = sessionStorage.getItem('accessToken');
      if (token == null) {
        let login = sessionStorage.getItem('profile');
        this.Playerservice.getSportsBookToken(login);
      } else {
        this.router.navigate(['/Sportsm']);
      }
    } else if (this.playerLoggedIn == false) {
      this.router.navigate(['/Sportsm']);
    }
  }
  }
  openInstantplay() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
    } else {
      if (typeof window !== "undefined") {
      window.open(this.appLinks.instaPlay, '_self')
      }
    }
  }


  onSubmitNewForm() {
    let LogName: any 
    let LogPassword:any
    if (typeof window !== "undefined") {
       LogName = localStorage.getItem('LogN')
      LogPassword= localStorage.getItem('LogP') 
    }
    if (this.newForm.value.passwordOne !== this.newForm.value.passwordTwo) {
      if (this.selectedlanguege == 'EN') {
        this.conformPassTxt = 'Passwords did not match'
      } else {
        this.conformPassTxt = 'گذرواژه ها مطابقت نداشتند'
      }
    }
    else {
      let payload: any
      if (this.showMoreFields == true) {
        this.loginPlayerName = this.newForm.value.usernaemOne
        this.loginPlayerPass = this.newForm.value.passwordOldOne
        payload = {
          "loginName": this.loginPlayerName,
          "oldPassword": this.loginPlayerPass,
          "newPassword": this.newForm.value.passwordOne
        }
      } else {
        payload = {
          // "loginName": this.loginPlayerName,
          // "oldPassword": this.loginPlayerPass,
          // "newPassword": this.newForm.value.passowrdOne
          "loginName": LogName,
          "oldPassword": LogPassword,
          "newPassword": this.newForm.value.passwordOne
        }
      }
      this.conformPassTxt = '' 
      this.loginService.updateExpiredPassword(payload).subscribe(res => {
        if (res["success"] == true) {
          this.router.navigate(['/home'])
          this.showNewpopup = false;
          this.navigationsuccessPop = true;
          if (this.selectedlanguege == 'EN') {
            this.gamelunchmessage = "Your new password updated successfully"
            this.navigationsuccessPop = true;
          } else {
            this.gamelunchmessage = "رمز عبور جدید شما با موفقیت به روز شد"
            this.navigationsuccessPop = true;
          }
          setTimeout(() => {
            this.successPayload = {
              "success": res.success,
              "sessionId": res.sessionId
            }
            this.store.dispatch(new loginActions.LoginSuccess(this.successPayload));
            this.showPasswordExpairPop = false
          }, 1500)

        } else {
          this.gamelunchmessage = res.description; 
          this.navigationsuccessPop = true;
        }
      })
    }

  } 

  closeNewpopup() {
    this.router.navigate(['/home'])
    this.showNewpopup = false;
    this.showNewpopup1 = false; 
  }
  toggleFieldTextTypeOld() {
    this.newFieldTextType = !this.newFieldTextType;
  }

  
  

}
