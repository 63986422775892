import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { SlotsService } from 'src/app/source/services/slots.service';
import { Router } from '@angular/router';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions'; 
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';


@Component({
  selector: 'app-lucky-streak',
  templateUrl: './lucky-streak.component.html',
  styleUrls: ['./lucky-streak.component.css']
})
export class LuckyStreakComponent implements OnInit {
  private storeSub: Subscription;
  playerLoggedIn: boolean = false;
  gamelunchmessage: any;
  navigationsuccessPop: boolean = false;
  loginSub: Subscription;
  private storeProfileSub: Subscription;
  playerName: string;
  loaderdata: boolean = true;
  
  constructor(private router: Router, private cookieService: CookieService, public sanitizer: DomSanitizer,private casinoGamesService: CasinoGamesService,  private store: Store<appState.AppState>, private slotSer: SlotsService) { }

  ngOnInit() {
    
   let wData = this.cookieService.get("wsession")
   console.log(wData) 
   if(wData){
    this.playerLoggedIn = true;
  }else{
     this.playerLoggedIn = false;
   }
   this.launchFresh()
  }


  launchFresh() {
    if (!this.playerLoggedIn) {
      this.navigationsuccessPop = true;
      this.gamelunchmessage = "Please login"; 
      this.showPopup("Please login");
    } else {
      this.casinoGamesService.launchFreshDeck()
        .subscribe((data) => {
          if (!data)
            this.showPopup('Error');
          else {
            let redirectionurl = data["FRESH_DECK_LAUNCH_URL"];
            setTimeout(() => {
              
              this.loaderdata = false;
            }, 1000);
            return window.open(redirectionurl, '_self');
          }
        }, (err) => {
          this.showPopup(err);
        })
    }
  }

  showPopup(msg) {
    this.gamelunchmessage = msg;
    this.navigationsuccessPop = true;
  }
  navigationPopClose() {
    this.navigationsuccessPop = false;
    this.router.navigate(['/home'])
  }

}
