import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BetHisData } from 'src/app/source/modules/BetHistory/BetHisData';
import { BetHistory, History, Betline } from 'src/app/source/modules/BetHistory/bet-history';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Router } from '@angular/router';
import { PlayerService } from 'src/app/source/services/player/player.service'; 
import * as moment from 'moment';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  now = '';
  undefiend;
  selecteddata: string = "All";
  private HistoryStoreSub: Subscription;
  today = new Date;
  loginSub: Subscription;
  historyloader: boolean = false;
  p: number = 1;
  statustype = ['All', 'Pending', 'WINS', 'Cancelled', 'VOID', 'LOST'];
  selectnum: number = 10;
  logoutstore: Subscription;
  bettokenstoreSub: Subscription;
  descending: boolean;
  betHisData: BetHisData;
  betHistory: BetHistory;
  private tokenstoreSub: Subscription;
  betHisResponse: Array<History>;
  betSlipId: number;
  bethistoryform: FormGroup;
  StartDate: Date;
  todaydate: any;
  EndDate: Date;
  errormessage: string;
  accbetslipid: any;
  SelectedbetId: any = {};
  betIdNO: string;
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate:any;
  playerLoggedIn: boolean = false;
  constructor(private Playerservice: PlayerService, private router: Router, private store: Store<appState.AppState>) {
  }
  ngOnInit() { 
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.EndDate = new Date();
    let today = new Date(this.EndDate.getFullYear(), this.EndDate.getMonth(), this.EndDate.getDate() - 1);
    this.StartDate = today;
    this.bethistoryform = new FormGroup({
      "StartDate": new FormControl(this.StartDate, Validators.required),
      "EndDate": new FormControl(this.EndDate, Validators.required),
      "status": new FormControl(null)
    });
    this.bethistoryform.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.StartDate).getTime() > ToDate.getTime() || new Date(x.StartDate).getTime() > new Date(x.EndDate).getTime() || new Date(x.EndDate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   }); 
  }


  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }

 
  // tranHistory(value: any) {
  //   this.betSlipId = value;
  // }

  onBetHistoryFormSubmit() {
    this.p = 1;
    this.errormessage = null;
    this.betHisResponse = null;
    this.historyloader = true;
    this.bethistoryform.value.status = this.selecteddata;
    this.bethistoryform.value.StartDate = moment(this.bethistoryform.value.StartDate).format('YYYY-MM-DD');
    this.bethistoryform.value.EndDate = moment(this.bethistoryform.value.EndDate).format('YYYY-MM-DD');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  

  }

  accordian(betId) {
    // this.accbetslipid = betId;
    if (this.betIdNO == betId) {
      this.oddinfo(betId)
    } else {
      // this.SelectedbetId = {};
      this.oddinfo(betId)
    }
  }
  changeStatustType(event) {
    this.selecteddata = event;
  }

  oddinfo(betId) {
    this.betIdNO = betId;
    if (betId in this.SelectedbetId) {
      delete this.SelectedbetId[betId];
    } else {
      this.SelectedbetId[betId] = this.betIdNO;
    }
  }
}
