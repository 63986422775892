import * as cashierActions from './cashierActions';
import { Balance } from '../../modules/cashier/balance';
import { PaymentSystems } from '../../modules/cashier/paymentSystems';
import { DepositResponse } from '../../modules/cashier/depositResponse';
import { HttpClientError } from '../../modules/login/httpClientError';
import { WithDrawResponse } from '../../modules/cashier/withdrawResponse';
import { BankAccountInfo } from '../../modules/cashier/bankAccountInfo';
import { DepositSystems } from '../../modules/cashier/depositSystems';
import { WithdrawSystems } from '../../modules/cashier/withDraw';
import { TransactionResponse, TransactionResponseForToken } from '../../modules/cashier/transactionsresponsehistory';
import { withDrawRequestResponse } from '../../modules/cashier/withDrawRequestResponse';
import { SlideplayResponse } from '../../modules/cashier/slideplay';
import { DepositSystem } from '../../modules/cashier/deposisystem';
import { addBankAccountResponse } from '../../modules/cashier/addbankaccount';
import { OtpRequest } from '../../modules/login/otprequest';
import { WitdrawSelect } from '../../modules/cashier/witdrawselect';
import { NgIf } from '@angular/common';
import { reverselCashout } from '../../modules/cashier/reversalcashout';

export interface CashierState {

    CashierOtpRequestResponse: OtpRequest;
    CashierOtpRequestErrorResponse: HttpClientError;
    balance: Balance;
    balanceErrorRes: HttpClientError;
    DepositSystem: DepositSystem;
    DepositSystemError: HttpClientError;
    SlidePlayResponse: SlideplayResponse;
    SlidePlayErrorResponse: HttpClientError;
    paymentSystems: PaymentSystems;
    depositResponse: DepositResponse;
    depositFail: HttpClientError;
    withDrawResponse: WithDrawResponse;
    withDrawResponseError: HttpClientError;
    bankAccountInfo: BankAccountInfo;
    bankAccountInfoError: HttpClientError;
    withDrawRequestResponse: withDrawRequestResponse;
    withDrawRequestResponseFail: HttpClientError;
    depositeSystem: DepositSystems;
    depositeSystemResponseFail: HttpClientError;
    withDrawSystem: WithdrawSystems;
    withDrawSystemResponseFail: HttpClientError;
    TransactionResponse: TransactionResponse;
    TranscationResponseFail: HttpClientError;
    TransactionResponseForToken: TransactionResponseForToken;
    TranscationResponseForTokenFail: HttpClientError;
    addBankAccountResponse: addBankAccountResponse;
    addBankAccountError: HttpClientError;
    witdrawselect:WitdrawSelect;
    reverselcashout:reverselCashout;
    reverselcashoutError: HttpClientError;
}

const initialState: CashierState = {
    balance: null,
    balanceErrorRes:null,
    paymentSystems: null,
    SlidePlayResponse: null,
    SlidePlayErrorResponse: null,
    depositResponse: null,
    depositFail: null,
    withDrawResponse: null,
    withDrawResponseError: null,
    bankAccountInfo: null,
    withDrawRequestResponse: null,
    withDrawRequestResponseFail: null,
    depositeSystem: null,
    depositeSystemResponseFail: null,
    withDrawSystem: null,
    withDrawSystemResponseFail: null,
    TransactionResponse: null,
    TranscationResponseFail: null,
    TransactionResponseForToken: null,
    TranscationResponseForTokenFail: null,
    DepositSystem: null,
    DepositSystemError: null,
    addBankAccountResponse: null,
    addBankAccountError: null,
    bankAccountInfoError: null,
    CashierOtpRequestResponse: null,
    CashierOtpRequestErrorResponse: null,
    witdrawselect:null,
    reverselcashout:null,
    reverselcashoutError: null
}

export function cashierReducer(state: CashierState = initialState, action: cashierActions.CashierActions) {
    switch (action.type) {
        case cashierActions.RESET_STATE:
            return {
                ...state,
                balance: null, 
                balanceErrorRes:null,
                paymentSystems: null,
                SlidePlayResponse: null,
                SlidePlayErrorResponse: null,
                depositResponse: null,
                depositFail: null,
                withDrawResponse: null,
                withDrawResponseError: null,
                bankAccountInfo: null,
                withDrawRequestResponse: null,
                withDrawRequestResponseFail: null,
                depositeSystem: null,
                depositeSystemResponseFail: null,
                withDrawSystem: null,
                withDrawSystemResponseFail: null,
                TransactionResponse: null,
                TranscationResponseFail: null,
                TransactionResponseForToken: null,
                TranscationResponseForTokenFail: null,
                DepositSystem: null,
                DepositSystemError: null,
                addBankAccountResponse: null,
                addBankAccountError: null,
                bankAccountInfoError: null,
                CashierOtpRequestResponse: null,
                CashierOtpRequestErrorResponse: null,
                witdrawselect:null,
                reverselcashout:null,
                reverselcashoutError: null
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE:
            return {
                ...state,
                balance: null, 
                balanceErrorRes:null
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE_SUCCESS:
            return {
                ...state,
                balance: { ...state.balance, ...action.payload },
                balanceErrorRes:null
            }
            break;
        case cashierActions.CASHIER_GET_BALANCE_FAIL:
            return {
                ...state,
                balance: null,
                balanceErrorRes:{ ...state.balanceErrorRes, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS:
            return {
                ...state,
                paymentSystems: null
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS_SUCCESS:
            return {
                ...state,
                paymentSystems: { ...state, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS_FAIL:
            return {
                ...state,
                paymentSystems: null
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST:
            return {
                ...state,
                withDrawResponse: null,
                withDrawResponseError: null
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST_SUCCESS:
            return {
                ...state,
                withDrawResponse: { ...state.withDrawResponse, ...action.payload },
                withDrawResponseError: null,
            }
            break;
        case cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST_FAIL:
            return {
                ...state,
                withDrawResponse: null,
                withDrawResponseError: { ...state.withDrawResponseError, ...action.payload },
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST:
            return {
                ...state,
                withDrawRequestResponse: null,
                withDrawRequestResponseFail: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST_SUCCESS:
            return {
                ...state,
                withDrawRequestResponse: { ...state.withDrawRequestResponse, ...action.payload },
                withDrawRequestResponseFail: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_REQUEST_FAIL:
            return {
                ...state,
                withDrawRequestResponse: null,
                withDrawRequestResponseFail: { ...state.withDrawRequestResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_DEPOSIT:
            return {
                ...state,
                depositResponse: null,
                depositFail: null
            }
            break;
        case cashierActions.CASHIER_DEPOSIT_SUCCESS:
            return {
                ...state,
                depositResponse: { ...state.depositResponse, ...action.payload },
                depositFail: null
            }
            break;
        case cashierActions.CASHIER_DEPOSIT_FAIL:
            return {
                ...state,
                depositResponse: null,
                depositFail: { ...state.depositFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT:
            return {
                ...state,
                bankAccountInfo: null,
                bankAccountInfoError: null
            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                bankAccountInfo: { ...state.bankAccountInfo, ...action.payload },
                bankAccountInfoError: null
            }
            break;
        case cashierActions.CASHIER_GET_BANK_ACCOUNT_FAIL:
            return {
                ...state,
                bankAccountInfo: null,
                bankAccountInfoError: { ...state.bankAccountInfoError, ...action.payload },
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS:
            return {
                ...state,
                depositeSystem: null,
                depositeSystemResponseFail: null,
                depositResponse: null
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS_SUCCESS:
            return {
                ...state,
                depositeSystem: { ...state.depositeSystem, ...action.payload },
                depositeSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS_FAIL:
            return {
                ...state,
                depositeSystem: null,
                depositeSystemResponseFail: { ...state.depositeSystemResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS:
            return {
                ...state,
                withDrawSystem: null,
                withDrawSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS_SUCCESS:
            return {
                ...state,
                withDrawSystem: { ...state.withDrawSystem, ...action.payload },
                withDrawSystemResponseFail: null
            }
            break;
        case cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS_FAIL:
            return {
                ...state,
                withDrawSystem: null,
                withDrawSystemResponseFail: { ...state.withDrawSystemResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY:
            return {
                ...state,
                TransactionResponse: null,
                TranscationResponseFail: null
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY_SUCCESS:
            return {
                ...state,
                TransactionResponse: { ...state.TransactionResponse, ...action.payload },
                TranscationResponseFail: null
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY_FAIL:
            return {
                ...state,
                TransactionResponse: null,
                TranscationResponseFail: { ...state.TranscationResponseFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY_TOKEN:
            return {
                ...state,
                TransactionResponseForToken: null,
                TranscationResponseForTokenFail: null
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY_TOKEN_SUCCESS:
            return {
                ...state,
                TransactionResponseForToken: { ...state.TransactionResponseForToken, ...action.payload },
                TranscationResponseForTokenFail: null
            }
            break;
        case cashierActions.CASHIER_TRANSACTION_HISTORY_TOKEN_FAIL:
            return {
                ...state,
                TransactionResponseForToken: null,
                TranscationResponseForTokenFail: { ...state.TranscationResponseForTokenFail, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_SLIDEPLAY_DEPOSIT_SYSTEMS:
            return {
                ...state,
                SlidePlayResponse: null,
                SlidePlayErrorResponse: null
            }
            break;
        case cashierActions.CASHIER_GET_SLIDEPLAY_DEPOSIT_SYSTEMS_SUCCESS:
            return {
                ...state,
                SlidePlayResponse: { ...state.SlidePlayResponse, ...action.payload },
                SlidePlayErrorResponse: null
            }
            break;
        case cashierActions.CASHIER_GET_SLIDEPLAY_DEPOSIT_SYSTEMS_FAIL:
            return {
                ...state,
                SlidePlayResponse: null,
                SlidePlayErrorResponse: { ...state.SlidePlayErrorResponse, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_GET_CASHIER_DEPOSIT_SYSTEMS:
            return {
                ...state,
                DepositSystem: null,
                DepositSystemError: null
            }
            break;
        case cashierActions.CASHIER_GET_CASHIER_DEPOSIT_SYSTEMS_SUCCESS:
            return {
                ...state,
                DepositSystem: { ...state.DepositSystem, ...action.payload },
                DepositSystemError: null
            }
            break;
        case cashierActions.CASHIER_GET_CASHIER_DEPOSIT_SYSTEMS_FAIL:
            return {
                ...state,
                DepositSystem: null,
                DepositSystemError: { ...state.DepositSystemError, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_ADD_BANK_ACCOUNT:
            return {
                ...state,
                addBankAccountResponse: null,
                addBankAccountError: null
            }
            break;
        case cashierActions.CASHIER_ADD_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                addBankAccountResponse: { ...state.addBankAccountResponse, ...action.payload },
                addBankAccountError: null
            }
            break;
        case cashierActions.CASHIER_ADD_BANK_ACCOUNT_FAIL:
            return {
                ...state,
                addBankAccountResponse: null,
                addBankAccountError: { ...state.addBankAccountError, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_OTP_REQUEST:
            return {
                ...state,

                CashierOtpRequestResponse: null,
                CashierOtpRequestErrorResponse: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                CashierOtpRequestResponse: { ...state.CashierOtpRequestResponse, ...action.payload },
                CashierOtpRequestErrorResponse: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_OTP_REQUEST_FAIL:
            return {
                ...state,
                CashierOtpRequestResponse: null,
                CashierOtpRequestErrorResponse: { ...state.CashierOtpRequestErrorResponse, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_SELECT_REQUEST:
            return {
                ...state,

                witdrawselect: null
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_SELECT_REQUEST_SUCCESS:
            return {
                ...state,
                witdrawselect: { ...state.witdrawselect, ...action.payload }
            }
            break;
        case cashierActions.CASHIER_WITHDRAW_SELECT_REQUEST_FAIL:
            return {
                ...state,
                witdrawselect: null
                
            }
            break;
            case cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST:
                return {
                    ...state,
                    reverselcashout:null,
                    reverselcashoutError: null
                }
                break;
            case cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST_SUCCESS:
                return {
                    ...state,
                    reverselcashout: { ...state.reverselcashout, ...action.payload },
                    reverselcashoutError: null
                }
                break;
            case cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST_FAIL:
                return {
                    ...state,
                    reverselcashout: null,
                    reverselcashoutError: { ...state.reverselcashoutError, ...action.payload }
                }
                break;
        default:
            return state;
            break;
    }
}