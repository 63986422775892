import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CashierGetBalanceStart } from 'src/app/source/appstates/cashierstates/cashierActions';
import {Component, OnInit, OnDestroy} from '@angular/core';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';

@Component({
  selector: 'app-depositresponse',
  templateUrl: './depositresponse.component.html',
  styleUrls: ['./depositresponse.component.css']
})
export class DepositresponseComponent implements OnInit, OnDestroy {
  result: string;
  depositmsg: string;
  private loginSub: Subscription;
  depositresponse: boolean = false;
  playerLoggedIn: boolean = false;
  constructor(
    private router: Router, private store: Store<appState.AppState>,
    private route: ActivatedRoute) {

  }
  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      this.result = params.result;
      if (this.result == 'success') {
        this.depositresponse = true;
        this.depositmsg = "success";
      } else {
        this.depositresponse = true;
        this.depositmsg = "error";
      }
    });
    //this.store.dispatch(new PlayerGetProfile());
  }

  navigate() {
    if (this.result == 'success') {
      this.store.dispatch(new CashierGetBalanceStart());
      this.depositresponse = false;
      this.router.navigate(["/transaction"]);
    } else {
      this.depositresponse = false;
      this.router.navigate(["/deposit"]);
    }
  }
  ngOnDestroy() { }

}
