
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { forgotResponse } from 'src/app/source/modules/forgotpassword';
@Component({
  selector: 'app-passwords',
  templateUrl: './passwords.component.html',
  styleUrls: ['./passwords.component.css']
})
export class PasswordsComponent implements OnInit, OnDestroy {
  forgotPassword: boolean = false;
  forgotform: FormGroup;
  private storeSub: Subscription;
  private forgotresponse: forgotResponse;
  forgotPasswordErrorMessage:string;
  comonmessage:string="";
  // changedemail:boolean=false;
  // emailErroMessage:boolean=false;
  comingSoon :boolean= false;
  constructor( private store: Store<appState.AppState>, ) { }

  ngOnInit() {
    
    this.store.dispatch(new loginActions.ResetState());
    this.forgotform = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
      "login": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')])
    });
  //   this.forgotform.get('email').valueChanges.subscribe(x => {
  //     let extension = x.substr(x.lastIndexOf(".") + 1);
  //     if(extension === 'com'){ 
  //      this.emailErroMessage=false;
  //       this.changedemail = true;
  //     }else{
        
  //      this.emailErroMessage=true;
  //       this.changedemail = false;
  //     }
  //  });
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.forgotresponse) {
          if (loginState.forgotresponse.success === true) {
            this.forgotform.reset();
            this.forgotPassword = false;
          this.forgotPasswordErrorMessage =  "";
            this.comonmessage = loginState.forgotresponse.description;
            this.comingSoon = true;
          } else if (loginState.forgotresponse.success === false) {
          //this.forgotPasswordErrorMessage =  loginState.forgotresponse.description;
          this.forgotPasswordErrorMessage =  "Invalid Data";
           // this.toastr.error(loginState.forgotresponse.description);
          }
        }
        if(loginState.forgotPasswordErrorResponse)
        {

          this.forgotPasswordErrorMessage =  loginState.forgotPasswordErrorResponse.message;
        }
      }
    )
  }

  onForgotpsdFormSubmit() {
    this.store.dispatch(new loginActions.ForgotPasswordStart(this.forgotform.value))
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  openForgotPasswordPop(){
    this.forgotPassword = true;
  }
  closeForgotPasswordPop(){
    this.forgotform.reset();
    this.store.dispatch(new loginActions.ResetState());
    this.forgotPassword = false;
  }
  comingSoonPopClose(){
    this.store.dispatch(new loginActions.ResetState());
    this.comonmessage ="";
    this.comingSoon = false;
  }
  comingSoonPopOpen(){
    this.comonmessage = "Coming soon";
    // this.comonmessage = "New Markets, New Matches and Live Casino coming soon, meanwhile enjoy our Sportsbook and new Casino games. Happy Playing!"
    this.comingSoon = true;
  } 

  
}
