import { Component, ElementRef, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WalletInfo } from 'src/app/source/modules/cashier/balance';
import { Store } from '@ngrx/store';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions'; 
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConf } from 'src/app/source/config/app.config';
import { CommonUtilService } from 'src/app/source/services/common/commonutil.service';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { appConstants } from 'src/app/source/constants/appconstants';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { stringify } from '@angular/compiler/src/util';
import { CurrencyPipe } from '@angular/common';
import { TBankAccountInfo } from 'src/app/source/modules/cashier/bankAccountInfo';
import { Router } from '@angular/router';

import * as appState from 'src/app/source/appstates/appState';


declare const $: any;
@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.css']
})
export class CashierComponent implements OnInit {
  private storeSub: Subscription;
  private loginSub: Subscription;
  private viewstoreSub: Subscription;
  public isAccountInfoInvalid: boolean = true;
  paymentNewMethod:string  = '117'
  walleteInfo: WalletInfo[];
  preferredBalance: WalletInfo;
  playerLOggedIn: boolean = false;
  public availableForPayout: any = null;
  AddressType: string = "TRC20";
  public cashOutAmount: Number = 0;
  prefCurrency: string = "";
  playerNickName: any = "";
  isError: boolean = false;
  errMsg: string = "";
  // successMsg: any = null;
  chpBTN: boolean = false;
  usdBTN: boolean = false;
  paymentMethod: any = 342;
  depositAmount: number;
  // depositAmount: any = 1000;
  balance: any;
  private profile: Profile;
  chipsAmount: string;
  historyloader: boolean = false;
  historyloader1: boolean = true;
  cashoutUpdate: FormGroup;
  perfectMoneyForm: FormGroup;
  transferChipForm: FormGroup;
  wattetType: any;
  openAmountI: boolean = false;
  // selecteddata: number = 250000;
  selecteddata: any;
usdForm: FormGroup;
  chpForm: FormGroup;
  payoutamount = [
    500000,
    1000000,
    2000000,
    5000000,
    10000000,
    20000000,
    30000000,
    50000000,
    100000000
  ];
selectedOption: string;
  bankAccountInfos: any;
  lang: any = appConstants.language;
  cashOutBtn: boolean = false;
  waittingLoader: boolean = false;
  //employee: any = undefined;
  intervalId: any;
  // changedemail:boolean=false;
  // emailErroMessage:boolean=false;
  usdtAddressTypedisable: boolean = false
  usdtAddressTypeValue: any;
  userAccount: string | null = '';
  userAccountIFSC: string | null = '';
  openRivercashout:boolean=true
  openCaspiancashout:boolean=false
  isActive: number = 1;
  @ViewChild('cashoutTab', { static: false }) cashoutTab: ElementRef;


  constructor(private store: Store<appState.AppState>, private renderer: Renderer2,
    private playerSer: PlayerService, private commonUtilSer: CommonUtilService, private router: Router,
    private currencyPipe: CurrencyPipe) {
    this.cashOutAmount = this.payoutamount[0];
    //  this.getCashOutBalance();

  }



  ngOnInit(): void {

    this.AddUF('CHP')
    console.log(this.payoutamount[0])
    
    //   this.cashoutUpdate.get("cashoutsend.email").valueChanges.subscribe(x => {
    //     let extension = x.substr(x.lastIndexOf(".") + 1);
    //     if(extension === 'com'){ 
    //      this.emailErroMessage=false;
    //       this.changedemail = true;

    //     }else{

    //      this.emailErroMessage=true;
    //       this.changedemail = false;
    //     }
    //  });
    this.transferChipForm = new FormGroup({
      // "sentTransferChipForm": new FormGroup({
      // "nickname": new FormControl('', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
      "nickname": new FormControl('', Validators.required),
      "message": new FormControl('', Validators.pattern('^[a-zA-Z0-9 ]*$')),
      "amount": new FormControl('', [Validators.required, Validators.min(500000), Validators.max(50000000)]),
      "franc": new FormControl('00'),
      // "walletCode": new FormControl(this.prefCurrency)
      // }),

      "payoutvalue": new FormControl(this.availableForPayout, [Validators.required])
    });
    this.transferChipForm.valueChanges.subscribe(form => {

      if (form.amount) {
        this.transferChipForm.patchValue({
          amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
        }, { emitEvent: false })
      }
    });

    this.perfectMoneyForm = new FormGroup({
      "currency": new FormControl(null),
      "paymentMethod": new FormControl(null),
      "bonuscode": new FormControl(null),
      "amount": new FormControl(null),
      "PERFECT_VOUCHER_NUMBER": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{0,24}')]),
      "PERFECT_VOUCHER_CODE": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{0,24}')])
    });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          this.walleteInfo = cashierState.balance.values;
          if (this.walleteInfo)
            this.loadWalletsData(this.walleteInfo);
          for (let wallete of this.walleteInfo) {
            if (wallete.preferred === true) {
              this.preferredBalance = wallete;
              let totalbalance = wallete.cash.value + wallete.bonus.value;
              this.balance = Number(totalbalance.toString().split(".")[0]);
              break;
            }
          }
        }
        else {
          // ERROR POPUP
        }
      }
    );
    

    // <------------------ Start ------------------>
    // addded on 15 - Aug - 2024 by raju
    const url = new URL(window.location.href);
    const cashout = url.searchParams.get('cashout');

    if (cashout === 'true') {
      const tab = document.getElementById("cashout-tab");
      if (tab) {
        tab.click();
      }
    }
    // <---^^--------------- End ---------------^^--->

    // this.intervalId = setInterval(() => {
    //   this.userAccount = sessionStorage.getItem('UserAC');
    //   this.userAccountIFSC = sessionStorage.getItem('Usersc');
    //   if (this.userAccount && this.userAccountIFSC ) {
    //     this.historyloader1 = false
    //     clearInterval(this.intervalId);
    //   }  
    // }, 1000);  

    this.viewstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.bankAccountInfo) {

          if (cashierState.bankAccountInfo.success == true) {
            if (cashierState.bankAccountInfo.TBankAccountInfos) {
              this.historyloader1 = false;
              this.bankAccountInfos = cashierState.bankAccountInfo.TBankAccountInfos;
              if (this.bankAccountInfos[0] && this.bankAccountInfos[0].nameOnBank) {
                // this.userAccount = this.bankAccountInfos[0].nameOnBank.split(',')[1];

                const nameOnBank = this.bankAccountInfos[0].nameOnBank;
                if (nameOnBank.includes('CardNo :')) {
                  const parts = nameOnBank.split('CardNo :');
                  this.userAccount = parts.length > 1 ? parts[1].trim() : '';
                } 
                else if (nameOnBank.includes(',')) {
                  const parts = nameOnBank.split(',');
                  this.userAccount = parts.length > 1 ? parts[1].trim() : '';
                } 
                else {
                  this.userAccount = '';
                }



              } else {
                this.userAccount = '';
              }

              if (this.bankAccountInfos[0] && this.bankAccountInfos[0].bankIFSCCOde) {
                this.userAccountIFSC = this.bankAccountInfos[0].bankIFSCCOde;
              } else {
                this.userAccountIFSC = '';
              }

              this.isAccountInfoInvalid = !this.userAccount || !this.userAccountIFSC;
            }
          }

        }

      })
  this.initializeForms();
    this.profile1()
  }


  initializeForms() {
    this.usdForm = new FormGroup({
      cashoutsend: new FormGroup({
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
        ]),
        usdtAddress: new FormControl(''),
        usdtAddressType: new FormControl("TRC20"),

        paymentMethod: new FormControl(this.paymentNewMethod),
        amount: new FormControl('', [
          Validators.required,
          Validators.min(10),
          Validators.max(100000)
        ])
      }),
      payoutvalue: new FormControl(this.availableForPayout, [Validators.required])
    });

    this.chpForm = new FormGroup({
      cashoutsend1: new FormGroup({
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
        ]),
        usdtAddress: new FormControl(''),
        usdtAddressType: new FormControl("TRC20"),
        paymentMethod: new FormControl(this.paymentNewMethod),
        amount: new FormControl('', [
          Validators.min(10),
          Validators.max(100000)
        ])
      }),
      payoutvalue: new FormControl(this.availableForPayout, [Validators.required])
    });
  }





  isButtonEnabled(): boolean {
    // The button is enabled when both Card Number and Sebha Number are null or empty
    return !this.userAccount && !this.userAccountIFSC;
  }

  // Method to determine if the input fields should be disabled
  isInputDisabled(): boolean {
    // Input fields are disabled when either Card Number or Sebha Number has a value
    return !!this.userAccount || !!this.userAccountIFSC;
  }
  // ngOnDestroy(): void {     
  //   if (this.intervalId) {
  //     clearInterval(this.intervalId);
  //   }
  // }
  AddUF(data) {
    this.wattetType = data
    this.paumoutA()
    this.loadWalletsData(this.walleteInfo);
  }
  paumoutA() {
    this.payoutamount = [];
    if (this.wattetType == "USD") {
      // this.selecteddata = 10
      // this.payoutamount = [
      //   10,
      //   25,
      //   50,
      //   100,
      //   200,
      //   300,
      //   400,
      //   500,
      //   1000,
      //   2000,
      //   3000,
      //   4000,
      //   5000,
      //   10000,
      //   20000
      // ];
      this.selecteddata = '';
    } else {
      this.changeAmtVal("500000");
      this.selecteddata = 500000
      this.payoutamount = [
        500000,
        1000000,
        2000000,
        5000000,
        10000000,
        20000000,
        30000000,
        50000000,
        100000000
      ];
    }
    console.log(this.wattetType)
  }
  profile1() {
    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          if (playerState.profile.success == true) {
            this.profile = playerState.profile;
            

            const usdEmailControl = this.usdForm.get('cashoutsend.email');
            if (usdEmailControl) {
              usdEmailControl.setValue(this.profile.email);
            }

            const chpEmailControl = this.chpForm.get('cashoutsend1.email');
            if (chpEmailControl) {
              chpEmailControl.setValue(this.profile.email);
            }
            const usdAddressControl = this.usdForm.get('cashoutsend.usdtAddress');
            if(this.profile.ssn == "xxxxxxxxxx"){
              if (usdAddressControl) {
                usdAddressControl.setValue('');
              }
            }else{
              if (usdAddressControl) {
                usdAddressControl.setValue(this.profile.ssn);
              }
            }

            const chpAddressControl = this.chpForm.get('cashoutsend1.usdtAddress');
            if (chpAddressControl) {
              chpAddressControl.setValue(this.profile.ssn);
            }

            // if (this.profile.email) {
            //   (<FormControl>this.cashoutUpdate.get('cashoutsend')).get("email").setValue(this.profile.email);
            // }

          }
        }
      }
    )
    this.store.dispatch(new playerActions.PlayerGetProfile());

  }



  onInput() {
    if ($(window).width() < 660 && $(window).height() < 400) {
      $("input").focusin(function () {
        window.scroll(0, $(this).offset().top - 20);
        $('.fixMenu').css("position", "relative");
        $('.body_m').css("margin-top", "0px");
      });
      $("input").focusout(function () {
        $('.fixMenu').css("position", "fixed");
        $('.body_m').css("margin-top", "52px");
      });
    }
  }


  // loadWalletsData(apiRes) {
  //   this.prefCurrency = this.commonUtilSer.loadAppPreferredCurrency(apiRes);
  //   let availableForPayoutblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(apiRes, this.prefCurrency, "cash");
  //   this.availableForPayout = availableForPayoutblc.toString().split(".")[0];
  //   // alert(this.availableForPayout)
  // }

  loadWalletsData(apiRes) {
    if (apiRes == undefined) {
      this.storeSub = this.store.select("cashierState").subscribe(
        (cashierState: CashierState) => {
          if (cashierState.balance) {
            this.walleteInfo = cashierState.balance.values;
            if (this.walleteInfo)
              this.loadWalletsData(this.walleteInfo);
            for (let wallete of this.walleteInfo) {
              if (wallete.preferred === true) {
                this.preferredBalance = wallete;
                let totalbalance = wallete.cash.value + wallete.bonus.value;
                this.balance = Number(totalbalance.toString().split(".")[0]);
                break;
              }
            }
          }
          else {
            // ERROR POPUP
          }
        }
      );
    }
    if (this.wattetType != "USD") {
      this.prefCurrency = this.commonUtilSer.loadAppPreferredCurrency(apiRes);
      let availableForPayoutblc = this.commonUtilSer.loadCashbalanceBasedOnCurrency(apiRes, this.prefCurrency, "cash");
      this.availableForPayout = availableForPayoutblc.toString().split(".")[0];
      this.cashOutBtn = false;
    } else {
      if (apiRes) {
        for (let i = 0; i < apiRes.length; i++) {
          if (apiRes[i].wallet.name == "USD") {
            if (apiRes[i].cash.value != 0) {
              this.availableForPayout = apiRes[i].cash.value.toString().split(".")[0]
              this.cashOutBtn = false;
            } else {
              this.availableForPayout = 0;
              this.cashOutBtn = true;
            }
          }
        }
      }
    }
  }

  transfer() {

    let num = this.transferChipForm.value.amount;
    console.log(this.transferChipForm.value.amount)
    console.log(this.availableForPayout)
    // let a = num.replace(/,/g, '');
    // let amountno = Number(a);
    let amountno = num;
    if (amountno <= 0) {
      this.setError("Amount can't be zero ");
    } else if (amountno <= this.availableForPayout) {
      this.historyloader = true;
      this.resetError();
      // this.transferChipForm.value.amount = String(a);
      const body = {
        nickname: this.transferChipForm.value.nickname,
        message: this.transferChipForm.value.message,
        amount: this.transferChipForm.value.amount,
        franc: this.transferChipForm.value.franc
      }
      this.playerSer.makeP2PTransfer(body)
        .subscribe((data) => {
          this.ptoptransferhanle(data);
        }, (err) => {
          this.setError(err.statusText);
        })
    } else {
      this.setError("Amount can't be less than with your balance");
    }
  }
  resetError() {
    this.isError = false;
    this.errMsg = "";
  }
  setError(errMsg) {
    this.historyloader = false;
    if (errMsg == 'PLAYER_NOT_FOUND') {
      this.isError = true;
      if (this.lang == 'EN') {
        this.errMsg = 'Player not found';
      }
      else {
        this.errMsg = 'پخش کننده یافت نشد';
      }
    } else if (typeof errMsg == "string") {
      this.isError = true;
      this.errMsg = errMsg;
    } else if (errMsg == 'Please try after 10mins') {
      // } else if (errMsg == 'Invalid ev_number. Please try after 10mins') {
      this.isError = true;
      if (this.lang == 'EN') {
        this.errMsg = 'Please try after 10mins';
      } else {
        this.errMsg = 'شماره EV نامعتبر است. لطفاً بعد از 10 دقیقه امتحان کنید';

      }
    } else {
      this.errMsg = this.errMsg + " and " + errMsg["message"];
      this.isError = true;
    }
  }
  // Depost functionality 
  deposit() {
    this.waittingLoader = true;
    this.historyloader = true;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    let data = {
      "paymentMethod": this.paymentMethod, "bonuscode": "", "currency": "CHP",
      "amount": this.paymentMethod == "116" ? 1000000 : (this.depositAmount)
    };
    this.playerSer.playerDeposit(data)
      .subscribe((data) => {
        this.depositHandler(data);
      }, (err) => {
        this.setError(err);
      })
  }
  bitcoin(amt) {
    // this.waittingLoader = true;
    this.historyloader = false;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.paymentMethod = amt;
    let data = {
      "paymentMethod": this.paymentMethod,
      "bonuscode": "",
      "currency": "CHP",
      "amount": 500,
      "language": localStorage.getItem("locale"),
      paymentType: "deposit",

    };
    this.playerSer.playerDeposit(data)
      .subscribe((data) => {
        this.depositHandler(data);
      }, (err) => {

        this.setError(err);
      })
  }
  // bitcoin(amt) {
  //   this.waittingLoader = true;
  //   this.historyloader = false;
  //   this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  //   this.paymentMethod = amt;

  //   if(this.paymentMethod == 341){
  //     this.paymentMethod = 342
  //     var body={
  //       "paymentMethod": this.paymentMethod,
  //       "paymentType":"deposit",
  //       "bonuscode": "",
  //       "currency": "CHP",
  //       "amount": this.depositAmount,
  //       "language": localStorage.getItem("locale"),
  //       "ip":"127.0.0.1"
  //   }
  //   }else{

  //     var body ={
  //       "paymentMethod": this.paymentMethod,
  //       "paymentType":"deposit",
  //       "bonuscode": "",
  //       "currency": "CHP",
  //       "amount": 500,
  //       "language": localStorage.getItem("locale"),
  //       "ip":"127.0.0.1"
  //   }
  //   } 
  //   this.playerSer.playerDeposit(body)
  //     .subscribe((data) => {
  //       this.depositHandler(data);
  //     }, (err) => {

  //       this.setError(err);
  //     })

  // }
  perfectmoneydeposit() {
    this.historyloader = true;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.perfectMoneyForm.value.currency = "CHP";
    this.perfectMoneyForm.value.paymentMethod = 117;
    this.perfectMoneyForm.value.bonuscode = "";
    this.perfectMoneyForm.value.amount = 0;
    this.playerSer.playerDeposit(this.perfectMoneyForm.value)
      .subscribe((data) => {
        this.perfectMoneydepositHandler(data);
      }, (err) => {
        this.setError(err);
      })
  }
  getChipsAmount() {
    this.playerSer.chipsAmount()
      .subscribe((data) => {
        this.chipsAmountHandler(data);
      }, (err) => {

        this.setError(err.statusText);
      })
  }
  chipsAmountHandler(data) {
    if (data['success']) {
      this.chipsAmount = data['description'];
    } else {

      this.setError(data.description);
    }
  }
  depositHandler(data) {
    this.historyloader = false;
    this.openAmountI = false;
    if (data && data.success) {
      if (data["result"]["externalLink"]) {
        if (data["result"]["redirectUrl"]) {
          let redirectionurl = data["result"]["redirectUrl"];
          let urlst = redirectionurl.substr(0, 4);
          if (urlst == 'http') {
            setTimeout(() => {
              this.waittingLoader = false;
            }, 2000);
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            } else {
              this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            }
            window.location.href = redirectionurl;
            this.perfectMoneyForm.controls['PERFECT_VOUCHER_NUMBER'].setValue("");
            this.perfectMoneyForm.controls['PERFECT_VOUCHER_CODE'].setValue("");
          } else {
            this.setError("Network Error While connecting to PaymentGateway, Please try after 10 Minutes");
          }
        } else {
          this.setError("Network Error While connecting to PaymentGateway, Please try after 10 Minutes");
        }

      } else {
        this.setError("Network Error While connecting to PaymentGateway, Please try after 10 Minutes");
      }
    }
    else {
      this.setError("Network Error While connecting to PaymentGateway, Please try after 10 Minutes");
    }
  }
  perfectMoneydepositHandler(data) {
    this.historyloader = false;
    if (data && data.success) {
      if (data["result"]["externalLink"]) {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        this.perfectMoneyForm.controls['PERFECT_VOUCHER_NUMBER'].setValue("");
        this.perfectMoneyForm.controls['PERFECT_VOUCHER_CODE'].setValue("");
        this.setError(data["result"]["message"]);
        // let redirectionurl = data["result"]["redirectUrl"];
        // window.location.href = redirectionurl;
      }
      else {
        this.setError("Please Try After 15 Minutes");
      }
    }
    else {
      this.setError("Please Try After 15 Minutes");
    }
  }
  ptoptransferhanle(data) {
    // if (data["success"] === false){
    //   this.errMsg=data["description"];
    // }else{
    //   this.successMsg="Transfer successfull";
    // }

    this.historyloader = false;
    if (data && data.success) {
      // this.transferChipForm.controls['nickname'].setValue("");
      // this.transferChipForm.controls['amount'].setValue("");
      this.transferChipForm.get("nickname").reset();
      this.transferChipForm.get("amount").reset();
      this.transferChipForm.get("message").reset();
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.setError("Transfer successfull");

    } else if (data.description == 'USER_IS_LOCKED') {
      this.setError("User is locked");

    }
    else {
      this.setError("code" in data ? data["description"] : "Unknown error");
    }
  }

  withdraw() {
    this.resetError(); 
    
    let amount: any = this.wattetType === "CHP" ? this.selecteddata : this.usdForm.value.cashoutsend.amount;
    let availablePayout = this.availableForPayout;
 

    console.log('Amount Type:', typeof amount);
    console.log('Available For Payout Type:', typeof availablePayout);

    if (Number(amount) <= 0) {
      this.setError("Amount can't be zero");
    } else if (Number(amount) <= Number(availablePayout)) {
      // if (this.wattetType === "USD" && !this.usdForm.value.cashoutsend.usdtAddress) {
      // this.setError("USDT Address is required");
      //   return;
      // }

      this.historyloader = true;
      // this.historyloader1 = true;

      let formToSubmit = this.wattetType === "USD" ? this.usdForm : this.chpForm;

      if (this.wattetType === "USD") {
        // formToSubmit.value.cashoutsend.usdtAddressType = this.AddressType;
        if (formToSubmit.value.cashoutsend.usdtAddress) {
          formToSubmit.value.cashoutsend.usdtAddress
        } else {
          formToSubmit.value.cashoutsend.usdtAddress = 'xxxxxxxxxx'
        }
      formToSubmit.value.cashoutsend.currency = this.wattetType;
      formToSubmit.value.cashoutsend.paymentMethod = this.paymentNewMethod; 
      

        console.log('Payload:', formToSubmit.value);

      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      console.log(formToSubmit.value.cashoutsend)
        this.playerSer.playerWithdraw(formToSubmit.value.cashoutsend)
        .subscribe(
            (apiRes) => {
          this.historyloader = false;
// this.historyloader1 = false;

          if (!apiRes.success) {
            let errorMessage = "Unknown error";
                if (apiRes['description'] === 'error' || apiRes["code"] === 'error') {
              errorMessage = this.lang === "FA" && apiRes.description.indexOf("User with email") === 0
                    ? "This email has been registered to another user. Use another email"
                    : apiRes['description'];
                } else {
                  errorMessage = apiRes['code'] ? apiRes["code"] : errorMessage;
                }
                this.setError(errorMessage);
              } else {
                this.store.dispatch(new cashierActions.CashierGetBalanceStart());
                this.profile1();
                this.setError(this.lang === "EN" ? appConstants.CASHOUT_SUCCESS_MSG_EN : appConstants.CASHOUT_SUCCESS_MSG_FA);
              }
},
            (err) => {
              this.historyloader = false;
              // this.historyloader1 = false;
              this.setError(err.statusText === 'error' ? 'This email is used by another user' : err.statusText);
            }
          );
      } else if (this.wattetType === "CHP") {

for(let i=0; i<this.payoutamount.length; i++){
  console.log(this.payoutamount[i] == this.selecteddata)
  console.log(this.payoutamount[i] )
  console.log(this.selecteddata)
  if(this.payoutamount[i] == this.selecteddata){
  console.log(1)
  }else{
    console.log(12)
  }
}
       


        formToSubmit.value.cashoutsend1.amount = String(this.selecteddata);
        formToSubmit.value.cashoutsend1.currency = this.wattetType;
        formToSubmit.value.cashoutsend1.paymentMethod = this.paymentNewMethod;

        console.log(formToSubmit.value.cashoutsend1)

        this.playerSer.playerWithdraw(formToSubmit.value.cashoutsend1)
          .subscribe(
            (apiRes) => {
              this.historyloader = false;
              // this.historyloader1 = false;

              if (!apiRes.success) {
                let errorMessage = "Unknown error";
                if (apiRes['description'] === 'error' || apiRes["code"] === 'error') {
                  errorMessage = this.lang === "FA" && apiRes.description.indexOf("User with email") === 0
                    ? "This email has been registered to another user. Use another email"
                    : apiRes['description'];
            } else {
              errorMessage = apiRes['code'] ? apiRes["code"] : errorMessage;
                }
                this.setError(errorMessage);
          } else {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
                this.profile1();
                this.setError(this.lang === "EN" ? appConstants.CASHOUT_SUCCESS_MSG_EN : appConstants.CASHOUT_SUCCESS_MSG_FA);
              }
            },
            (err) => {
              this.historyloader = false;
              // this.historyloader1 = false;
              this.setError(err.statusText === 'error' ? 'This email is used by another user' : err.statusText);
          }
        );
      }
    } else {
      this.setError("Amount can't be less than your balance");
    }
  }




  changePaymentMethod(amt) {
    this.historyloader = false;
    this.perfectMoneyForm.reset();
    this.errMsg = null;
    this.paymentMethod = amt;
    if (this.paymentMethod == '117')
      return this.getChipsAmount();
  }
  openPopup() {
    this.openAmountI = true
  }
  tabswitch() {
    if (localStorage.getItem("CHP") == "CHP") {
      this.chpBTN = true;
      this.AddUF('CHP')
    } else if (localStorage.getItem("USD") == "USD") {

      this.usdBTN = true;
      this.AddUF('USD')
    } else if (localStorage.getItem("TWO") == "TWO") {
      this.usdBTN = true
      this.chpBTN = true
      this.AddUF("USD")

    }

    this.profile1();
    this.historyloader = false;
    this.perfectMoneyForm.controls['PERFECT_VOUCHER_NUMBER'].setValue("");
    this.perfectMoneyForm.controls['PERFECT_VOUCHER_CODE'].setValue("");
    this.resetError();
  }
  changeAmtVal(value) {
    this.selecteddata = value.replaceAll(",", "");
    console.log(value)
    console.log(this.availableForPayout)
    console.log(Number(this.availableForPayout) <= Number(this.selecteddata))
    if (Number(this.availableForPayout) >= Number(this.selecteddata)) {
      this.cashOutBtn = false;
    } else if (Number(this.availableForPayout) < Number(value)) {
      this.cashOutBtn = true;
    } else {
      this.cashOutBtn = true;
    }
  }
  pendingCashout() {
    this.store.dispatch(new cashierActions.ResetState());
    this.store.dispatch(new cashierActions.CashierGetOpenWithDrawRequest());
  }

  routing() {
    this.router.navigate(['/bank']);
  }

  setActive(imageNumber: number) {
    this.isActive = imageNumber;
  }
  rivercashout(data) {
    this.paymentNewMethod = data
    if(this.paymentNewMethod == '117'){
      console.log("River")
      this.openRivercashout=true
      this.openCaspiancashout=false;
      this.setActive(1);
    }else{
      this.paymentNewMethod = data

      this.openRivercashout=false
  
      this.openCaspiancashout=true;
      this.setActive(2);
    }

  }

   
}





