import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DepositSystemsClass, TelecomProvider } from 'src/app/source/modules/cashier/depositSystems';
import { CashierGetDepositSystems, CashierDepositStart } from 'src/app/source/appstates/cashierstates/cashierActions';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Profile } from 'src/app/source/modules/player/profile';
import { DepositSystem } from 'src/app/source/modules/cashier/deposisystem';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css'],
})
export class DepositComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  private depositstoreSub:Subscription;
   DepositForm:FormGroup;
  private profilestoreSub: Subscription;
  form: FormGroup;
  mobilenumber: any;
  private profile: Profile;
  formResponseMessage: string;
  depositSystems: DepositSystemsClass;
  telecomProvider: TelecomProvider[];
  hideFormOnCouldAfrica: boolean = false;
  showtransactionId: boolean = false;
  chanelname: string = "";
  bonuscode: number = 0;
  transactionId: string = "";
  errormessage:string;
  slidePlayForm: FormGroup;
  DepositSystem:DepositSystem;
  depositerrormessage:string;
  
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  @ViewChild(UpdateProfileComponent, { static: false }) updatePro: UpdateProfileComponent;
  constructor(private store: Store<AppState>) {

    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  
  }
  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn; 
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.slidePlayForm = new FormGroup({
      "amount": new FormControl(null, Validators.required),
      "comments": new FormControl(null, Validators.minLength(4)),
      "paymentSystem": new FormControl("SLYDEPAY"),
      "currency": new FormControl("CHP"),
     // "bonuscode": new FormControl(null, Validators.pattern('[a-zA-Z0-9]*')),
    })

    this.DepositForm = new FormGroup({
      "amount": new FormControl(null, Validators.required),
      // "comments": new FormControl(null, Validators.minLength(4)),
      "paymentMethod": new FormControl(304),
      "currency": new FormControl("CHP"),
      "bonuscode": new FormControl(null, Validators.pattern('[a-zA-Z0-9]*')),
    })
    this.mobilenumber = sessionStorage.getItem('playermobilenumber');

    this.form = new FormGroup({});
    this.depositstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.DepositSystem) {
          if(cashierState.DepositSystem.success == true){
            this.DepositForm.reset();
            this.depositerrormessage="";
            window.location.replace(cashierState.DepositSystem.result.redirectUrl);
          }else{
           // this.depositerrormessage = cashierState.DepositSystem.description;
            this.depositerrormessage ="Please add your email in the update profile to deposit.";
          }
        }
        if(cashierState.DepositSystemError){
            this.depositerrormessage = cashierState.DepositSystemError.message;
        }
      });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.SlidePlayResponse) {
          if(cashierState.SlidePlayResponse.success == true){
            this.slidePlayForm.reset();
            window.location.replace(cashierState.SlidePlayResponse.redirectionUrl);
          }else{
            this.errormessage = cashierState.SlidePlayResponse.description;
          }
        }
        if(cashierState.SlidePlayErrorResponse){
            this.errormessage = cashierState.SlidePlayErrorResponse.message;
        }
      });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {

        if (cashierState.depositeSystem) {

          this.depositSystems = cashierState.depositeSystem.depositSystems;

          if (cashierState.depositeSystem.depositSystems) {
            if (cashierState.depositeSystem.depositSystems.additional) {
              this.telecomProvider = cashierState.depositeSystem.depositSystems.additional;
            }
          }

          if (this.depositSystems) {
            for (let frm of this.depositSystems.params) {
              if (frm.required) {
                this.form.addControl(frm.name, new FormControl('', Validators.required));
              } else {
                this.form.addControl(frm.name, new FormControl(''));
              }
            }
            if (this.depositSystems.limits) {
              for (let limits of this.depositSystems.limits) {
                try {
                  this.form.get('currency').setValue(limits.currency.code);
                } catch (e) { }
              }
            }
          }

          if (cashierState.depositeSystem.description) {
            this.formResponseMessage = cashierState.depositeSystem.description;
          }

        } else {
          this.depositSystems = null;
          this.telecomProvider = null;
          this.showtransactionId = false;
        }

        if (cashierState.depositResponse) {
          if (cashierState.depositResponse.success === true) {
            if (cashierState.depositResponse.result) {
              if (cashierState.depositResponse.result.form) {
                this.createFormToSubmitDeposit(cashierState.depositResponse.result.form);
                this.showtransactionId = false;
              }
              if (cashierState.depositResponse.result.redirectUrl) {
                let depositPaymentGateWay = window;
                depositPaymentGateWay.location.href = cashierState.depositResponse.result.redirectUrl;
              }
              if (cashierState.depositResponse.result.transactionId) {
                //TODO show transaction ID
                this.transactionId = cashierState.depositResponse.result.transactionId;
                this.showtransactionId = true;
              }
            }
          }
          if (cashierState.depositResponse.description) {
            this.formResponseMessage = cashierState.depositResponse.description;
          }
        }

        if (cashierState.depositFail) {
          this.formResponseMessage = cashierState.depositFail.message;
        }

        if (cashierState.depositeSystemResponseFail) {
          this.formResponseMessage = cashierState.depositeSystemResponseFail.message;
        }

      }
    )

    this.store.dispatch(new CashierGetDepositSystems({ id: 304 }));
  }
  createFormToSubmitDeposit(form) {
    let frm = document.createElement("form");

    for (let params in form.params) {
      let element = document.createElement("input");
      element.value = form.params[params];
      element.name = params;
      frm.appendChild(element);
    }

    frm.method = form.method;
    frm.action = form.url;
    document.body.appendChild(frm);
    frm.submit();
  }
  //onPaymentSelection(paymentName, selected) {
  // this.form.reset();
  //this.showtransactionId = false;
  // if (paymentName == 'CLOUD_AFRICA') {
  //   this.hideFormOnCouldAfrica = true;
  // } else {
  //   this.hideFormOnCouldAfrica = false;
  // }
  //this.store.dispatch(new CashierGetDepositSystems({ id: selected }));
  //}
  onClickProviderDeposit(provider) {
    this.chanelname = provider;
    this.hideFormOnCouldAfrica = false;
    this.formResponseMessage = "";
    this.form.value.amount.updateValue();
  }
  onFormSubmit() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.form.value.currency = 'CHP'
    this.store.dispatch(new CashierDepositStart(this.form.value));
  }
  onSlidePlayPayementsubmit(){
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierGetSlidePlayDepositSystems(this.slidePlayForm.value));
  }
  onDepositsubmit(){
    
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierGetCashierDepositSystems(this.DepositForm.value));
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

   
  updateProfile() {
    this.updatePro.openUpdatePop();
  }
  deposit() {
    
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    
  }
}
