import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { PlayerService } from '../player/player.service';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class MeightService {

  constructor(
    private playerService: PlayerService,
    private loginservice:LoginService, private store: Store<appState.AppState>, private http: HttpClient, private router: Router, private cookieService: CookieService) {
  }
 meightServiceData(data):Observable<any>{
   return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.meight}/${data}`)
 }
 onLogOut() {
  this.loginservice.onPlayerLoggedIn(false);
  sessionStorage.removeItem('accessToken');
  this.cookieService.deleteAll();
  sessionStorage.removeItem('profile');
  sessionStorage.removeItem('playermobilenumber');
  sessionStorage.clear();
  this.router.navigate(['/home']);
  this.store.dispatch(new loginActions.ResetState()); 
  this.store.dispatch(new cashierActions.ResetState());  
  this.store.dispatch(new loginActions.LogoutStart()); 
  return this.http.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());
}
TwowayonLogOut() {
  this.router.navigate(['/home']);
 this.loginservice.onPlayerLoggedIn(false);
 sessionStorage.removeItem('accessToken');
 this.cookieService.deleteAll();
 sessionStorage.removeItem('profile');
 sessionStorage.removeItem('playermobilenumber');
 sessionStorage.clear();
 this.store.dispatch(new loginActions.ResetState()); 
 this.store.dispatch(new cashierActions.ResetState()); 
 this.store.dispatch(new loginActions.LogoutStart()); 
 return this.http.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());

}
}
