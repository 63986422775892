import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { appConf } from 'src/app/source/config/app.config';
import { appConstants } from 'src/app/source/constants/appconstants';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Router } from '@angular/router';
import { NavigationComponent } from '../navigation/navigation.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { CasinoGamesService } from 'src/app/source/services/casino/casinogames.service';
import { CookieService } from 'ngx-cookie-service';
// import { ModalService } from './modal/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selectedlanguege = 'FA';
  lang: any = appConstants.language;
  showPop: boolean = false;
  appLinks: any = appConstants.appDownloadLinks;
  playerLoggedIn: boolean = false;
  private loginstoreSub: Subscription;
  navigationsuccessPop: boolean = false;
  oneClick: boolean = false;
  errorMessage: string;
  gamelunchmessage: string = "";
  redirectLoader: boolean = false;
  logPop: boolean = false;
  logName: any;
  sprtUrl: string;
  private allGamesLunchStore: Subscription;
  SprtsToken: any;
  headerCasinoGamesList: any = [];
  gamelunchurl: any;
  mrgameLunchUrl: any;
  IOSnote: boolean = false;
  isMobile: boolean = false;
  certificateLink: any = appConstants.appCertificatesLinks.mainPageCertificate;

  @ViewChild(NavigationComponent, { static: false }) loginpopup: NavigationComponent;
  wSession: any;
  showLoad: boolean = false;
  constructor(private cookieService: CookieService, private casinoGamesService: CasinoGamesService, private Playerservice: PlayerService, private sanitizer: DomSanitizer, public router: Router, private store: Store<appState.AppState>) {
    if (typeof window !== "undefined") {
    sessionStorage.removeItem('myPopupTwo')
    sessionStorage.removeItem('myPopup')
    }
    // this.casinoGamesService.getHeaderCasinoGames()
    //   .subscribe((gamesList) => {
    //     this.headerCasinoGamesList = gamesList["casinoGamesList"];
    //     console.log(this.headerCasinoGamesList)
    //   });
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    if (this.lang == null) {
      this.lang = 'FA'
    } else {
      if (typeof window !== "undefined") {
      this.lang = localStorage.getItem('locale');
      }
    }
    if (typeof window !== "undefined") {
    this.selectedlanguege = localStorage.getItem('locale');
    }
  
    // window.scroll(0, 150);

    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn == true) {
          }
        }
      }
    )
  }

  btnPop() {
    if (this.isMobile) {
      this.showPop = true;
    }
  }
  closeLogPop() {
    this.showPop = false;
  }

  ngOnInit() { 
    // window.scroll(0,0)
    this.allGamesLunchStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => { 
        if (loginState.allGamesLunch) {
          if (loginState.allGamesLunch.status == 200) {
            this.mrgameLunchUrl = loginState.allGamesLunch.gameUrl;
            if (this.mrgameLunchUrl) {
              this.slotsGameLunch();
            }

          } else if (loginState.allGamesLunch.status != 200) {
            this.navigationsuccessPop = true;
            if (loginState.allGamesLunch.description) {
              this.gamelunchmessage = loginState.allGamesLunch.description;
            }
            else {
              this.gamelunchmessage = "Unknown Error"
            }
          }
        }
        if (loginState.allGamesLunchError) {
          this.navigationsuccessPop = true;
          if (loginState.allGamesLunchError.message) {
            this.gamelunchmessage = loginState.allGamesLunchError.message;
          } else {
            this.gamelunchmessage = "Unknown Error"
          }
        }
      }
    )

  }
  launchLiveCasino() {
    if (!this.playerLoggedIn) {
      // this.openLogin();
      this.showPopup('Please login');
    } else {
      // this.router.navigate(['/live-casino']);
      this.casinoGamesService.launchLiveCasino()
        .subscribe((data) => {
          if (!data)
            this.showPopup('Error');
          else {
            let redirectionurl = data["VIVO_GAME_LAUNCH_URL"];
            if (typeof window !== "undefined") {
            return window.open(redirectionurl, '_self');}
            // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){

            //   return window.open(redirectionurl, '_self');
            // }else{
            //   }
            // return location.replace(redirectionurl);
            // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(redirectionurl); 

          }
        }, (err) => {
          this.showPopup(err);
        })
    }
  }
  openPokerTable(t: any) {
    console.log(t)
    if (this.playerLoggedIn) {
    if (typeof window !== "undefined") {
      if (this.isMobile) {
        window.open(this.appLinks.instaPlay, '_self')
      } else {
        if (t == 'old') {
          console.log(this.appLinks.instaPlay)
          window.open(this.appLinks.instaPlay, '_blank', 'width=400,height=900,resizable=0')
        } else {
          window.open(this.appLinks.instaPlayNew, '_blank', 'width=400,height=900,resizable=0')
        }
      }
    }
    } else {
      this.showPopup('Please login');

    }
  }
  showPopup(err) {
    this.errorMessage = err;
    this.navigationsuccessPop = true;
  }
  navigationPopClose() {
    this.navigationsuccessPop = false;
  }
  openLogin() {
    // this.loginpopup.openLoginDialog();
    this.showPopup('Please login');
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  sportsbetaccess() {
    if (this.playerLoggedIn == true) {
      if (typeof window !== "undefined") {
      let token = sessionStorage.getItem('accessToken');
      if (token == null) {
        let login = sessionStorage.getItem('profile');
        this.Playerservice.getSportsBookToken(login);
      } else {
        this.router.navigate(['/Sportsm']);
      }
    }
    } else if (this.playerLoggedIn == false) {
      this.router.navigate(['/Sportsm']);
    }

  }
  openLoginDialog() {
    this.logPop = true;
  }
  liveCasino() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
      this.showPopup('Please login');
    } else {
      // this.router.navigate(['/casinoh5'])
      if (typeof window !== "undefined") {
      this.router.navigate(['/liveDealer_2']);}
    }
  }
  launchJetx() {
    this.launchCasinoGame('smartsoft-jetx', 'SmartSoft')
  }


  launchCasinoGame(id, providername) {
    if (this.playerLoggedIn) {
      const body = {
        gameId: id,
        gameMode: 'real_play',
        provider: providername
      }
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
    } else {
      this.openLoginDialog();
    }
  }

  sportsRedirect() {
    if (this.playerLoggedIn == true) {
      this.showLoad = true;
      this.sportsData();
      this.redirectLoader = true;
    } else {
      this.openLoginDialog();
      this.showPopup('Please login');
    }
  }
  sportsData() {
    if (this.playerLoggedIn) {
      this.Playerservice.onPlayerGetProfile().subscribe(data => this.getSportData(data))
    } else {
      this.openLoginDialog();
    }
  }
  getSportData(data) {
    this.wSession = this.cookieService.get('wsession')
    console.log("Home 239", data)
    this.logName = { loginName: data.login, webSocketSessionId: this.wSession };
    this.Playerservice.sportsDataServe(this.logName).subscribe(data => this.launchSport(data, this.logName))
    this.redirectLoader = false;
  }

  launchSport(data, logName) {
    console.log("Home 245 launch", data);
    this.sprtUrl = data.data.address;
    this.SprtsToken = data.data.token;
    console.log(JSON.stringify(data) + "  " + data.login)
    console.log(data.data.token + "  " + data.login)
    // this.Playerservice.sportsSettionTkn(logName.loginName, this.SprtsToken).subscribe( data => { console.log( data ) } )
    // alert(" check Home 245 launch data ")
    this.showLoad = false;
    if (typeof window !== "undefined") {
    return window.open(this.sprtUrl, '_self');
    }
  }
  slotsGameLunch() {
    this.gamelunchurl = this.mrgameLunchUrl;
    if (typeof window !== "undefined") {
    return window.open(this.gamelunchurl, '_self');
    }
  }

  mojos() {
    if (!this.playerLoggedIn) {
      this.openLoginDialog();
    } else {
      this.router.navigate(['/liveDealer_2'])
    }
  }

  showNote() {
    this.IOSnote = !this.IOSnote;
  }
}

