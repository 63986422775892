import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import * as cashierActions from './cashierActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { CashierService } from '../../services/cashier/cashier.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Balance } from '../../modules/cashier/balance';
import { PaymentSystems } from '../../modules/cashier/paymentSystems';
import { DepositResponse } from '../../modules/cashier/depositResponse';
import { BankAccountInfo } from '../../modules/cashier/bankAccountInfo';
import { WithdrawSystems } from '../../modules/cashier/withDraw';

import {TransactionResponse, TransactionResponseForToken} from 'src/app/source/modules/cashier/transactionsresponsehistory';
import { withDrawRequestResponse } from '../../modules/cashier/withDrawRequestResponse';
import { LoginService } from '../../services/login/login.service';
import { SlideplayResponse } from '../../modules/cashier/slideplay';
import { DepositSystem } from '../../modules/cashier/deposisystem';
import { addBankAccountResponse } from '../../modules/cashier/addbankaccount';
import { WitdrawSelect } from '../../modules/cashier/witdrawselect';
const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "Something went wrong please contact admin" }));
        } catch (error) {

        }
    }
    return of(new actions({ "message": "Something went wrong please contact admin" }));
}

@Injectable()
export class CashierEffects {
    constructor(private actions$: Actions,
        private router: Router,
        private cashierservice: CashierService, 
        private loginservice: LoginService) { }

    @Effect()
    cashierGetBalance = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BALANCE),
        exhaustMap((onCashierGetBalanceStart: cashierActions.CashierGetBalanceStart) =>
            this.cashierservice.onCashierGetBalance()
                .pipe(
                    map((response: Balance) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetBalanceSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetBalanceFail);
                    })
                )
        )
    )
    @Effect({ dispatch: false })
    CashierGetBalanceSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BALANCE_SUCCESS),
        tap((onCashierGetBalanceSuccess:cashierActions.CashierGetBalanceSuccess)=>{
            // if(onCashierGetBalanceSuccess.payload.code === "SESSION_EXPIRED"
            //  || onCashierGetBalanceSuccess.payload.code === 'INVALID_SESSION_TOKEN'){
            //     this.loginservice.sessinExpireToLogout();
            // }
            if(onCashierGetBalanceSuccess.payload.success === true){
               this.loginservice.onPlayerLoggedIn(true);
            }
            
        })
    )
    @Effect({ dispatch: false })
    CashierGetBalanceFail = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BALANCE_FAIL),
        tap((onCashierGetBalanceFail:cashierActions.CashierGetBalanceFail)=>{
            if(onCashierGetBalanceFail.payload.code === "SESSION_EXPIRED"
            || onCashierGetBalanceFail.payload.code === 'INVALID_SESSION_TOKEN'){
                this.loginservice.sessinExpireToLogout();
            }   
            
        })
    )
    @Effect()
    cashierResetPlaymoney = this.actions$.pipe(
        ofType(cashierActions.CASHIER_RESET_PLAYMONEY),
        exhaustMap((onCashierRetsetPlaymoneyStart: cashierActions.CashierResetPlayMoneyStart) =>
            this.cashierservice.onCashierResetPlayMoney()
                .pipe(
                    map(response => { 
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierResetPlayMoneySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierResetPlayMoneyFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierResetPlaymoneySuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_RESET_PLAYMONEY_SUCCESS),
        tap((onCashierResetPlayerMoneySuccess: cashierActions.CashierResetPlayMoneySuccess) => {
            if (onCashierResetPlayerMoneySuccess.payload["success"] === true) {
            }
           
        })
    )

    @Effect()
    cashierGetPaymentSystem = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_PAYMENTS_SYSTEMS),
        exhaustMap((onCashierGetPaymentSystemsStart: cashierActions.CashierGetPaymentSystemsStart) =>
            this.cashierservice.onCashierGetPaymentSystems()
                .pipe(
                    map((response: PaymentSystems) => {   
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetPaymentSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetPaymentSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierDeposit = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DEPOSIT),
        exhaustMap((onCashierDepositStart: cashierActions.CashierDepositStart) =>
            this.cashierservice.onCashierDeposit(onCashierDepositStart.payload)
                .pipe(
                    map((response: DepositResponse) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierDepositSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierDepositFail);
                    })
                )
        )
    )

    @Effect()
    cashierGetOpenWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_OPEN_WITHDRAW_REQUEST),
        exhaustMap((onCashierGetOpenWithDrawRequest: cashierActions.CashierGetOpenWithDrawRequest) =>
            this.cashierservice.onCashierGetOpenWithdrawRequest()
                .pipe(
                    map(response => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetOpenWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetOpenWithDrawRequestFail);
                    })
                )
        )
    )

    @Effect()
    cashierWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_REQUEST),
        exhaustMap((onCashierWithDrawRequest: cashierActions.CashierWithDrawRequest) =>
            this.cashierservice.onCashierWithdrawRequest(onCashierWithDrawRequest.payload)
                .pipe(
                    map((response:withDrawRequestResponse) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithDrawRequestFail);
                    })
                )
        )
    );
    @Effect({ dispatch: false })
    CashierWithDrawRequestSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_REQUEST_SUCCESS),
        tap((onCashierWithDrawRequestSuccess: cashierActions.CashierWithDrawRequestSuccess) => {
            if (onCashierWithDrawRequestSuccess.payload.success === true) {
                this.cashierservice.onCashierWitdrawSystemSuccess();
            }
        })
    )


    @Effect()
    cashierCancelWithdrawRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST),
        exhaustMap((onCashierCancelWithDrawRequest: cashierActions.CashierCancelWithDrawRequest) =>
            this.cashierservice.onCashierCancelWithdrawRequest(onCashierCancelWithDrawRequest.payload)
                .pipe(
                    map(response => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierCancelWithDrawRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierCancelWithDrawRequestFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierCancelWithdrawRequestSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST_SUCCESS),
        tap((onCashierCancelWithDrawRequestSuccess: cashierActions.CashierCancelWithDrawRequestSuccess) => {
            if (onCashierCancelWithDrawRequestSuccess.payload["success"] === true) {
                this.cashierservice.onCashierCancelWitdrawSuccess();
            }
            if(onCashierCancelWithDrawRequestSuccess.payload["code"] === "SESSION_EXPIRED"){
                this.loginservice.sessinExpireToLogout();
            }
        })
    )
    @Effect({ dispatch: false })
    CashierCancelWithDrawRequestFail = this.actions$.pipe(
        ofType(cashierActions.CASHIER_CANCEL_WITHDRAW_REQUEST_FAIL),
        tap((onCashierCancelWithDrawRequestFail:cashierActions.CashierCancelWithDrawRequestFail)=>{
            if(onCashierCancelWithDrawRequestFail.payload["code"] === "SESSION_EXPIRED"){
                this.loginservice.sessinExpireToLogout();
            }   
        })
    )
    @Effect()
    cashierTransactionHistory = this.actions$.pipe(
        ofType(cashierActions.CASHIER_TRANSACTION_HISTORY),
        exhaustMap((onCashiercashierTransactionHistory: cashierActions.CashierTransactionHistory) =>
            this.cashierservice.onCashierTransactionHistory(onCashiercashierTransactionHistory.payload)
                .pipe(
                    map((response:TransactionResponse) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierTransactionHistorySuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierTransactionHistoryFail);
                    })
                )
        )
    )
    @Effect()
    CashierTransactionHistoryBYToken = this.actions$.pipe(
        ofType(cashierActions.CASHIER_TRANSACTION_HISTORY_TOKEN),
        exhaustMap((onCashierTransactionHistoryBYToken: cashierActions.CashierTransactionHistoryBYToken) =>
            this.cashierservice.onCashierTransactionHistoryBYToken(onCashierTransactionHistoryBYToken.payload)
                .pipe(
                    map((response:TransactionResponseForToken) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierTransactionHistoryBYTokenSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierTransactionHistoryBYTokenFail);
                    })
                )
        )
    )
    // @Effect({ dispatch: false })
    // cashierTransactionHistorySuccess = this.actions$.pipe(
    //     ofType(cashierActions.CASHIER_TRANSACTION_HISTORY_SUCCESS),
    //     tap((onCashierTransactionHistorySuccess: cashierActions.CashierTransactionHistorySuccess) => {
    //         if (onCashierTransactionHistorySuccess.payload.success === true) {
    //             //this.router.navigate(['/profile']);
    //         }
           
    //     })
    // )

    @Effect()
    cashierAddBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_ADD_BANK_ACCOUNT),
        exhaustMap((onCashierAddBankAccount: cashierActions.CashierAddBankAccount) =>
            this.cashierservice.onCashierAddBankAccount(onCashierAddBankAccount.payload)
                .pipe(
                    map((response : addBankAccountResponse)=> {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierAddBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierAddBankAccountFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierAddBankAccountSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_ADD_BANK_ACCOUNT_SUCCESS),
        tap((onCashierAddBankAccountSuccess: cashierActions.CashierAddBankAccountSuccess) => {
            if (onCashierAddBankAccountSuccess.payload["success"] === true) {
                this.cashierservice.getCashierBankAccounts();
            }
        })
    )

    @Effect()
    cashierGetBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_BANK_ACCOUNT),
        exhaustMap((onCashierGetBankAccount: cashierActions.CashierGetBankAccount) =>
            this.cashierservice.onCashierGetBankAccount()
                .pipe(
                    map((response: BankAccountInfo) => {
                        this.loginservice.sessionlogout(response); 
                        return new cashierActions.CashierGetBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetBankAccountFail);
                    })
                )
        )
    )
    @Effect()
    cashierDeleteBankAccount = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DELETE_BANK_ACCOUNT),
        exhaustMap((onCashierDeleteBankAccount: cashierActions.CashierDeleteBankAccount) =>
            this.cashierservice.onCashierDeleteBankAccount(onCashierDeleteBankAccount.payload)
                .pipe(
                    map((response: BankAccountInfo) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierDeleteBankAccountSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierDeleteBankAccountFail);
                    })
                )
        )
    )

    @Effect({ dispatch: false })
    cashierDeleteBankAccountSuccess = this.actions$.pipe(
        ofType(cashierActions.CASHIER_DELETE_BANK_ACCOUNT_SUCCESS),
        tap((onCashierDeleteBankAccountSuccess: cashierActions.CashierDeleteBankAccountSuccess) => {
            if (onCashierDeleteBankAccountSuccess.payload["success"] === true) {
                this.cashierservice.getCashierBankAccounts();
            }
        })
    )

    @Effect()
    cashierGetDepositSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_DEPOSIT_SYSTEMS),
        exhaustMap((onCashierGetDepositSystems: cashierActions.CashierGetDepositSystems) =>
            this.cashierservice.onCashierGetDepositSystems(onCashierGetDepositSystems.payload)
                .pipe(
                    map(response => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetDepositSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetDepositSystemsFail);
                    })
                )
        )
    )

    
    @Effect()
    CashierGetSlidePlayDepositSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_SLIDEPLAY_DEPOSIT_SYSTEMS),
        exhaustMap((onCashierGetSlidePlayDepositSystems: cashierActions.CashierGetSlidePlayDepositSystems) =>
            this.cashierservice.onCashierGetSlidePlayDepositSystems(onCashierGetSlidePlayDepositSystems.payload)
                .pipe(
                    map((response :SlideplayResponse) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetSlidePlayDepositSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetSlidePlayDepositSystemsFail);
                    })
                )
        )
    )
    @Effect()
    CashierGetCashierDepositSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_CASHIER_DEPOSIT_SYSTEMS),
        exhaustMap((onCashierGetCashierDepositSystems: cashierActions.CashierGetCashierDepositSystems) =>
            this.cashierservice.onGetCashierDeposit(onCashierGetCashierDepositSystems.payload)
                .pipe(
                    map((response :DepositSystem) => {
                        return new cashierActions.CashierGetCashierDepositSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetCashierDepositSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierGetWithdrawSystems = this.actions$.pipe(
        ofType(cashierActions.CASHIER_GET_WITHDRAW_SYSTEMS),
        exhaustMap((onCashierGetWithdrawSystems: cashierActions.CashierGetDepositSystems) =>
            this.cashierservice.onCashierGetWithdrawSystems(onCashierGetWithdrawSystems.payload)
                .pipe(
                    map((response:WithdrawSystems) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierGetWithdrawSystemsSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierGetWithdrawSystemsFail);
                    })
                )
        )
    )

    @Effect()
    cashierWithdrawCashout = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_CASHOUT),
        exhaustMap((onCashierWithdrawCashout: cashierActions.CashierWithdrawCashout) =>
            this.cashierservice.onCashierWithdrawCashout(onCashierWithdrawCashout.payload)
                .pipe(
                    map(response => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierWithdrawCashoutSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithdrawCashoutFail);
                    })
                )
        )
    )
    @Effect()
    CashierWithdrawOTPRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_OTP_REQUEST),
        exhaustMap((onCashierWithdrawOTPRequest: cashierActions.CashierWithdrawOTPRequest) =>
            this.cashierservice.onCashierWithdrawOtpRequest(onCashierWithdrawOTPRequest.payload)
                .pipe(
                    map(response => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierWithdrawOTPRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithdrawOTPRequestFail);
                    })
                )
        )
    )
    @Effect()
    CashierWithdrawSelectRequest = this.actions$.pipe(
        ofType(cashierActions.CASHIER_WITHDRAW_SELECT_REQUEST),
        exhaustMap((onCashierWithdrawSelectRequest: cashierActions.CashierWithdrawSelectRequest) =>
            this.cashierservice.onCashierWithdrawSelectRequest()
                .pipe(
                    map((response:WitdrawSelect) => {
                        this.loginservice.sessionlogout(response);
                        return new cashierActions.CashierWithdrawSelectRequestSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, cashierActions.CashierWithdrawSelectRequestFail);
                    })
                )
        )
    )

}