import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Subscription } from 'rxjs';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { TBankAccountInfo } from 'src/app/source/modules/cashier/bankAccountInfo';

import { WitdrawSelect, Datum } from 'src/app/source/modules/cashier/witdrawselect';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
declare var $: any;
@Component({
  selector: 'app-bankaccount',
  templateUrl: './bankaccount.component.html',
  styleUrls: ['./bankaccount.component.css']
})
export class BankaccountComponent implements OnInit {
  accountloader:boolean=false;
  private cashierwithdrawstoreSub: Subscription;
  private loginSub: Subscription;
  form: FormGroup;
  private storeSub: Subscription;
  private viewstoreSub: Subscription;
  addbankerrormessage: string;
  successmessage: string;
  bankAccountInfos: TBankAccountInfo[]; WitdrawSelect: WitdrawSelect;
  witdrawselectdata: Array<Datum>;
  historyloader: boolean = true;
  carderrormessage: boolean = false;
  cardone: boolean = false;
  cardtwo: boolean = false;
  cardthree: boolean = false;
  cardfour: boolean = false;
  lengtherrormessage: boolean = false;
  firstone:number;
  secondone:number;
  thirdone:number;
  fourthone:number;
  playerLoggedIn:boolean=false;
  selectdata: any = [ 
    { name: 'Savings', value: 1 },
    { name: 'Current', value: 2 },
  ];
  // acDetails : any = [
  //   {Name: '', Number: '', Bname: ''}
  // ];
  errormessage: string;
  accountinfo: any;
  mailId:string;
  constructor(private store: Store<appState.AppState>) {
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.store.dispatch(new cashierActions.ResetState());
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    $(".inputs").keyup(function () {
      if (this.value.length == this.maxLength) {
        $(this).next('.inputs').focus();
      }
    });
    this.form = new FormGroup({
      // "NameOnAccount": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
       "NameOnAccount": new FormControl(null, [Validators.required, Validators.minLength(13), Validators.maxLength(16), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      "bankName": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('^[a-zA-Z ]*$')]),
      // "NameOnAccount1": new FormControl(null, Validators.required),
      // "NameOnAccount2": new FormControl(null, Validators.required),
      // "NameOnAccount3": new FormControl(null, Validators.required),
      // "NameOnAccount4": new FormControl(null, Validators.required),
      // "bankAccountNumber": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{24}')]),
      "bankAccountNumber": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(32)]),
      "personalNumber": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{24}')]),
      // "bankIFSCCOde": new FormControl(null, [Validators.required, Validators.pattern('^[A-Za-z]{4}[a-zA-Z0-9]{13,19}$')]),
      // "NameOnCard": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      //"bankName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      //"accountBranchName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "accountType": new FormControl(null, [Validators.required, Validators.minLength(5), Validators.pattern('^[a-zA-Z ]*$')]),
      //"personalNumber": new FormControl(null, [Validators.required, Validators.pattern('^[A-Za-z]{4}[a-zA-Z0-9]{7}$')]),
    });
    // this.form.valueChanges.subscribe(form => {
    //   if (this.form.value.NameOnAccount1) {
    //     let first = Number(this.form.value.NameOnAccount1);
    //     let res = Number.isInteger(first);
    //     if (res == true) {
    //       this.firstone=first;
    //       this.carderrormessage = false;
    //       this.cardone = true;
    //     } else {
    //       this.carderrormessage = true;
    //       this.cardone = false;
    //     }
    //   }
    //   if (this.form.value.NameOnAccount2) {
    //     let second = Number(this.form.value.NameOnAccount2);
    //     let res = Number.isInteger(second);
    //     if (res == true) {
    //       this.secondone=second;
    //       this.carderrormessage = false;
    //       this.cardtwo = true;
    //     } else {
    //       this.carderrormessage = true;
    //       this.cardtwo = false;
    //     }
    //   }
    //   if (this.form.value.NameOnAccount3) {
    //     let third = Number(this.form.value.NameOnAccount3);
    //     let res = Number.isInteger(third);
    //     if (res == true) {
    //       this.thirdone = third;
    //       this.carderrormessage = false;
    //       this.cardthree = true;
    //     } else {
    //       this.carderrormessage = true;
    //       this.cardthree = false;
    //     }
    //   }
    //   if (this.form.value.NameOnAccount4) {
    //     let fourth = Number(this.form.value.NameOnAccount4);
    //     let res = Number.isInteger(fourth);
    //     if (res == true) {
    //       this.fourthone = fourth;
    //       this.carderrormessage = false;
    //       this.cardfour = true;
    //     } else {
    //       this.carderrormessage = true;
    //       this.cardfour = false;
    //     }
    //   }
    //   if (form) {
    //     let value = this.firstone + "" + this.secondone + "" + this.thirdone + "" + this.fourthone;
    //     if (value.length >=13) {
    //       this.lengtherrormessage = false;
    //     } else if(value.length < 13){
    //       this.lengtherrormessage = true;
    //     }
    //   }
    // });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.addBankAccountResponse) {
          if (cashierState.addBankAccountResponse.success === true) {
            this.addbankerrormessage = "";
            //this.successmessage = cashierState.addBankAccountResponse.description;
            this.form.reset(); 
            this.historyloader = false;
          } else if (cashierState.addBankAccountResponse.success === false) {
            this.successmessage = "";
            this.historyloader = false;
            this.addbankerrormessage = cashierState.addBankAccountResponse.description;
          }
        }
        if (cashierState.addBankAccountError) {
          this.successmessage = "";
          this.historyloader = false;
          this.addbankerrormessage = cashierState.addBankAccountError.message;
        }
      });
    this.viewstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.bankAccountInfo) {
          if (cashierState.bankAccountInfo.success == true) {
            if (cashierState.bankAccountInfo.TBankAccountInfos) {
              // var name1 = cashierState.bankAccountInfo.TBankAccountInfos[0].nameOnBank
              // this.acDetails[0].Name =  name1.replace('Name :','').substring(0, name1.length - 31)
              // this.acDetails[0].Number =  cashierState.bankAccountInfo.TBankAccountInfos[0].bankAccountNumber;
              // this.acDetails[0].Bname =  cashierState.bankAccountInfo.TBankAccountInfos[0].bankName;
              this.historyloader = false;
              this.errormessage = "";
              this.accountinfo = cashierState.bankAccountInfo.activeBankCount;            
              this.bankAccountInfos = cashierState.bankAccountInfo.TBankAccountInfos;
              this.mailId = cashierState.bankAccountInfo.mailId; 
            }
          } else if (cashierState.bankAccountInfo.success == false) {
            this.historyloader = false;
            if( cashierState.bankAccountInfo.code === 'INVALID_SESSION_TOKEN'){

              this.errormessage = 'Invalid session token';
            }else{
              this.errormessage = cashierState.bankAccountInfo.description;

            }
          }
        }
        if (cashierState.bankAccountInfoError) {
          this.historyloader = false;
          this.errormessage = cashierState.bankAccountInfoError.message;
        }
      })
    this.cashierwithdrawstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.witdrawselect) {
          if (cashierState.witdrawselect.status == true) {
            if (cashierState.witdrawselect.data)
              this.witdrawselectdata = cashierState.witdrawselect.data;
          } else if (cashierState.withDrawRequestResponse.success == false) {
          }
        }

      }
    );

    this.store.dispatch(new cashierActions.CashierGetBankAccount());
    this.store.dispatch(new cashierActions.CashierWithdrawSelectRequest());
  }

  onInput() {
    if ($(window).width() < 660 && $(window).height() < 400) {
      $("input").focusin(function () {
        window.scroll(0, $(this).offset().top - 20);
        $('.fixMenu').css("position", "relative");
        $('.body_m').css("margin-top", "0px");
      });
      $("input").focusout(function () {
        $('.fixMenu').css("position", "fixed");
        $('.body_m').css("margin-top", "52px");
      });
    }
  }

  onFormSubmit() {
    // for(let data of this.witdrawselectdata){
    //   if(data.code == this.form.value.accountType){
    //         this.form.value.bankName = data.name;

    this.historyloader = true;

    const data = {
      // NameOnAccount: this.form.value.accountType + "," + this.form.value.NameOnAccount,
      nameOnAccount: "Name : " +  this.form.value.accountType + " CardNo : " + this.form.value.NameOnAccount,
      bankName: this.form.value.bankName,
      // NameOnAccount : this.form.value.NameOnAccount+ "," + this.form.value.NameOnCard,
      bankAccountNumber: this.form.value.bankAccountNumber,
      accountType: this.form.value.accountType,
      personalNumber: this.form.value.personalNumber,
      // NameOnCard : this.form.value.NameOnCard,
    } 
    console.log( data )
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierAddBankAccount(data));
  
    // }
    //}
  }
  onCloseAccount(encodedId) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    let obj = { "bankEncodedIDs": [encodedId] }
    this.store.dispatch(new cashierActions.CashierDeleteBankAccount(obj));
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.viewstoreSub) {
      this.viewstoreSub.unsubscribe();

    }
    if (this.cashierwithdrawstoreSub) {
      this.cashierwithdrawstoreSub.unsubscribe();
    }
  }

}
