import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './skins/navigation/navigation.component';
import { LiveMatchComponent } from './skins/live-match/live-match.component';
import { PokerComponent } from './skins/poker/poker.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import { HistoryComponent } from './skins/history/history.component';
import { ResultsComponent } from './skins/results/results.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';

import { BankaccountComponent } from './skins/bankaccount/bankaccount.component';
import { RouteauthGuard } from './routeauth.guard';
import { HomeComponent } from './skins/home/home.component';
import { DashboardComponent } from './skins/dashboard/dashboard.component';
import { TournamentsComponent } from './skins/tournaments/tournaments.component';
import { CasinogamesComponent } from './skins/casinogames/casinogames.component';
import { RussionpokerComponent } from './skins/russionpoker/russionpoker.component';
import { RemoteHistoryComponent } from './skins/remote-history/remote-history.component';
import { PokerHistoryComponent } from './skins/poker-history/poker-history.component';
import { CasinoComponent } from './skins/casino/casino.component';
import { LivecasinoComponent } from './skins/livecasino/livecasino.component';
import { ProfileComponent } from './skins/profile/profile.component';
import { CashierComponent } from './skins/cashier/cashier.component';
import { ExchangeComponent } from './skins/exchange/exchange.component';
import { SportbookhistoryComponent } from './skins/sportbookhistory/sportbookhistory.component';
import { RussianpokerhistoryComponent } from './skins/russianpokerhistory/russianpokerhistory.component';
import { BackgammonhistroyComponent } from './skins/backgammonhistroy/backgammonhistroy.component';
import { ComingsoonComponent } from './skins/comingsoon/comingsoon.component';
import { PtoptransfersComponent } from './skins/ptoptransfers/ptoptransfers.component';
import { MrslottyComponent } from './skins/mrslotty/mrslotty.component';
import { IosInstallationComponent } from './skins/ios-installation/ios-installation.component';
import { IndieCasinoGamesComponent } from './skins/indie-casino-games/indie-casino-games.component';
import { LuckyStreakComponent } from './skins/lucky-streak/lucky-streak.component';
import { LiveDealarComponent } from './skins/live-dealar/live-dealar.component';
import { NewSlotsNcrashComponent } from './skins/new-slots-ncrash/new-slots-ncrash.component';
import { VivogamesComponent } from './vivogames/vivogames.component';
import { RefferalComponent } from './skins/refferal/refferal.component';
import { TwoFactorComponent } from './skins/two-factor/two-factor.component';
import { ExchangepopupComponent } from './skins/exchangepopup/exchangepopup.component';
import { ExCurrencyComponent } from './skins/ex-currency/ex-currency.component';
import { SitemapComponent } from './skins/sitemap/sitemap.component';
import { ExcurrencypopupComponent } from './skins/excurrencypopup/excurrencypopup.component';
import { CrashComponent } from './skins/crash/crash.component';
const routes: Routes = [
  {
    path: "", component: NavigationComponent,

    children: [

      { path: "", redirectTo: 'home', pathMatch: "full" },
      { path: "home", component: HomeComponent },
      { path: "passwordupdate", component: HomeComponent },
      { path: "Upadtepassword", component: HomeComponent },
      { path: "activate", component: HomeComponent },
      { path: "referrer", component: RefferalComponent },
      { path: "twofactor", component: TwoFactorComponent },
      { path: "exchangeCurrencyPopup", component: ExcurrencypopupComponent },

      // { path: "casinoh5", component: LiveDealarComponent },
      // { path: "new_games", component: NewSlotsNcrashComponent },
      {
        path: "liveDealer_2", component: NewSlotsNcrashComponent,
        // children: [{ path: "vivogames", component: VivogamesComponent }]
      },
      { path: "vivogames", component: VivogamesComponent },
      // { path: "MT_page", component: VivogamesComponent },
      // { path: "vivo", component: LiveDealarComponent },
      // { path: "casinoh5", component: NewSlotsNcrashComponent },
      { path: "liveDealer_3", component: LiveDealarComponent },
      { path: "live-casino", component: HomeComponent },
      { path: "live-casino2", component: HomeComponent },
      // { path: "jetx", component: HomeComponent },
      { path: "Sportsm", component: HomeComponent },
      { path: "livedealer", component: HomeComponent },
      { path: "register", component: HomeComponent },
      { path: "supportc", component: HomeComponent },
      { path: "tournaments", component: TournamentsComponent },
      { path:"crash", component: CrashComponent},
      // { path: "sitemap", component: SitemapComponent },
      // { path: "poker", component: PokerComponent },  
      // { path: "newSlotGames", component: CasinoComponent },
      { path: "casinoh5", component: CasinoComponent },
      // { path:"indieCasino", component:IndieCasinoGamesComponent},
      { path: "lucky_streak", component: LuckyStreakComponent },
      // { path: "liveDealer_2", component: MrslottyComponent },
      { path: "iosInstallation", component: IosInstallationComponent },
      // { path: 'goldenrace', component: GoldenraceComponent }, 
      // { path: 'live-casino', component: LivecasinoComponent },
      // { path: "Withdrawal", component: WithdrawalComponent },
      // { path: "casinoHistory", component: GameHistoryComponent },
      // { path: "remoteHistory", component: RemoteHistoryComponent },
      // { path: "pokerHistory", component: PokerHistoryComponent },
      // { path: "cashOut", component: CashOutHistoryComponent },
      // { path: "transaction", component: TransactionHistoryComponent },
      // { path: "deposit", component: DepositComponent },
      // { path: "balance", component: BalanceComponent},
      // { path: "games", component: RussionpokerComponent },
      // { path: "bankaccount", component: BankaccountComponent},
      // { path: ":name", component: DashboardComponent,
      // canActivate:[RouteauthGuard]
      // },
      {
        path: "", component: DashboardComponent,
        children: [
          { path: 'history', component: HistoryComponent },
          { path: "specification", component: ProfileComponent },
          { path: "profile", component: ProfileComponent },
          { path: "bank", component: BankaccountComponent },
          { path: "deposit", component: CashierComponent },
          { path: "cashier", component: CashierComponent },
          { path: "p2p-transfer", component: PtoptransfersComponent },
          { path: "ptoptransfer", component: PtoptransfersComponent },
          { path: "cashout", component: CashierComponent },
          { path: "balance", component: BalanceComponent },
          { path: "exchange", component: ExchangeComponent },
          { path: "vip-points-exchange", component: ExchangeComponent },
          { path: "pokerHistory", component: PokerHistoryComponent },
          { path: "casinohistory", component: RemoteHistoryComponent },
          { path: "sportbookhistory", component: HistoryComponent },
          // { path: "sportbookhistory", component: SportbookhistoryComponent },
          { path: "backgammonhistory", component: BackgammonhistroyComponent },
          { path: "russianpokerhistory", component: RussianpokerhistoryComponent },
          { path: "transaction", component: TransactionHistoryComponent },
          { path: "transactions", component: TransactionHistoryComponent },

          // { path: "exchangeCurrency", component: ExchangeComponent },        
          { path: "exchangeCurrency", component: ExCurrencyComponent },

        ]
      },
    ],

  },

  { path: "depositresponse", component: DepositresponseComponent },
  { path: '**', component: PagenotfoundComponent }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }