import { DomainConf } from './DomainSwitchConfig';

export const appConf = {

    "lskey": "riverpoker",
    "wsessionIdKey": "wsession",
    siteId: DomainConf.getDomainConfigData().siteId,
    "captchaInfoKey": "captchainfokey",
    "countriesDataKey": "countriesData",
    "preferredCurrency": DomainConf.getDomainConfigData().currency,// CHP FOR riverpoker, CHP FOR POKERMOGULS ,GHS FOR WINNABETS 
    "Userdatakeyforcookie": "USER_DATA",
    "minAgeForBday": 18,
    nothingToDisplayMsg: "No value",
    paymentMethod: 304,
    bonuscode: "",
    rowPerPage: 99,
    defaultRoute: "home",
    appLanguages: [{ langid: "en", langname: "English" }, { langid: "fr", langname: "French" }],
    currentLanguageKey: "i18nlangkey",
    defaultLanguage: "en",
    vipPointsKey: "COMPPOINTS",
    htmlGames: ["BLACKJACK", "EUROPEANROULETTE", "BACCARAT", "JACKSORBETTER"],
    turnOnFilterForHtmlGames: true
}