import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
//import { type } from 'os';
import { TransactionResponse } from '../../source/modules/cashier/transactionsresponsehistory';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConstants } from 'src/app/source/constants/appconstants';
import { filter } from 'rxjs/operators';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
  providers: []
})
export class TransactionHistoryComponent implements OnInit, OnDestroy {

  private storeSub: Subscription;
  private loginSub: Subscription;
  typeForm: FormGroup;
  form: FormGroup;
  selectedType: any;
  transaction: any;
  depositsres: any;
  cashoutsres: any;
  bonusres: any;
  errormessage: string = "";
  types = [
    {
      language: 'Deposits',
      senttype: "Deposits"
    },
    {
      language: 'Cashout',
      senttype: "Cashout"
    },
    {
      language: 'Bonus Adjustment',
      senttype: "Bonus Adjustment"
    }
  ];
  // types = ["Deposits", "Cash Adjustment", "Chargeback",
  // "Cashout", "Bonus Adjustment", "Transfer Cancellation",
  // "Transfer to Agent", "Transfer from Agent", "Transfer from Player",
  // "Transfer to Player", "Referring Revenue", "Cashout Reverse",
  // "Manual Deposit"]
  //typesValue="Deposit,Cash Adjustment,Chargeback,Cashout,Bonus Adjustment,Transfer Cancellation,Transfer to Agent,Transfer from Agent,Transfer from Player,Transfer to Player,Referring Revenue,Cashout Reverse,Manual Deposit"

  startDate: Date;
  todaydate: any;
  endDate: Date;
  p: number = 1;
  d: number = 1;
  b: number = 1;
  historyloader: boolean = false;
  selectnum: number = 10;
  cashselectnum: number = 10;
  bonusselectnum: number = 10;
  description: boolean = false;
  playerLoggedIn: boolean = false;
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate: any;
  selectedtypedata: any;
  lang: any = appConstants.language;
  constructor(private fb: FormBuilder, private store: Store<appState.AppState>) {
    if (this.lang == "EN") {
      this.types = [
        {
          language: 'Deposits',
          senttype: "Deposits"
        },
        {
          language: 'Cashout',
          senttype: "Cashout"
        },
        {
          language: 'Bonus Adjustment',
          senttype: "Bonus Adjustment"
        }
      ];
    } else {
      this.types = [
        {
          language: 'واریز',
          senttype: "Deposits"
        },
        {
          language: 'برداشت',
          senttype: "Cashout"
        },
        {
          language: ' بونوس',
          senttype: "Bonus Adjustment"
        }
      ];
    }

    this.store.dispatch(new cashierActions.ResetState());
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });
    this.endDate = new Date();
    let oneweek = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
    this.startDate = oneweek;

    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.typeForm = this.fb.group({
      typesValue: this.fb.array([])
    })
    this.form = new FormGroup({
      "currency": new FormControl('CHP', Validators.required),
      "startDate": new FormControl('', Validators.required,),
      "endDate": new FormControl('', Validators.required),
      "limit": new FormControl(100, Validators.required),
      "index": new FormControl(0, Validators.required)
    });
    this.form.valueChanges.subscribe(x => {
      let yearsDiff = 0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if (new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate = true;
      } else {
        this.steddate = false;
      }
    });
    this.form.value.startDate = this.startDate;
    this.form.value.endDate = this.endDate;
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.TransactionResponseForToken) {
          this.historyloader = false;
          if (cashierState.TransactionResponseForToken.success) {
            this.selectedType.find(type => {
              if (type == 'Deposits') {
                if (cashierState.TransactionResponseForToken.deposits.length) {
                  this.depositsres = cashierState.TransactionResponseForToken.deposits;
                }
              }

              if (type == 'Cashout') {
                if (cashierState.TransactionResponseForToken.cashouts.length) {
                  this.cashoutsres = cashierState.TransactionResponseForToken.cashouts;
                }
              }
              if (type == 'Bonus Adjustment') {
                if (cashierState.TransactionResponseForToken.bonus.length) {
                  this.bonusres = cashierState.TransactionResponseForToken.bonus;
                }
              }
            })
          } else {
            if (cashierState.TransactionResponseForToken.description == 'NO_DATA_FOUND' || cashierState.TransactionResponseForToken.description == 'TECHNICAL_ERROR') {
              this.errormessage = "There is no History  for the selected Period";
              return;
            }
            this.errormessage = cashierState.TransactionResponseForToken.description;
          }
        }
        if (cashierState.TranscationResponseForTokenFail) {
          this.errormessage = cashierState.TranscationResponseForTokenFail.message;
        }
        if (cashierState.TransactionResponse) {
          //this.historyloader = false;
          if (cashierState.TransactionResponse.success) {
            if (cashierState.TransactionResponse.values) {
              if (!cashierState.TransactionResponse.values.length) {
                this.description = true;
                this.errormessage = "There is no History  for the selected Period";
              } else {
                this.transaction = cashierState.TransactionResponse.values;
              }
            }
          } else {
            if (cashierState.TransactionResponse.description == 'NO_DATA_FOUND') {
              this.errormessage = "There is no History  for the selected Period";
              return;
            }
            this.errormessage = cashierState.TransactionResponse.description;
          }
        }
        if (cashierState.TranscationResponseFail) {
          this.errormessage = cashierState.TranscationResponseFail.message;
        }

      }
    )

    this.types.forEach(value => this.onChangetype(value.senttype, true));
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    // this.onFormSubmit();
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }


  get today() {
    return new Date();
  }

  onChangetype(typesValue: string, isChecked: boolean) {
    const typesFormArray = <FormArray>this.typeForm.controls.typesValue;
    if (isChecked) {
      typesFormArray.push(new FormControl(typesValue));
    } else {
      let index = typesFormArray.controls.findIndex(x => x.value == typesValue)
      typesFormArray.removeAt(index);
    }
    this.selectedType = this.typeForm.value.typesValue;
  }
  onFormSubmit() {

    this.store.dispatch(new cashierActions.ResetState());
    if (this.selectedType == '') {
      this.errormessage = 'Please select any type from checklist';
    } else {
      this.historyloader = true;
      const body = {
        currency: this.form.value.currency,
        startDate: moment(this.form.value.startDate).format('DD-MM-YYYY'),
        endDate: moment(this.form.value.endDate).format('DD-MM-YYYY'),
        limit: this.form.value.limit,
        index: this.form.value.index,
        type: this.selectedType.toString()

      };
      this.transaction = this.errormessage = this.depositsres = this.cashoutsres = this.bonusres = null;
      this.description = false;
      this.p = 1;
      this.d = 1;
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.store.dispatch(new cashierActions.CashierTransactionHistoryBYToken(body));

      // let bonusadjustvalue = this.selectedType.filter(this.bonusadjustment);
      // if (bonusadjustvalue == "Bonus Adjustment") {
      //   const body = {
      //     currency: this.form.value.currency,
      //     startDate: moment(this.form.value.startDate).format('MM-DD-YYYY'),
      //     endDate: moment(this.form.value.endDate).format('MM-DD-YYYY'),
      //     limit: this.form.value.limit,
      //     index: this.form.value.index,
      //     type: "Bonus Adjustment"
      //   };
      //   this.store.dispatch(new cashierActions.CashierTransactionHistory(body));
      // }
      // let indexDep = this.selectedType.indexOf('Deposits');
      // let indexCash = this.selectedType.indexOf('Cashout');
      // if(indexDep != -1 && indexCash != -1){
      // this.selectedtypedata = "Deposits, Cashout";

      // }else if(indexDep != -1){

      //   this.selectedtypedata = "Deposits";
      // }else if(indexCash != -1){

      //   this.selectedtypedata = "Cashout";
      // };
      // if(indexDep != -1 || indexCash != -1){
      //   const body = {
      //     currency: this.form.value.currency,
      //     startDate: moment(this.form.value.startDate).format('DD-MM-YYYY'),
      //     endDate: moment(this.form.value.endDate).format('DD-MM-YYYY'),
      //     limit: this.form.value.limit,
      //     index: this.form.value.index,
      //     type: this.selectedtypedata

      //  };
      //     this.store.dispatch(new cashierActions.CashierTransactionHistoryBYToken(body));
      // }

    }
  }
  bonusadjustment(value, index, array) {
    if (value == "Bonus Adjustment") {
      return true;
    }
  }
  onChange(data) {
    this.d = 1;
    this.selectnum = data;
  }
  cashoutChange(data) {
    this.p = 1;
    this.cashselectnum = data;
  }
  bonusChange(data) {
    this.b = 1;
    this.bonusselectnum = data;
  }

}
