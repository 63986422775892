import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetRemoteGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { RemoteGamesHistory } from 'src/app/source/modules/remotegamehistory/remotegamehistory';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';

@Component({
  selector: 'app-russianpokerhistory',
  templateUrl: './russianpokerhistory.component.html',
  styleUrls: ['./russianpokerhistory.component.css']
})
export class RussianpokerhistoryComponent implements OnInit {
  private profileRemotestoreSub: Subscription;
  private loginSub: Subscription;
  remoteform: FormGroup;
  profile: string;
  startDate: Date;
  todaydate: any;
  endDate: Date;
  RemoteGamesHistory: RemoteGamesHistory;
  remotehistory: any;
  historyloader: boolean = false;
  description: string;
  p: number = 1;
  selectnum: number = 10;
  itemsperpagecount = [
    { num: 10 },
    { num: 20 },
    { num: 30 },
    { num: 40 },
    { num: 50 }
  ];
  steddate:any;
  playerLoggedIn:boolean=false;
  totalrecords: number;
  remoteGameHistoryErrorMessage: string;
  constructor(private store: Store<appState.AppState>) {

    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        if(this.playerLoggedIn){
          this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        }
      }
    });
    this.todaydate = moment(new Date()).format('YYYY-MM-DD');
    this.profile = sessionStorage.getItem('profile');
    this.endDate = new Date();
    let today = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 1);
    this.startDate = today;
    this.remoteform = new FormGroup({
      "currency": new FormControl("CHP", Validators.required),
      "startDate": new FormControl(this.startDate, Validators.required),
      "endDate": new FormControl(this.endDate, Validators.required),
      "name": new FormControl('sportbook'),
      "limit": new FormControl(1000, Validators.required),
      "index": new FormControl(0, Validators.required),
    });
  
    this.remoteform.valueChanges.subscribe(x => {
      let yearsDiff =0;
      var ToDate = new Date();
      var pastYear = ToDate.getFullYear() - yearsDiff;
      ToDate.setFullYear(pastYear);
      if(new Date(x.startDate).getTime() > ToDate.getTime() || new Date(x.startDate).getTime() > new Date(x.endDate).getTime() || new Date(x.endDate).getTime() > ToDate.getTime()) {
        this.steddate= true;
      }else{
         this.steddate= false;
      }
   });
    // this.onremoteFormSubmit();
    this.profileRemotestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.remotegameshistory) {
          this.historyloader = false;
          if (playerState.remotegameshistory.success == true) {
            if (playerState.remotegameshistory.total == 0) {
              this.totalrecords = playerState.remotegameshistory.total;
            } else {
              this.remotehistory = playerState.remotegameshistory.values;
              this.totalrecords = playerState.remotegameshistory.total;
            }
          } else if (playerState.remotegameshistory.success == false) {
            this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
          }
        }
        if (playerState.remotegameshistoryError) {
          this.historyloader = false;
          this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
        }
      }
    )
  }

  onremoteFormSubmit() {
    this.historyloader = true;
    this.totalrecords = null;
    this.remotehistory = null;
    this.remoteGameHistoryErrorMessage = null;
    this.p = 1;
    this.remoteform.value.startDate = moment(this.remoteform.value.startDate).format('DD-MM-YYYY');
    this.remoteform.value.endDate = moment(this.remoteform.value.endDate).format('DD-MM-YYYY');
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new PlayerGetRemoteGameHistory(this.remoteform.value));
  }
  ngOnDestroy() {
    if (this.profileRemotestoreSub) {
      this.profileRemotestoreSub.unsubscribe();
    }
  }
  onChange(data) {
    this.p = 1;
    this.selectnum = data;
  }
}