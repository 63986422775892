
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ResetPassword } from 'src/app/source/modules/login/resetpassword';
import { LoginService } from 'src/app/source/services/login/login.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetform: FormGroup;
  openpopup: boolean = false;
  openpopupforget: boolean = false;
  private storeSub: Subscription;
  resetPasswordErrorMessage: string = "";
  ResetPassword: ResetPassword;
  resetPasswordSuccessmessage: string = "";
  resetpsdsuccess: boolean = false;
  // changedemail:boolean=false;
  // emailErroMessage:boolean=false;
  selectedlanguege = 'FA';
  forgotSet: boolean = false
  forgotSuccessmessage: string = '';
  forgotAction: boolean = false;
  forgetPasswordSuccessmessage: any;
  constructor(private store: Store<appState.AppState>, private logiServices: LoginService) {
    this.selectedlanguege = localStorage.getItem('locale');
    this.store.dispatch(new loginActions.ResetState());
  }

  ngOnInit() {

    this.store.dispatch(new loginActions.ResetState());
    this.resetform = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
      "login": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')])
    });
    //   this.resetform.get('email').valueChanges.subscribe(x => {
    //     let extension = x.substr(x.lastIndexOf(".") + 1);
    //     if(extension === 'com'){ 
    //      this.emailErroMessage=false;
    //       this.changedemail = true;
    //     }else{

    //      this.emailErroMessage=true;
    //       this.changedemail = false;
    //     }
    //  });
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.ResetPassword) {
          if (loginState.ResetPassword.success === true) {
            this.resetform.reset();
            this.openpopup = false;
            this.openpopupforget = false;
            this.resetPasswordErrorMessage = "";
            this.resetpsdsuccess = true;
            this.resetPasswordSuccessmessage = "Temporary password has sent to your email, Please login and update the password immediately.";

          } else if (loginState.ResetPassword.success === false) {
            this.resetpsdsuccess = false;
            this.resetPasswordSuccessmessage = "";
            this.resetPasswordErrorMessage = loginState.ResetPassword.description;
            setTimeout(()=>{
              this.resetPasswordErrorMessage =""
              this.resetform.reset();
            },3000)
            //this.resetPasswordErrorMessage =  "Invalid Data";
          }
        }
        if (loginState.ResetPasswordError) {
          this.resetpsdsuccess = false;
          this.resetPasswordSuccessmessage = "";
          this.resetPasswordErrorMessage = loginState.ResetPasswordError.message;
        }
      }
    )
  }

  onResetPasswordFormSubmit() {
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.ResetPasswordStart(this.resetform.value))
  }
  onResetPasswordFormSubmit1() {
    this.forgotAction = true;
    console.log(this.resetform.value)
    this.logiServices.onForgotPassword(this.resetform.value).subscribe(res => this.forgetResponse(res))
  }
  forgetResponse(res) {
    console.log(res)
    this.forgetPasswordSuccessmessage = res.description;
    if (res.success == true){
      this.forgotSet = true;
      this.openpopupforget=false;
      // setTimeout(()=>{
      //   this.openpopupforget=false;
      //   this.forgetPasswordSuccessmessage =""
      // },4000)
      this.resetform.reset();
    }else {
      setTimeout(()=>{
        this.forgetPasswordSuccessmessage =""
        this.resetform.reset();
      },3000)
    }
  }
  forgotsuccessPopClose() {
    this.forgotSet = false;
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  openrestpassword() {
    this.openpopup = true;
  }
  openrestpassword1() {
    this.openpopupforget = true
  }
  closeresetPasswordPop() {
    this.openpopup = false;
    this.openpopupforget = false;
  }
  resetpsdsuccessPopClose() {
    this.resetpsdsuccess = false;
    this.store.dispatch(new loginActions.ResetState());
  }
}
