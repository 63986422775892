import { liveOdds } from './liveodds';
import { league } from './live';
import { Participant } from './participants';

export class LiveBetslip {

name: string;
matchId: number;
type: String;
// oddData: liveOdds;
oddTitle: string;
oddstype: string;
betString: any;
odd: any;
oddId: number;
decimalOdd: string;
betAmount: number;
mktId: any;
flag: boolean;

datendtime:any;
//selectionId : number;


constructor(oddData: league, title: string, odds: liveOdds, part: Participant) {

this.flag =true;
this.matchId = oddData.id;
this.name = oddData.t1n + " " + "Vs" + " " + oddData.t2n;
this.oddstype = odds.name;
if(oddData.st){
this.datendtime = oddData.st;
}
if (title == part.eu) {
this.oddTitle = part.name;
this.odd = part.eu;
this.decimalOdd = part.eu;
this.mktId = odds.id;
this.oddId = part.id;
if(this.datendtime){

this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}else{
this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}
} else if (title == part.us) {
this.oddTitle = part.name;
this.odd = part.eu;
this.decimalOdd = part.eu;
this.mktId = odds.id;
this.oddId = part.id;
if(this.datendtime){
this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}else{
this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}
//this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
} else if (title == part.na) {
this.oddTitle = part.name;
this.odd = part.eu;
this.decimalOdd = part.eu;
this.mktId = odds.id;
this.oddId = part.id;
if(this.datendtime){
this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}else{
this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}
// this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
}





}


}