import { Component, OnInit } from '@angular/core';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { WalletInfo } from 'src/app/source/modules/cashier/balance';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { appConf } from 'src/app/source/config/app.config';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { CommonUtilService } from 'src/app/source/services/common/commonutil.service';
import { appConstants } from 'src/app/source/constants/appconstants';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exchangepopup',
  templateUrl: './exchangepopup.component.html',
  styleUrls: ['./exchangepopup.component.css']
})
export class ExchangepopupComponent implements OnInit {

  private loginSub: Subscription;
  private storeSub: Subscription;
  playerLoggedIn: boolean = false;
  setSelection: string = "USD";
  covertTxt: string = "Please enter value bellow";
  USD_wallet: any;
  CHP_wallet: any;
  playerBalance: any;
  selectedDropDownCurrencyValue: any = 5;
  chpExchangerate: any;
  usdExchangerate: any;
  dataLoad: boolean = false;
  summeryMsg: string = "";
  isError: boolean = false;
  errMsg: string = "";
  showBtn: boolean = true;
  payoutUSDamount = [
    5,
    10,
    20,
    30,
    40,
    50,
    100,
    200,
    500,
    1000,
    2000,
    3000
  ]
  payoutamount = [
    250000,
    500000,
    750000,
    1000000,
    1500000,
    2000000,
    2500000,
    5000000
  ];
  historyloader: boolean = false;
  walleteInfo: WalletInfo[];
  usdBalance: string;
  chpBalance: string;

  constructor(private store: Store<appState.AppState>, private commonUtilSer: CommonUtilService, private playerService: PlayerService) {
sessionStorage.setItem("APKPopup","exchangeCurrencyPopup");
 
  }

  ngOnInit() {

    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn) {
            let bodyCHP = {
              "walletCode": "CHP"
            }
            
            this.playerService.exchangRates(bodyCHP).subscribe(res => {
        
              console.log(res);
              // this.selectedDropDownCurrencyValue = res.responses.CHP[0].rate;
              if(res){
                this.chpExchangerate = res.responses.CHP[0].rate;
                console.log(this.chpExchangerate);
                this.showBtn = false;
              }
            })
            let bodyUSD = {
              "walletCode": "USD"
            }
            this.playerService.exchangRates(bodyUSD).subscribe(res => {  
              // this.selectedDropDownCurrencyValue = res.responses.USD[0].rate;
              if(res){
        
                this.usdExchangerate = res.responses.USD[0].rate;
                this.showBtn = false;
              }
            })
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
          }
        }
      });

    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          if (cashierState.balance.success === true) {
            this.playerBalance = cashierState.balance;
            this.showDataToView("USD");
            this.walleteInfo = cashierState.balance.values;
            for (let i = 0; i < this.walleteInfo.length; i++) {
               
              if (this.walleteInfo[i].wallet.name == "USD") {
                var usd = Number(this.walleteInfo[i].cash.value).toFixed(2)
                this.usdBalance = (this.walleteInfo[i].symbol + " " + Number(usd).toLocaleString())
              }

              if (this.walleteInfo[i].wallet.name === "CHP" ) { 
                var chp = Number(this.walleteInfo[i].cash.value).toFixed(2);
                console.log(chp)
                this.chpBalance = Number(chp).toLocaleString();
                 

            }
             
            }
          } 
        }
      }
    );
    this.selectedDropDownCurrencyValue="5";
  }

  showDataToView(wallet: any) {
    this.dataLoad = true
    this.setSelection = wallet;
    let body = {
      "walletCode": (wallet === "CHP" ? "CHP" : "USD")
    }
    this.playerService.exchangRates(body).subscribe(res => {
      this.dataLoad = false;
      if (wallet === "CHP") {
        this.selectedDropDownCurrencyValue = this.selectedDropDownCurrencyValue || res.responses.CHP[0].rate;
        this.summeryMsg = `${res.responses.CHP[0].rate} TOMAN is equals to ${this.usdExchangerate} USD`;
        this.covertTxt = "Convert USD into TOMAN";
      } else {
        this.selectedDropDownCurrencyValue=this.selectedDropDownCurrencyValue || 5;
        // this.selectedDropDownCurrencyValue = res.responses.USD[0].rate;
        this.summeryMsg = `${res.responses.USD[0].rate} USD is equals to ${this.chpExchangerate} TOMAN`;
        this.covertTxt = "Convert TOMAN into USD";
      }
    })

    this.playerBalance.values.map(res => {
      if (res.wallet.name === "USD") {
        this.USD_wallet = res.cash.value;
      } else if (res.wallet.name === "CHP") {
        this.CHP_wallet = res.cash.value;
      }
    })

    // this.currencyValue
  }
  changeAmtVal(value:string) {

    console.log(value , "changeAmtVal")
    this.selectedDropDownCurrencyValue = value.split(" ")[0]
  }
  submitExchangeCurrency() {
    if (this.playerLoggedIn) {
      this.showBtn = true;
      this.historyloader = true;

const amount=this.selectedDropDownCurrencyValue || 5;

      if (this.setSelection == "CHP") {
        let body = {
          "walletCode": this.setSelection,
          "keyVsValue": {
            "AMOUNT_cash_USD": amount,
            // "AMOUNT_cash_USD": this.selectedDropDownCurrencyValue,
            "AMOUNT_bonus_USD": "0.00",
            "AMOUNT_tm_USD": "0.00"
          }
        }
        this.playerService.walletExchange(body).subscribe(res => this.exchangeRatesApiRes(res))
      } else {
        let body = {
          "walletCode": this.setSelection,
          "keyVsValue": {
            "AMOUNT_cash_CHP": amount,
            // "AMOUNT_cash_CHP": this.selectedDropDownCurrencyValue,
            "AMOUNT_bonus_CHP": "0.00",
            "AMOUNT_tm_CHP": "0.00"
          }
        }
        this.playerService.walletExchange(body).subscribe(res => this.exchangeRatesApiRes(res))
      }
    } else {
      this.isError = true;
      this.errMsg = "Please login"
    }
  }
  exchangeRatesApiRes(res:any) {
    console.log(res)
    this.isError = true;
    if (res.success == true) {
      this.errMsg = "Exchange Converted successfull";
      this.historyloader = false;
      setTimeout(() => {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      }, 500);
    } else {
      this.historyloader = false;
      this.errMsg = res.description
    }
  }
  resetError() {
    this.isError = false;
    this.showBtn = false;
  }

}
