import { Component, OnDestroy, OnInit } from '@angular/core';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { PlayerGetTournamentsList } from 'src/app/source/appstates/playerstates/playerActions';
import { Subscription } from 'rxjs';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Values } from 'src/app/source/modules/player/tournamentsList';
@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent implements OnInit, OnDestroy {

  private storeSub: Subscription;
  tournamentList:any = [];
  tourneyName:any = [] ;
  constructor(private store: Store<appState.AppState>) {
    sessionStorage.removeItem('myPopup')
    sessionStorage.removeItem('myPopupTwo')
  window.scroll(0,0);}
  ngOnInit() {

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => { 
        if (playerState.tournamentList) {
          if (playerState.tournamentList.values) { 
            this.tournData(playerState.tournamentList.values)
            // this.tournamentList = playerState.tournamentList.values;
          }
        }
      }
    )

    let obj = { "filter": { "regular": true, "guaranteed": true, "freeroll": true }, "paging": { "offset": 0, "limit": 100 } };
    this.store.dispatch(new PlayerGetTournamentsList(obj));
  }

  tournData(data){
    // console.log(data)
    
    if(this.tournamentList.length == 0){
    for(let i=0; i<data.length; i++){
      // console.log(data[i].type.id == '1' )
      if( data[i].type.id == '1' ){
        // console.log( data[i].type.id )
        this.tournamentList.push(data[i]);
        // console.log( this.tournamentList)
       
         
      }
    }
        console.log( this.tournamentList)
    
        var tourneyName = [];
      for(let i=0;i<this.tournamentList.length;i++){
        // console.log( this.tournamentList )
   
        
this.tourneyName.push({
name:this.tournamentList[i].name.replace(/\*/g, '').trim(),
caption:this.tournamentList[i].game.caption,
heading:this.tournamentList[i].name,
sDate:this.tournamentList[i].startDate,
buyIn:this.tournamentList[i].buyIn.value,
status:this.tournamentList[i].status.name,
playersCount:this.tournamentList[i].playersCount,
})

        // this.tournamentList[i].name;
        // console.log(tourney
        // console.log(tourneyName.replace(/\*/g, ''));
        
      }
      console.log(this.tourneyName)
      
    }else{
      return;
    }
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
