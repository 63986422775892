import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/source/services/login/login.service';
import { Store } from '@ngrx/store';
import { LoginActions } from 'src/app/source/appstates/loginstates/loginActions';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as appState from 'src/app/source/appstates/appState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { appConstants } from 'src/app/source/constants/appconstants';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
  private loginstoreSub: Subscription;
  playerLoggedIn: boolean = false;
  loginSite: boolean = false;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  isMobile: boolean = false;
  appLinks: any = appConstants.appDownloadLinks;
  lang: any = appConstants.language;
  showLoad: boolean = false;
  redirectLoader: boolean = false;
  wSession: any;
  logName: any;
  sprtUrl: string; 
  SprtsToken: any;
  constructor(private store: Store<appState.AppState>, private router: Router,private Playerservice: PlayerService,private cookieService: CookieService,) { }

  ngOnInit() { 
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          if (this.playerLoggedIn == false) { 
            this.casinomessage = "Please login";
            this.casinogamessuccessPop = true;
          }
        }
      }
    )
  }
   openDashboard(route:string) { 
    if (this.playerLoggedIn) {
      this.router.navigate([route])
    } else {
      this.casinogamessuccessPop = true;
      this.casinomessage = "Please login"; 
    }
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  openPokerTable(t: any) { 
    if (this.playerLoggedIn) {
      if (this.isMobile) {
        window.open(this.appLinks.instaPlay, '_self')
      } else {
        if (t == 'old') {
          console.log(this.appLinks.instaPlay)
          window.open(this.appLinks.instaPlay, '_blank', 'width=400,height=900,resizable=0')
        } else {
          window.open(this.appLinks.instaPlayNew, '_blank', 'width=400,height=900,resizable=0')
        }
      }
    } else if(!this.playerLoggedIn) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  sportsRedirect() {
    if (this.playerLoggedIn == true) {
      this.showLoad = true;
      this.sportsData();
      this.redirectLoader = true;
    } else if(!this.playerLoggedIn) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  sportsData() {
    if (this.playerLoggedIn) {
      this.Playerservice.onPlayerGetProfile().subscribe(data => this.getSportData(data))
    } else if(!this.playerLoggedIn) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  getSportData(data) {
    this.wSession = this.cookieService.get('wsession') 
    this.logName = { loginName: data.login, webSocketSessionId: this.wSession };
    this.Playerservice.sportsDataServe(this.logName).subscribe(data => this.launchSport(data, this.logName))
    this.redirectLoader = false;
  }
  launchSport(data, logName) { 
    this.sprtUrl = data.data.address;
    this.SprtsToken = data.data.token; 
    // this.Playerservice.sportsSettionTkn(logName.loginName, this.SprtsToken).subscribe( data => { console.log( data ) } )
    // alert(" check Home 245 launch data ")
    this.showLoad = false;
    return window.open(this.sprtUrl, '_self');
  }
}
