import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { UpDatePasswordModel } from 'src/app/source/modules/player/updatepassword';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Router } from '@angular/router';
import { MeightService } from 'src/app/source/services/meightservice/meight.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  updatePassword: FormGroup;
  private updatepasswordsub: Subscription;
  private updatepasswordmodel: UpDatePasswordModel;
  changePassword: boolean = false;
  updatepassworderror: string = "";
  ChangePasswordsuccess: boolean = false;
  historyloader:boolean=false;
  constructor( private meightservice: MeightService, private store: Store<appState.AppState>,private router: Router) {
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.store.dispatch(new playerActions.ResetState());
    this.updatePassword = new FormGroup({
      // "oldPassword": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.pattern('[a-zA-Z0-9@#$%^&*()-_=+]*')]),
      "oldPassword": new FormControl(null, Validators.required),
      "newPassword": new FormControl(null, [Validators.required,  Validators.minLength(8), Validators.pattern(/^(?!.*\s)(?!.*&)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*])[A-Za-z\d!@#$%^*]{8,30}$/)])
    })

    this.updatepasswordsub = this.store.select('playerState').subscribe(
      (playerState: ProfileState) => {
        if (playerState.updatepasswordmodel) {
          this.historyloader=false;
          if (playerState.updatepasswordmodel.success == true) {
            this.updatePassword.reset();
            this.changePassword = false;
            this.ChangePasswordsuccess = true;
            this.updatepassworderror = "";
          } else if (playerState.updatepasswordmodel.success == false) {
            this.updatepassworderror = 'Invalid Old Password';
//            if(playerState.updatepasswordmodel.description=="Invalid argument"){
// if(localStorage.getItem('locale') == "FA"){
//   this.updatepassworderror = "پسورد شما اشتباه است"
// }else{
// } 
//            }else{

//              this.updatepassworderror = playerState.updatepasswordmodel.description;
//            }
            console.log(this.updatepassworderror)
          }
        }
        if(playerState.updatepasswordmodelError){
          this.historyloader=false;
          this.updatepassworderror = playerState.updatepasswordmodelError.message;
        }
      }
    )
  }
  onUpdatePasswordSubmit() {
    this.historyloader=true;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new playerActions.PlayerUpdatePassword(this.updatePassword.value));
  }
  ngOnDestroy() {
    if (this.updatepasswordsub) {
      this.updatepasswordsub.unsubscribe();
    }
  }
  openPasswordPop() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.changePassword = true;
  }
  closePasswordPop() {
    this.updatePassword.reset();
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.changePassword = false;
  }
  ChangePsdPopClose() {
    this.changePassword = false;
    this.ChangePasswordsuccess = false;
    this.meightservice.onLogOut().subscribe(res => {

    }, err => {

    });
    // this.store.dispatch(new loginActions.LogoutStart());
    // this.router.navigate(['/home']);
  }
  passwordValid(event) {


    const keyCode = event.charCode || event.keyCode;
    const disallowedCodes = [38]; // & symbol keyCode
    if (disallowedCodes.includes(keyCode)) {
      event.preventDefault();
      this.updatePassword.get('newPassword').setErrors({ 'disallowedCharacter': true });
      return false;
    }
    
    if (this.updatePassword.get('newPassword').errors) {
      this.updatePassword.get('newPassword').updateValueAndValidity();
    }
    return true;

    // var k;
    // k = event.charCode;
    // console.log(k)//         k = event.keyCode;  (Both can be used)
    // return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 58) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }

}
